import { DatePicker, Select } from "antd";
import moment from "moment";
import locale from "antd/es/date-picker/locale/zh_TW";
import React, { useContext, useState } from "react";
import {
  PickerBaseProps,
  PickerDateProps,
  PickerTimeProps,
} from "antd/lib/date-picker/generatePicker";
import { staticText } from "../../utils/staticText";
import { TempWorkContext } from "../../contexts/tmpwork";
import { InitContext } from "../../contexts/init";

const { Option } = Select;

export const Control = () => {
  const { data, dispatch } = useContext(TempWorkContext);
  const { data: InitData, dispatch: InitDispatch } = useContext(InitContext);
  const { tmp, tmpList, workDate, status, pubDates } = data;
  const { datepickerOpen } = InitData;

  const { overview } = staticText;

  const [testInput, setTestInput] = useState<React.Component<
    | PickerBaseProps<moment.Moment>
    | PickerDateProps<moment.Moment>
    | PickerTimeProps<moment.Moment>,
    any,
    any
  > | null>();

  const handleDateChange = (date: moment.Moment | null) => {
    if (date) {
      dispatch({
        type: "setState",
        payload: {
          workDate: moment(date).startOf("day"),
        },
      });

      // @ts-ignore
      if (testInput) testInput.blur(); // ? TYPE?
    }
  };

  return (
    <div className="control-box overview">
      <div className="select-group">
        <h5>{overview.control.text1}</h5>
        <DatePicker
          inputReadOnly
          defaultPickerValue={moment()}
          value={workDate}
          locale={locale}
          open={datepickerOpen}
          onOpenChange={(value) =>
            InitDispatch({
              type: "setState",
              payload: { datepickerOpen: value },
            })
          }
          onChange={handleDateChange}
          getPopupContainer={() =>
            document.querySelector(".date-picker-wrapper")
          }
          ref={(input) => setTestInput(input)}
          className="control-datePicker"
          placeholder={overview.control.placeholder.date}
          dateRender={(current) => {
            return (
              <div
                className={`ant-picker-cell-inner ${
                  pubDates.includes(current.format("YYYY-MM-DD")) ? "unpub" : ""
                }`}
              >
                {current.date()}
              </div>
            );
          }}
        />
      </div>
      <div className="select-group">
        <h5>{overview.control.text2}</h5>
        <Select
          onFocus={(e) => {
            e.target.setAttribute("readOnly", "true");
          }}
          allowClear={true}
          placeholder={overview.control.placeholder.tmp}
          value={tmp ? tmp : null}
          className="control-tmp"
          onChange={(e) => {
            dispatch({
              type: "setState",
              payload: {
                tmp: e,
                tmpWok: 0,
                status: e ? status : "showControl",
                tmpWokList: [],
                taskLists: [],
                tab: null,
                openCount: false,
              },
            });
          }}
        >
          {tmpList.map((ele) => {
            return (
              <Option value={ele.id} key={ele.id}>
                {ele.name}
              </Option>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

import React from "react";
import { Button } from "../components/ui/Button";
import { ReactComponent as Pen } from "../images/layout/Pen.svg";
import { ReactComponent as Overview } from "../images/home/icon-01.svg";
import { ReactComponent as MyworkIcon } from "../images/home/icon-02.svg";
import { ReactComponent as MegaPhoneIcon } from "../images/home/icon_icon-05.svg";

/** 首頁 */
export const Home = () => {

  return (
    <div className="home-wrapper">
      <h3 className="card-title">
        <MegaPhoneIcon />
        Tickup beta 版上線!
      </h3>
      <p className="mb50">
        Tickup
        是一個任務指派的管理系統，提供給現場營運管理作業流程使用，在正式上線前，歡迎大家體驗
        Tickup beta 管理作業流程~
      </p>
      <div className="function-card-wrapper mb50">
        <div className="function-card">
          <figure>
            <Pen />
          </figure>
          <div className="content">
            <h3 className="card-title">作業指派</h3>
            <p>
              團隊管理者可以在這裡指派各部門相關作業給現場同仁，同時管理班表、人力狀況。
            </p>
          </div>
        </div>
        <div className="function-card">
          <figure>
            <Overview />
          </figure>
          <div className="content">
            <h3 className="card-title">作業總覽</h3>
            <p>
              團隊管理者可以在此一覽所有部門員工執行進度，與分工狀況，快速掌握團隊運營狀況。
              (目前分為房務總覽、客務總覽、臨時總覽)
            </p>
          </div>
        </div>
        <div className="function-card">
          <figure>
            <MyworkIcon />
          </figure>
          <div className="content">
            <h3 className="card-title">我的工作</h3>
            <p>
              部門員工可以在此看到自己當天的班表，以及負責作業，執行完就打勾，回報進度變得超輕鬆！
            </p>
          </div>
        </div>
      </div>

      <div className="separator mb50"></div>

      <h3 className="card-title">
        <MegaPhoneIcon />
        客泊樂住宿表
      </h3>

      <div className="g-calendar-wrapper">
        <iframe
          src={`https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Asia%2FTaipei&showTz=0&showCalendars=0&showPrint=0&showTitle=0&showNav=1&showTabs=1&src=${process.env.REACT_APP_CALENDAR}&src=emgtdHcudGFpd2FuI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%23AD1457&color=%230B8043`}
          style={{ border: 0 }}
          width="800"
          height="600"
          frameBorder="0"
          scrolling="no"
        ></iframe>
      </div>

      <div className="separator mb50"></div>

      <h3 className="card-title">
        <MegaPhoneIcon />
        問題大募集
      </h3>
      <p className="mb30">
        在工作上有遇到什麼問題，可以讓 tickup 滿足你的嗎？填寫下方表單來許願吧~
      </p>

      <Button
        text="填寫許願表單"
        type="main"
        onClick={() =>
          window.open("https://forms.gle/4qXYyPwAcT94c2PC6", "_blank")
        }
      ></Button>
      <Button
        text="系統問題回報"
        type="main"
        className="ml20"
        onClick={() =>
          window.open("https://forms.gle/XuPwUAn1o4ec5cBB6", "_blank")
        }
      ></Button>
    </div>
  );
};

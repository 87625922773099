import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Layout } from "./components/layout/Layout";
import { Assign } from "./pages/Assign";
import { Mywork } from "./pages/Mywork";
import { Overview } from "./pages/Overview";
import { TmpWork } from "./pages/TmpWork";
import { Login } from "./pages/Login";
import { Bind } from "./pages/Bind";
import { Home } from "./pages/Home";
import { ForgotPwd } from "./pages/ForgotPwd";
import { Redirect } from "./pages/Redirect";
import { SignUp } from "./pages/SignUp";
import { Notice } from "./pages/Notice";
import { InitContextProvider } from "./contexts/init";
import { AssignContextProvider } from "./contexts/assign";
import { OverviewContextProvider } from "./contexts/overview";
import { MyworkContextProvider } from "./contexts/mywork";
import { TempWorkContextProvider } from "./contexts/tmpwork";
import { NoticeContextProvider } from "./contexts/notice";

export const Routes = () => {
  return (
    <Router>
      <InitContextProvider>
        <Switch>
          <Route exact path="/signup">
            <SignUp />
          </Route>
          <Route exact path="/bind">
            <Bind />
          </Route>
          <Route exact path="/forgot-password">
            <ForgotPwd />
          </Route>
          <Route path="/redirect">
            <Redirect />
          </Route>

          <Route exact path="/home">
            <Layout>
              <Home />
            </Layout>
          </Route>
          <Route exact path="/notice/:type">
            <Layout>
              <NoticeContextProvider>
                <Notice />
              </NoticeContextProvider>
            </Layout>
          </Route>
          <Route exact path="/assign/:pub">
            <Layout>
              <AssignContextProvider>
                <Assign />
              </AssignContextProvider>
            </Layout>
          </Route>
          <Route exact path={["/rooms/:dpt", "/reception/:dpt"]}>
            <Layout>
              <OverviewContextProvider>
                <Overview />
              </OverviewContextProvider>
            </Layout>
          </Route>
          <Route exact path="/cleaning/:dpt">
            <Layout>
              <OverviewContextProvider>
                <Overview />
              </OverviewContextProvider>
            </Layout>
          </Route>
          <Route exact path="/temporary/:dpt">
            <Layout>
              <TempWorkContextProvider>
                <TmpWork></TmpWork>
              </TempWorkContextProvider>
            </Layout>
          </Route>
          <Route exact path="/mywork">
            <Layout>
              <MyworkContextProvider>
                <Mywork />
              </MyworkContextProvider>
            </Layout>
          </Route>
          
          <Route path="/">
            <Login />
          </Route>
        </Switch>
      </InitContextProvider>
    </Router>
  );
};

import React, { useEffect } from "react";
import { Routes } from "./Routes";
import "antd/dist/antd.css";
import "./styles/app.scss";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import { Alert } from "./components/ui/Alert";
import smoothscroll from 'smoothscroll-polyfill';

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 2000,
  transition: transitions.FADE,
  containerStyle: {
    marginBottom: '5%'
  }
};

declare global {
  interface Array<T> {
    equals(array: any[]): boolean;
  }
}

smoothscroll.polyfill();

function App() {
  useEffect(() => {
    /**
     *  add function to Array so that I can check that the two arrays are the same or not
     */
    if (Array.prototype["equals"])
      console.warn(
        "Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code."
      );
    Array.prototype["equals"] = function (array) {
      if (!array) return false;
      if (this.length != array.length) return false;
      for (var i = 0, l = this.length; i < l; i++) {
        if (this[i] instanceof Array && array[i] instanceof Array) {
          if (!this[i].equals(array[i])) return false;
        } else if (this[i] != array[i]) {
          return false;
        }
      }
      return true;
    };
    Object.defineProperty(Array.prototype, "equals", { enumerable: false });
  }, []);
  return (
    <>
      <AlertProvider template={Alert} {...options}>
        <Routes />
      </AlertProvider>
    </>
  );
}

export default App;

import { Tabs } from "antd";
import React, { useContext, useState, useRef, forwardRef } from "react";
import { Wok } from "./Wok";
import { OverviewContext } from "../../contexts/overview";
import { InitContext } from "../../contexts/init";
import { staticText } from "../../utils/staticText";
import { Loading } from "../ui/Loading";
import { Tab } from "../../contexts/overview";

import { ReactComponent as NoData } from "../../images/assign/no-data.svg";

export const WokList = forwardRef(
  (props, ref: React.MutableRefObject<HTMLDivElement>) => {
    const { data: InitData } = useContext(InitContext);
    const { dataLoading } = InitData;
    const { data: OverviewData, dispatch: OverviewDispatch } =
      useContext(OverviewContext);
    const { wokList, tab } = OverviewData;
    const { TabPane } = Tabs;
    const [tabs] = useState(staticText.tabs);
    const { overview } = staticText;

    function callback(key: string) {
      OverviewDispatch({
        type: "setState",
        payload: {
          tab: Tab[key],
          status: "showWork",
          wokList: [],
          reload: true,
        },
      });
    }

    return (
      <div ref={ref}>
        <Tabs activeKey={Tab[tab].toString()} onChange={callback}>
          {tabs.map((ele, i) => (
            <TabPane tab={ele} key={(i + 1).toString()}>
              {dataLoading.value ? (
                <Loading />
              ) : (
                Tab[tab] === i + 1 &&
                (wokList.length > 0 ? (
                  wokList.map((ele) => {
                    return <Wok {...ele} key={ele.id} />;
                  })
                ) : (
                  <div className="no-data">
                    <NoData />
                    <h3>
                      {overview.wok.nowork[tab ? tab : "null"]}
                    </h3>
                  </div>
                ))
              )}
            </TabPane>
          ))}
        </Tabs>
      </div>
    );
  }
);

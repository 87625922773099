import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const Button = ({
  text,
  type = "main",
  disabled = false,
  multi = false,
  loading = false,
  onClick,
  className
}: {
  text: string;
  type: "main" | "sub";
  disabled?: boolean;
  multi?: boolean;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  className?:string;
}) => {
  return (
    <button
      className={`ui-button ${className ? className : ''} button-${type} ${disabled ? "disabled" : ""} ${
        multi ? "multi" : ""
      }`}
      onClick={disabled ? () => {} : onClick}
      style={{ width: multi ? "calc((100% - 8px) / 2)" : "146px" }}
    >
      {text}
      {loading && <Spin indicator={antIcon} style={{marginLeft: '3px', color: 'white'}}/>}
    </button>
  );
};

import moment from "moment";
import { createContext, useReducer } from "react";

type NoticeChanges = {
  col: string;
  old: string | number;
  new: string | number;
};

type Notice = {
  date: moment.Moment;
  data: {
    name: string;
    tentNo: string;
    changes: NoticeChanges[];
  }[];
};

export interface NoticeData {
  time?: {
    start: moment.Moment;
    end: moment.Moment;
  };
  notices?: Notice[];
}

const data: NoticeData = {
  time: {
    start: null,
    end: null,
  },
  notices: [],
};

export interface ActionType {
  type: "setState";
  payload: NoticeData;
}

function reducer(state: NoticeData, action: ActionType) {
  switch (action.type) {
    case "setState":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export interface NoticeContextType {
  data: NoticeData;
  dispatch: React.Dispatch<ActionType>;
}

export const NoticeContext = createContext<NoticeContextType>({
  data,
  dispatch: () => {},
});

export const NoticeContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, data);
  return (
    <NoticeContext.Provider value={{ data: state, dispatch }}>
      {children}
    </NoticeContext.Provider>
  );
};

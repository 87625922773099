import { Checkbox, Avatar, Tooltip } from "antd";
import { useContext } from "react";
import { Tasks } from "../../utils/types";
import { staticText } from "../../utils/staticText";
import { useAlert } from "react-alert";
import { InitContext } from "../../contexts/init";
import { MyworkContext } from "../../contexts/mywork";
import moment from "moment";

export const TaskCard = ({ id, name, DTS, stat, staf, GsId }: Tasks) => {
  const { data: InitData, dispatch: InitDispatch } = useContext(InitContext);
  const { account } = InitData;
  const { data, dispatch } = useContext(MyworkContext);
  const { wokLists, work, taskLists, area } = data;

  const { overview, loading } = staticText;
  const alert = useAlert();

  return (
    <>
      {taskLists.map((taskList) => (
        <div
          className={`task-card ${stat === "Y" ? "finish" : ""} ${
            wokLists
              .find((ele) => ele.id === work.id)
              ?.staf.findIndex((ele) => ele.id === account.id) === -1
              ? "disabled"
              : ""
          }`}
        >
          <Checkbox
            checked={stat === "Y" ? true : false}
            onChange={(e) => {
              // if (
              //   wokLists
              //     .find((ele) => ele.id === work.id)
              //     ?.staf.findIndex((ele) => ele.id === account.id) !== -1
              // ) {
              InitDispatch({
                type: "setState",
                payload: {
                  spinning: {
                    value: true,
                    tip: loading.handle,
                  },
                },
              });

              // TODO: fetch
              console.log("📥fetch chk");
              console.table({
                data: [
                  {
                    AgWkId: work.id,
                    GsId: GsId,
                    where: area.id,
                    CpEmpId: account.id,
                    AgTkId: id,
                    ChkStus: e.target.checked ? "Y" : "O",
                    DTS: e.target.checked
                      ? moment().format("YYYY-MM-DD HH:mm:ss")
                      : DTS,
                  },
                ],
              });

              fetch(
                `${
                  process.env.NODE_ENV === "development"
                    ? "https://cors-anywhere.herokuapp.com/"
                    : ""
                }${process.env.REACT_APP_URL}coptask`,
                {
                  method: "POST",
                  body: JSON.stringify([
                    {
                      AgWkId: work.id,
                      GsId: GsId,
                      where: area.id,
                      CpEmpId: account.id,
                      AgTkId: id,
                      ChkStus: e.target.checked ? "Y" : "O",
                      DTS: e.target.checked
                        ? moment().format("YYYY-MM-DD HH:mm:ss")
                        : DTS,
                    },
                  ]),
                }
              )
                .then((res) => res.json())
                .then((res) => {
                  console.log(res);

                  if (res) {
                    alert.success(
                      e.target.checked
                        ? overview.alert.success.replace("%任務數%", "1")
                        : overview.alert.cancel.replace("%任務數%", "1")
                    );

                    dispatch({
                      type: "setState",
                      payload: {
                        reloadTask: true,
                      },
                    });
                  }
                });
              // } else {
              //   // TODO: setPopup
              // }
            }}
          >
            {name}
          </Checkbox>
          {stat === "Y" && (
            <Tooltip title={staf.name} placement="top" key={staf.id}>
              <Avatar src={staf.pic} />
            </Tooltip>
          )}
          <p className="fnsh-time">
            {stat === "Y" && `${DTS} ${overview.task.finish}`}
          </p>
        </div>
      ))}
    </>
  );
};

import { DatePicker, Input, Select, TimePicker } from "antd";
import moment, { Moment } from "moment";
import { useState, useEffect, useContext, useRef } from "react";
import locale from "antd/es/date-picker/locale/zh_TW";
import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import { Button } from "../ui/Button";
// import { ReactComponent as NoData } from "../../images/assign/no-data.svg";
import { useAlert } from "react-alert";
import { staticText } from "../../utils/staticText";
import { ReactComponent as Delete } from "../../images/assign/delete.svg";
import { InitContext } from "../../contexts/init";
import { AssignContext } from "../../contexts/assign";

const { Option } = Select;

export const PubAssignArea = () => {
  const { data: InitData, dispatch: InitDispatch } = useContext(InitContext);
  const { popup, account, dptTyp } = InitData;
  const { data: AssignData, dispatch: AssignDispatch } =
    useContext(AssignContext);
  const {
    groups,
    items,
    workDate,
    creating,
    status,
    tagColor,
    assign,
    store,
    selectItem,
    tmp,
    wok,
    tempWok,
    showDeleted,
  } = AssignData;

  const { assignText, errorText } = staticText;
  const alert = useAlert();
  const inputRef = useRef<Input>(null);
  const [content, setContent] = useState({
    title: assignText.status.unselect_pub.title,
    subtitle: assignText.status.unselect_pub.subtitle,
  });
  const [disabled, setDisabled] = useState({
    timepicker: true,
    staf: true,
    dptTyp: true,
    dpt: true,
    tmp: true,
    work: true,
    sto: true,
    task: true,
  });
  const Admin = ["NoGp", "NoMuGp"];

  useEffect(() => {
    switch (status) {
      case "unselect":
        setContent({
          title: assignText.status.unselect_pub.title,
          subtitle: assignText.status.unselect_pub.subtitle,
        });
        setDisabled({
          timepicker: true,
          staf: true,
          dptTyp: true,
          dpt: true,
          tmp: true,
          work: true,
          sto: true,
          task: true,
        });
        break;
      case "new":
        setContent({
          title: assignText.status.new_pub.title,
          subtitle: assignText.status.new_pub.subtitle,
        });
        setDisabled({
          timepicker: false,
          staf: false,
          dptTyp: false,
          dpt: false,
          tmp: false,
          work: false,
          sto: false,
          task: false,
        });
        break;
      case "edit":
        // 臨時作業
        if (selectItem?.dpt.dsply === "NoGpTk") {
          setContent({
            title: assignText.status.edit_temp.title,
            subtitle: assignText.status.edit_temp.subtitle,
          });
        } else {
          // 一般作業
          setContent({
            title: assignText.status.edit.title,
            subtitle: assignText.status.edit_pub.subtitle,
          });
        }

        if (
          selectItem?.deleted === "D" ||
          (selectItem?.storeArr.length === 0
            ? false
            : selectItem?.storeArr.length ===
              selectItem?.storeArr.filter((store) => store.sysAct === "Y")
                .length)
        ) {
          setContent({
            title: assignText.status.edit_delete.title,
            subtitle: assignText.status.edit_delete.subtitle.replace(
              "%time%",
              selectItem?.updateAt.format("YYYY/MM/DD HH:mm")
            ),
          });
          // 已刪除的團單不可編輯
          setDisabled({
            timepicker: true,
            staf: true,
            dptTyp: true,
            dpt: true,
            tmp: true,
            work: true,
            sto: true,
            task: true,
          });
        } else {
          setDisabled({
            timepicker: false,
            staf: false,
            dptTyp: true,
            dpt: true,
            tmp: true,
            work: true,
            sto: true,
            task: false,
          });
        }
        break;
    }
  }, [status, selectItem]);

  useEffect(() => {
    if (!showDeleted && selectItem) {
      AssignDispatch({
        type: "setState",
        payload: {
          assign: {
            id: 0,
            time: { start: 0, end: 0 },
            staff: [],
            dptTyp: null,
            dpt: null,
            date: { start: "", end: "" },
            tmp: null,
            work: null,
            store: [],
            temp_work: null,
            temp_task: [""],
            updateAt: null,
          },
          tagColor: undefined,
          showAssign: false,
          status: "unselect",
          selectItem: undefined,
          selected: [],
          items: items.map(item => {
            return {
              ...item,
              selected: false
            }
          }),
          chose: false,
        }
      });
    }
  }, [showDeleted]);

  useEffect(() => {
    fetch(
      `${
        process.env.NODE_ENV === "development"
          ? "https://cors-anywhere.herokuapp.com/"
          : ""
      }${process.env.REACT_APP_URL}intim`
    )
      .then((res) => res.json())
      .then((res) => {
        AssignDispatch({
          type: "setState",
          payload: {
            tempWok: res.map((wok) => {
              return {
                id: wok.Id,
                name: wok.Name,
                sCol: wok.Color,
                dsply: wok.Dsply,
              };
            }),
          },
        });
      });
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      if (!disabled.task) {
        inputRef.current.focus({
          cursor: "end",
        });
      } else {
        inputRef.current.blur();
      }
    }
  }, [assign.temp_task.length]);
  // if (status === "unselect") {
  //   return (
  //     <div className="assign-area no-data">
  //       <NoData style={{ width: "70px", height: "50px", marginTop: "200px" }} />
  //       <h5 className="no-data-text">{assignText.status.unselect.noData}</h5>
  //     </div>
  //   );
  // }
  return (
    <>
      <div className="assign-area">
        <div
          className="color-tag"
          style={{
            background: `${tagColor && status === "edit" ? tagColor : ""}`,
          }}
        ></div>
        <h3 className="assign-title">{content.title}</h3>
        <h4 className="assign-subtitle">{content.subtitle}</h4>
        <div className="assign-content">
          <h6>{assignText.assignArea.workDate.title}</h6>
          <DatePicker
            bordered={false}
            disabled
            suffixIcon={<></>}
            value={workDate}
            locale={locale}
          />

          <h6>{assignText.assignArea.workTime.title}</h6>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TimePicker
              inputReadOnly
              allowClear={false}
              showNow={false}
              className="control-timePicker"
              format="HH:mm"
              minuteStep={30}
              disabled={disabled.timepicker}
              placeholder={assignText.assignArea.workTime.placeholder_s}
              onChange={(value) => {
                let start: number, end: number;
                if (value.valueOf() < assign.time.end) {
                  start = value
                    ? moment(workDate)
                        .add(value?.hour(), "hour")
                        .add(value?.minute(), "minute")
                        .valueOf()
                    : assign.time.start;
                  end = assign.time.end;
                } else {
                  start = assign.time.end;
                  end = value
                    ? moment(workDate)
                        .add(value?.hour(), "hour")
                        .add(value?.minute(), "minute")
                        .valueOf()
                    : assign.time.start;
                }
                AssignDispatch({
                  type: "setState",
                  payload: {
                    assign: {
                      ...assign,
                      time: {
                        start: start,
                        end: end,
                      },
                    },
                  },
                });
                if (status === "new") {
                  AssignDispatch({
                    type: "setState",
                    payload: {
                      creatingTime: {
                        start: start,
                        end: end,
                      },
                      items: items.map((item) =>
                        creating.includes(item.id)
                          ? Object.assign({}, item, {
                              start_time: start,
                              end_time: end,
                            })
                          : item
                      ),
                    },
                  });
                }
              }}
              value={
                (status === "unselect"
                  ? ""
                  : moment(assign.time.start)) as Moment
              }
              locale={locale}
            />
            <span>-</span>
            <TimePicker
              inputReadOnly
              allowClear={false}
              showNow={false}
              className="control-timePicker"
              format="HH:mm"
              minuteStep={30}
              disabled={disabled.timepicker}
              placeholder={assignText.assignArea.workTime.placeholder_e}
              onChange={(value) => {
                let start: number, end: number;
                if (value.valueOf() > assign.time.start) {
                  start = assign.time.start;
                  end = value
                    ? moment(workDate)
                        .add(value?.hour(), "hour")
                        .add(value?.minute(), "minute")
                        .valueOf()
                    : assign.time.end;
                } else {
                  start = value
                    ? moment(workDate)
                        .add(value?.hour(), "hour")
                        .add(value?.minute(), "minute")
                        .valueOf()
                    : assign.time.end;
                  end = assign.time.start;
                }
                AssignDispatch({
                  type: "setState",
                  payload: {
                    assign: {
                      ...assign,
                      time: {
                        start: start,
                        end: end,
                      },
                    },
                  },
                });
                if (status === "new") {
                  AssignDispatch({
                    type: "setState",
                    payload: {
                      creatingTime: {
                        start: start,
                        end: end,
                      },
                      items: items.map((item) =>
                        creating.includes(item.id)
                          ? Object.assign({}, item, {
                              start_time: start,
                              end_time: end,
                            })
                          : item
                      ),
                    },
                  });
                }
              }}
              value={
                (status === "unselect" ? "" : moment(assign.time.end)) as Moment
              }
              locale={locale}
            />
          </div>

          <h6>{assignText.assignArea.staff.title}</h6>
          <Select
            onFocus={(e) => {
              e.target.setAttribute("readOnly", "true");
            }}
            onDropdownVisibleChange={(value) =>
              InitDispatch({
                type: "setState",
                payload: { selectOpen: value },
              })
            }
            getPopupContainer={() =>
              document.querySelector(".ant-select-wrapper")
            }
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder={assignText.assignArea.staff.placeholder}
            value={assign.staff}
            onChange={(value) => {
              AssignDispatch({
                type: "setState",
                payload: {
                  assign: {
                    ...assign,
                    staff: value,
                  },
                },
              });
              if (status === "new") {
                let tempCreating = new Array();
                let tempItems = new Array();
                let nid = 100001;
                for (let i of value) {
                  tempCreating.push(nid);
                  tempItems.push({
                    id: nid,
                    group: i,
                    start_time: assign.time.start,
                    end_time: assign.time.end,
                    selected: false,
                    itemProps: {
                      style: {
                        background: `C2E5FA`,
                      },
                    },
                    color: `C2E5FA`,
                    dptTyp: {
                      id: assign.dptTyp,
                      name: dptTyp.find((ele) => ele.id === assign.dptTyp)
                        ?.name,
                    },
                    dpt: {
                      id: assign.dpt,
                      name: dptTyp
                        .find((ele) => ele.id === assign.dptTyp)
                        ?.dpt.find((ele) => ele.id === assign.dpt)?.name,
                      dsply: dptTyp
                        .find((ele) => ele.id === assign.dptTyp)
                        ?.dpt.find((ele) => ele.id === assign.dpt)?.dsply,
                    },
                    tmp: {
                      id: assign.tmp,
                      name: tmp.find((ele) => ele.id === assign.tmp)?.name,
                    },
                    wok: {
                      id: assign.work,
                      name: wok.find((ele) => ele.id === assign.work)?.name,
                    },
                    wokId: null,
                    storeArr: assign.store,
                    canMove: true,
                    canResize: "both",
                    canChangeGroup: false,
                  });
                  nid++;
                }
                let tempArr = items.filter((ele) => ele.id < 100000);
                AssignDispatch({
                  type: "setState",
                  payload: {
                    creatingGroups: value,
                    creating: tempCreating,
                    items: tempArr.concat(tempItems),
                    selected: [],
                  },
                });
              }
            }}
            className="control-people"
            suffixIcon={status === "unselect" ? <></> : <DownOutlined />}
            showArrow={status === "unselect" ? false : true}
            disabled={disabled.staf}
          >
            {groups.map((ele) => {
              return (
                <Option value={ele.id} label={ele.title} key={ele.id}>
                  {ele.title}
                </Option>
              );
            })}
          </Select>

          {status === "edit" && selectItem?.dpt.dsply !== "NoGpTk" && (
            <>
              <h6>{assignText.assignArea.dptTyp.title}</h6>
              <Select
                onFocus={(e) => {
                  e.target.setAttribute("readOnly", "true");
                }}
                style={{ width: "100%" }}
                placeholder={assignText.assignArea.dptTyp.placeholder}
                disabled={disabled.dptTyp}
                value={assign.dptTyp}
                suffixIcon={<DownOutlined />}
                showArrow={true}
              >
                {dptTyp.map((ele) => {
                  return (
                    <Option value={ele.id} label={ele.name} key={ele.id}>
                      {ele.name}
                    </Option>
                  );
                })}
              </Select>
            </>
          )}

          {status === "edit" && selectItem?.dpt.dsply !== "NoGpTk" && (
            <>
              <h6>{assignText.assignArea.dpt.title}</h6>
              <Select
                onFocus={(e) => {
                  e.target.setAttribute("readOnly", "true");
                }}
                style={{ width: "100%" }}
                placeholder={assignText.assignArea.dpt.placeholder}
                disabled={disabled.dpt}
                value={assign.dpt}
                suffixIcon={<DownOutlined />}
                showArrow={true}
              >
                {dptTyp
                  .find((ele) => ele.id === assign.dptTyp)
                  ?.dpt.map((ele) => {
                    return (
                      <Option value={ele.id} label={ele.name} key={ele.id}>
                        {ele.name}
                      </Option>
                    );
                  })}
              </Select>
            </>
          )}

          {Admin.includes(selectItem?.dpt.dsply) && (
            <>
              <h6>{assignText.assignArea.tmp.title}</h6>
              <Select
                onFocus={(e) => {
                  e.target.setAttribute("readOnly", "true");
                }}
                style={{ width: "100%" }}
                disabled={disabled.tmp}
                value={assign.tmp}
                placeholder={assignText.assignArea.tmp.placeholder}
                suffixIcon={status === "unselect" ? <></> : <DownOutlined />}
                showArrow={status === "unselect" ? false : true}
              >
                {tmp.map((ele) => {
                  return (
                    <Option value={ele.id} label={ele.name} key={ele.id}>
                      {ele.name}
                    </Option>
                  );
                })}
              </Select>
            </>
          )}

          {Admin.includes(selectItem?.dpt.dsply) && (
            <>
              <h6>{assignText.assignArea.work.title}</h6>
              <Select
                onFocus={(e) => {
                  e.target.setAttribute("readOnly", "true");
                }}
                style={{ width: "100%" }}
                disabled={disabled.work}
                value={assign.work}
                placeholder={assignText.assignArea.work.placeholder}
                suffixIcon={status === "unselect" ? <></> : <DownOutlined />}
                showArrow={status === "unselect" ? false : true}
              >
                {wok.map((ele) => {
                  return (
                    <Option value={ele.id} label={ele.name} key={ele.id}>
                      {ele.name}
                    </Option>
                  );
                })}
              </Select>
            </>
          )}

          {Admin.includes(selectItem?.dpt.dsply) && (
            <>
              <h6>{assignText.assignArea.store.title}</h6>
              <Select
                onFocus={(e) => {
                  e.target.setAttribute("readOnly", "true");
                }}
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder={assignText.assignArea.store.placeholder}
                value={assign.store}
                className="control-people"
                disabled={disabled.sto}
                suffixIcon={status === "unselect" ? <></> : <DownOutlined />}
                showArrow={status === "unselect" ? false : true}
              >
                {store.map((ele) => {
                  return (
                    <Option value={ele.id} label={ele.name} key={ele.id}>
                      {ele.name}
                    </Option>
                  );
                })}
              </Select>
            </>
          )}

          {(status !== "edit" || selectItem?.dpt.dsply === "NoGpTk") && (
            <>
              <h6>{assignText.assignArea.work.title}</h6>
              <Select
                onFocus={(e) => {
                  e.target.setAttribute("readOnly", "true");
                }}
                style={{ width: "100%" }}
                onChange={(value) => {
                  AssignDispatch({
                    type: "setState",
                    payload: {
                      assign: {
                        ...assign,
                        temp_work: value,
                      },
                    },
                  });
                  // TODO: setItems temp_work.find
                  if (status === "new") {
                    AssignDispatch({
                      type: "setState",
                      payload: {
                        items: items.map((item) =>
                          creating.includes(item.id)
                            ? Object.assign({}, item, {
                                wok: {
                                  id: value,
                                  name: tempWok.find((ele) => ele.id === value)
                                    ?.name,
                                },
                              })
                            : item
                        ),
                      },
                    });
                  }
                }}
                disabled={disabled.work}
                value={assign.temp_work}
                placeholder={assignText.assignArea.work.placeholder}
                suffixIcon={status === "unselect" ? <></> : <DownOutlined />}
                showArrow={status === "unselect" ? false : true}
              >
                {tempWok.map((ele) => {
                  return (
                    <Option value={ele.id} label={ele.name} key={ele.id}>
                      {ele.name}
                    </Option>
                  );
                })}
              </Select>
            </>
          )}

          {(status !== "edit" || selectItem?.dpt.dsply === "NoGpTk") && (
            <>
              <h6>{assignText.assignArea.task.title}</h6>
              {assign.temp_task.map((v, i) => {
                let inputProps = {
                  disabled: disabled.task,
                  placeholder: assignText.assignArea.task.placeholder,
                  key: i,
                  value: v,
                  className: "task-input",
                  allowClear: true,
                  onKeyPress: (e) => {
                    if (
                      e.key === "Enter" &&
                      (e.target as HTMLInputElement).value &&
                      assign.temp_task[assign.temp_task.length - 1] !== ""
                    ) {
                      AssignDispatch({
                        type: "setState",
                        payload: {
                          assign: {
                            ...assign,
                            temp_task: [...assign.temp_task, ""],
                          },
                        },
                      });
                    }
                  },
                  onChange: (e) =>
                    AssignDispatch({
                      type: "setState",
                      payload: {
                        assign: {
                          ...assign,
                          temp_task: assign.temp_task.map((v, i2) =>
                            i === i2 ? e.target.value : v
                          ),
                        },
                        items: items.map((item) =>
                          creating.includes(item.id)
                            ? Object.assign({}, item, {
                                temp_task: assign.temp_task.map((v, i2) =>
                                  i === i2 ? e.target.value : v
                                ),
                              })
                            : item
                        ),
                      },
                    }),
                };
                // get the last child of input reference
                if (i + 1 === assign.temp_task.length) {
                  inputProps = Object.assign({}, inputProps, { ref: inputRef });
                }

                return (
                  <div className="task-input-wrapper" key={i}>
                    <Input {...inputProps} />
                    {assign.temp_task.length > 1 && !disabled.task && (
                      <Delete
                        style={{
                          width: "20px",
                          height: "20px",
                          marginLeft: "8px",
                          fill: "#ff3b00",
                          cursor: "pointer",
                          opacity: "0.6",
                        }}
                        onClick={() => {
                          let array = [...assign.temp_task];
                          array.splice(i, 1);
                          AssignDispatch({
                            type: "setState",
                            payload: {
                              assign: {
                                ...assign,
                                temp_task: array,
                              },
                            },
                          });
                          if (status === "new") {
                            AssignDispatch({
                              type: "setState",
                              payload: {
                                items: items.map((item) =>
                                  creating.includes(item.id)
                                    ? Object.assign({}, item, {
                                        temp_task: array,
                                      })
                                    : item
                                ),
                              },
                            });
                          }
                        }}
                      />
                    )}
                  </div>
                );
              })}
              {!disabled.task && (
                <button
                  className="add-task-btn"
                  disabled={disabled.task}
                  onClick={() => {
                    if (assign.temp_task[assign.temp_task.length - 1] !== "") {
                      let array = [...assign.temp_task];
                      array.push("");
                      AssignDispatch({
                        type: "setState",
                        payload: {
                          assign: { ...assign, temp_task: array },
                        },
                      });
                    }
                  }}
                >
                  {assignText.assignArea.addTask}
                </button>
              )}
            </>
          )}
        </div>
        {status !== "edit" ? (
          <>
            <Button
              text={assignText.status.new_pub.btn1}
              type="sub"
              disabled={!creating.length}
              multi={true}
              onClick={() => {
                InitDispatch({
                  type: "setState",
                  payload: {
                    popup: {
                      ...popup,
                      open: true,
                      title: assignText.popup.clear.title,
                      content: assignText.popup.clear.content,
                      btnMain: assignText.popup.clear.btnMain,
                      btnSub: assignText.popup.clear.btnSub,
                      onClickMain: () => {
                        AssignDispatch({
                          type: "setState",
                          payload: {
                            items: items.filter((ele) => ele.id < 100000),
                            showAssign: false,
                          },
                        });
                        AssignDispatch({ type: "clearTimeline", payload: {} });
                        InitDispatch({ type: "closePopup", payload: {} });
                      },
                      onClickSub: () =>
                        InitDispatch({ type: "closePopup", payload: {} }),
                    },
                  },
                });
              }}
            />
            <Button
              text={assignText.status.new_pub.btn2}
              multi={true}
              type="main"
              disabled={
                !(
                  status === "new" &&
                  assign.temp_work &&
                  assign.temp_task.filter((ele) => ele).length > 0
                )
              }
              onClick={() => {
                // TODO: if fetch success
                console.log("📥pub new");
                console.table({
                  TpWkId: assign.temp_work,
                  AgDate: workDate.format("YYYY-MM-DD"),
                  SDT: moment(assign.time.start).format("YYYY-MM-DD"),
                  EDT: moment(assign.time.end).format("YYYY-MM-DD"),
                  AgMagId: account.id,
                  user: assign.staff,
                  AgTkName: assign.temp_task.filter((task) => task !== ""),
                });

                let body = {
                  TpWkId: assign.temp_work,
                  AgDate: workDate.format("YYYY-MM-DD"),
                  SDT: moment(assign.time.start).format("YYYY-MM-DD HH:mm"),
                  EDT: moment(assign.time.end).format("YYYY-MM-DD HH:mm"),
                  AgMagId: account.id,
                  user: assign.staff,
                  AgTkName: assign.temp_task.filter((task) => task !== ""),
                };

                fetch(
                  `${
                    process.env.NODE_ENV === "development"
                      ? "https://cors-anywhere.herokuapp.com/"
                      : ""
                  }${process.env.REACT_APP_URL}newintim`,
                  {
                    headers: new Headers({
                      "Content-Type": "application/json",
                    }),
                    method: "POST",
                    body: JSON.stringify(body),
                  }
                )
                  .then((res) => res.json())
                  .then((res) => {
                    console.log(items, res);

                    if (res.status !== 403) {
                      // fix
                      alert.success(
                        assignText.alert.pub.success.replace(
                          "%作業日%",
                          tempWok.find((wok) => wok.id === assign.temp_work)
                            .name
                        )
                      );
                      AssignDispatch({
                        type: "setState",
                        payload: {
                          isReload: true,
                          showAssign: false,
                        },
                      });
                      AssignDispatch({ type: "clearTimeline", payload: {} });
                    } else {
                      alert.error(errorText["403"]);
                    }
                  });
              }}
            />
          </>
        ) : (
          <>
            <Button
              text={assignText.status.edit.btn1}
              type="sub"
              multi={true}
              disabled={
                selectItem?.deleted === "D" ||
                (selectItem?.storeArr.length === 0
                  ? false
                  : selectItem?.storeArr.length ===
                    selectItem?.storeArr.filter((store) => store.sysAct === "Y")
                      .length)
              }
              onClick={() => {
                InitDispatch({
                  type: "setState",
                  payload: {
                    popup: {
                      ...popup,
                      open: true,
                      title: assignText.popup.delete.title,
                      content: (assign.staff.length > 1
                        ? assignText.popup.delete.content.many
                        : assignText.popup.delete.content.one
                      )
                        .replace(
                          "%人員%",
                          groups
                            .filter((ele) => assign.staff.includes(ele.id))
                            .map((ele) => ele.title)
                            .join(", ")
                        )
                        .replace("%作業%", selectItem.wok.name),
                      btnMain: assignText.popup.delete.btnMain,
                      btnSub: assignText.popup.delete.btnSub,
                      onClickMain: () => {
                        // TODO: fetch
                        console.log("📥fetch del");
                        console.table({
                          iWokId: assign.id,
                        });
                        let data = {
                          AgWkId: assign.id,
                        };
                        fetch(
                          `${
                            process.env.NODE_ENV === "development"
                              ? "https://cors-anywhere.herokuapp.com/"
                              : ""
                          }${process.env.REACT_APP_URL}${
                            Admin.includes(selectItem?.dpt.dsply)
                              ? "delagtk"
                              : "delinstall"
                          }`,
                          {
                            headers: new Headers({
                              "Content-Type": "application/json",
                            }),
                            method: "POST",
                            body: JSON.stringify(data),
                          }
                        )
                          .then((res) => res.json())
                          .then((json) => {
                            if (json.code === 200) {
                              alert.success(
                                assignText.alert.delete.success.replace(
                                  "%作業%",
                                  selectItem.wok.name
                                )
                              );

                              AssignDispatch({
                                type: "setState",
                                payload: {
                                  isReload: true,
                                  showAssign: false,
                                },
                              });
                              AssignDispatch({
                                type: "clearTimeline",
                                payload: {},
                              });
                            } else {
                              alert.error(errorText["403"]);
                            }
                          });
                        InitDispatch({ type: "closePopup", payload: {} });
                      },
                      onClickSub: () =>
                        InitDispatch({ type: "closePopup", payload: {} }),
                    },
                  },
                });
              }}
            />
            <Button
              text={assignText.status.edit.btn2}
              type="main"
              multi={true}
              disabled={
                (assign.time.start === selectItem?.start_time &&
                  assign.time.end === selectItem?.end_time &&
                  assign.staff
                    .slice()
                    .sort()
                    .equals(
                      items
                        ?.filter((ele) => ele.wokId === selectItem?.wokId)
                        .map((ele) => ele.group)
                        .sort()
                    ) &&
                  (selectItem?.dpt.dsply !== "NoGpTk" ||
                    assign.temp_task
                      .slice()
                      .filter((task) => task !== "")
                      .sort()
                      .equals(selectItem?.temp_task.slice().sort()))) ||
                !(
                  assign.time.start &&
                  assign.time.end &&
                  assign.staff.length &&
                  (selectItem?.dpt.dsply !== "NoGpTk" ||
                    assign.temp_task?.filter((ele) => ele).length)
                )
                  ? true
                  : false
              }
              onClick={() => {
                InitDispatch({
                  type: "setState",
                  payload: {
                    popup: {
                      ...popup,
                      open: true,
                      title: assignText.popup.edit.title,
                      content: `<ul>
                            ${
                              assign.time.start !== selectItem?.start_time ||
                              assign.time.end !== selectItem?.end_time
                                ? `<li>
                                  - ${assignText.popup.edit.content.time
                                    .replace(
                                      "%開始時間%",
                                      moment(assign.time.start).format("HH:mm")
                                    )
                                    .replace(
                                      "%結束時間%",
                                      moment(assign.time.end).format("HH:mm")
                                    )}
                                </li>`
                                : ""
                            }
                            ${
                              assign.staff.filter(
                                (ele) =>
                                  items
                                    .filter(
                                      (ele) => ele.wokId === selectItem?.wokId
                                    )
                                    .map((ele) => ele.group)
                                    .indexOf(ele) === -1
                              ).length
                                ? `<li>
                                - ${assignText.popup.edit.content.staffAdd.replace(
                                  "%新增人員%",
                                  groups
                                    .filter((ele) =>
                                      assign.staff
                                        .filter(
                                          (ele) =>
                                            items
                                              .filter(
                                                (ele) =>
                                                  ele.wokId ===
                                                  selectItem?.wokId
                                              )
                                              .map((ele) => ele.group)
                                              .indexOf(ele) === -1
                                        )
                                        .includes(ele.id)
                                    )
                                    .map((ele) => ele.title)
                                    .join(", ")
                                )}
                              </li>`
                                : ""
                            }
                            ${
                              items
                                .filter(
                                  (ele) => ele.wokId === selectItem?.wokId
                                )
                                .map((ele) => ele.group)
                                .filter(
                                  (ele) => assign.staff.indexOf(ele) === -1
                                ).length
                                ? `<li>
                                - ${assignText.popup.edit.content.staffDel.replace(
                                  "%刪除人員%",
                                  groups
                                    .filter((ele) =>
                                      items
                                        .filter(
                                          (ele) =>
                                            ele.wokId === selectItem?.wokId
                                        )
                                        .map((ele) => ele.group)
                                        .filter(
                                          (ele) =>
                                            assign.staff.indexOf(ele) === -1
                                        )
                                        .includes(ele.id)
                                    )
                                    .map((ele) => ele.title)
                                    .join(", ")
                                )}
                              </li>`
                                : ""
                            }
                            ${
                              selectItem?.dpt?.dsply === "NoGpTk" &&
                              assign.temp_task !== selectItem?.temp_task
                                ? `<li>
                            - ${assignText.popup.edit.content.temp_task.replace(
                              "%任務%",
                              assign.temp_task.filter((ele) => ele).join(", ")
                            )}
                          </li>`
                                : ""
                            }
                          </ul>
                        `,
                      btnSub: assignText.popup.edit.btnSub,
                      btnMain: assignText.popup.edit.btnMain,
                      onClickMain: () => {
                        if (assign.store.length === 0) {
                          let data = {
                            AgWkId: assign.id,
                            AgDate: workDate.format("YYYY-MM-DD"),
                            SDT: moment(assign.time.start).format(
                              "YYYY-MM-DD HH:mm"
                            ),
                            EDT: moment(assign.time.end).format(
                              "YYYY-MM-DD HH:mm"
                            ),
                            AgMagId: account.id,
                            user: assign.staff,
                            TpWkId: assign.work,
                            AgTkName: assign.temp_task.filter(
                              (task) => task !== ""
                            ),
                          };
                          fetch(
                            `${
                              process.env.NODE_ENV === "development"
                                ? "https://cors-anywhere.herokuapp.com/"
                                : ""
                            }${process.env.REACT_APP_URL}updateintall`,
                            {
                              headers: new Headers({
                                "Content-Type": "application/json",
                              }),
                              method: "POST",
                              body: JSON.stringify(data),
                            }
                          )
                            .then((res) => res.json())
                            .then((res) => {
                              console.log(res);

                              if (res.status !== 403) {
                                AssignDispatch({
                                  type: "setState",
                                  payload: {
                                    isReload: true,
                                    showAssign: false,
                                  },
                                });
                                alert.success(
                                  assignText.alert.edit.success.replace(
                                    "%作業%",
                                    selectItem.wok.name
                                  )
                                );
                              } else {
                                alert.error(errorText["403"]);
                              }
                              InitDispatch({ type: "closePopup", payload: {} });
                            });
                        } else {
                          let data = {
                            AgWkId: assign.id,
                            AgDate: workDate.format("YYYY-MM-DD"),
                            SDT: moment(assign.time.start).format(
                              "YYYY-MM-DD HH:mm"
                            ),
                            EDT: moment(assign.time.end).format(
                              "YYYY-MM-DD HH:mm"
                            ),
                            AgMagId: account.id,
                            NewAgEmpId: assign.staff,
                            OldAgEmpId: items
                              .filter((item) => item.wokId === assign.id)
                              .map((item) => item.group),
                            RvStId: assign.store,
                            TpWkId: assign.work,
                            Dsply: selectItem.wok.dsply,
                          };
                          fetch(
                            `${
                              process.env.NODE_ENV === "development"
                                ? "https://cors-anywhere.herokuapp.com/"
                                : ""
                            }${process.env.REACT_APP_URL}updatetask`,
                            {
                              headers: new Headers({
                                "Content-Type": "application/json",
                              }),
                              method: "POST",
                              body: JSON.stringify(data),
                            }
                          )
                            .then((res) => res.json())
                            .then(({ code }) => {
                              if (code === 200) {
                                AssignDispatch({
                                  type: "setState",
                                  payload: {
                                    isReload: true,
                                    showAssign: false,
                                  },
                                });
                                alert.success(
                                  assignText.alert.edit.success.replace(
                                    "%作業%",
                                    selectItem.wok.name
                                  )
                                );

                                AssignDispatch({
                                  type: "clearTimeline",
                                  payload: {},
                                });
                              } else {
                                alert.error(errorText["403"]);
                              }
                              InitDispatch({ type: "closePopup", payload: {} });
                            });
                        }
                      },
                      onClickSub: () =>
                        InitDispatch({ type: "closePopup", payload: {} }),
                    },
                  },
                });
              }}
            />
          </>
        )}
        <CloseOutlined
          className="assign-close"
          onClick={() => {
            const todo = () => {
              AssignDispatch({
                type: "setState",
                payload: {
                  store: [],
                  tmp: [],
                  wok: [],
                  items: items.map((item) =>
                    Object.assign({}, item, {
                      className: "",
                      selected: false,
                      itemProps: {
                        style: {
                          background: item.color,
                        },
                      },
                    })
                  ),
                  assign: {
                    id: 0,
                    time: { start: 0, end: 0 },
                    staff: [],
                    dptTyp: null,
                    dpt: null,
                    date: { start: "", end: "" },
                    tmp: null,
                    work: null,
                    store: [],
                    temp_work: null,
                    temp_task: [""],
                    updateAt: null,
                  },
                  tagColor: "",
                  status: "unselect",
                  selected: [],
                  selectItem: undefined,
                  showAssign: false,
                },
              });
            };
            if (status === "edit" && selectItem?.id < 100000) {
              if (
                assign.time.start === selectItem?.start_time &&
                assign.time.end === selectItem?.end_time &&
                assign.staff
                  .slice()
                  .sort()
                  .equals(
                    items
                      ?.filter((ele) => ele.wokId === selectItem?.wokId)
                      .map((ele) => ele.group)
                      .sort()
                  ) &&
                (selectItem?.dpt.dsply !== "NoGpTk" ||
                  assign.temp_task
                    .slice()
                    .sort()
                    .equals(selectItem?.temp_task.slice().sort()))
              ) {
                todo();
              } else {
                InitDispatch({
                  type: "setState",
                  payload: {
                    popup: {
                      ...popup,
                      open: true,
                      title: assignText.popup.unedit.title,
                      content: assignText.popup.unedit.content,
                      btnMain: assignText.popup.unedit.btnMain,
                      btnSub: assignText.popup.unedit.btnSub,
                      onClickMain: () => {
                        todo();
                        InitDispatch({ type: "closePopup", payload: {} });
                      },
                      onClickSub: () =>
                        InitDispatch({ type: "closePopup", payload: {} }),
                    },
                  },
                });
              }
            } else if (status === "new") {
              AssignDispatch({
                type: "setState",
                payload: {
                  selected: [],
                  items: items.map((item) =>
                    Object.assign({}, item, {
                      className: "",
                      selected: false,
                      itemProps: {
                        style: {
                          background: item.color,
                        },
                      },
                    })
                  ),
                  showAssign: false,
                },
              });
            }
          }}
        />
      </div>
    </>
  );
};

import { useEffect } from "react";
import { useHistory } from "react-router-dom";

/** LINE登入導向頁 */
export const Redirect = () => {
  let history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.table({ code: params.get("code"), state: params.get("state") });

    // TODO: fetch
    // if fail, redirect to /login
    // history.replace("/login");

    // if not bind, redirect to /bind
    // history.replace("/bind");

    // if success, redirect to /home
    // history.replace("/home");
  }, []);

  return (
    <div>
      <h1>redirect...</h1>
    </div>
  );
};

import {
  EnterOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  InfoCircleFilled,
  LeftOutlined,
} from "@ant-design/icons";
import { Checkbox, Avatar, Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import { TaskTable } from "./TaskTable";
import { TaskCard } from "./TaskCard";
import { staticText } from "../../utils/staticText";
import { useAlert } from "react-alert";
import { InitContext } from "../../contexts/init";
import moment from "moment";
import { TempWorkContext } from "../../contexts/tmpwork";

import { TmpTaskList } from "../../contexts/tmpwork";

export const TaskList = ({
  id,
  WkName,
  tip,
  expMin,
  staf,
  data,
  SDT,
  EDT,
  active,
  index,
}: TmpTaskList & { index: number }) => {
  const { data: InitData, dispatch: InitDispatch } = useContext(InitContext);
  const { popup, account, taskVisible } = InitData;
  const { data: TempWorkData, dispatch } = useContext(TempWorkContext);
  const { tmpWok, tmpWokList, status } = TempWorkData;
  const [time, setTime] = useState("");

  const { overview, loading } = staticText;
  const alert = useAlert();

  useEffect(() => {
    const countdown = () => {
      if (
        moment(EDT).diff(moment()) <
          tmpWokList.find((ele) => ele.id === tmpWok).expMin * 60 * 1000 &&
        moment(EDT).diff(moment()) > 0
      ) {
        let diff = moment(EDT).diff(moment());
        let days = Math.floor(diff / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        setTime(
          `${overview.wok.countdown.last}${
            days ? `${days}${overview.wok.countdown.day}` : ""
          }${hours ? `${hours}${overview.wok.countdown.hour}` : ""}${
            minutes ? `${minutes}${overview.wok.countdown.minute}` : ""
          }`
        );
      } else if (moment(EDT).diff(moment()) < 0) {
        let diff = moment().diff(moment(EDT));
        let days = Math.floor(diff / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        setTime(
          `${overview.wok.countdown.over}${
            days ? `${days}${overview.wok.countdown.day}` : ""
          }${hours ? `${hours}${overview.wok.countdown.hour}` : ""}${
            minutes ? `${minutes}${overview.wok.countdown.minute}` : ""
          }`
        );
      }
    };
    countdown();
    const interval = setInterval(() => {
      countdown();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="woklist-title-box">
        <h3 className={`overview-title${active === "D" ? " area-del" : ""}`}>
          <span className="wok">{WkName}</span>
          {tip && (
            <>
              <InfoCircleFilled
                className="tips-icon mobile"
                onClick={() => {
                  window.open(tip, "_blank");
                }}
              />
            </>
          )}
          <span className="wok mobile">
            {WkName}
            {tip && (
              <>
                <InfoCircleFilled
                  className="tips-icon"
                  onClick={() => {
                    window.open(tip, "_blank");
                  }}
                />
              </>
            )}
          </span>
        </h3>
        <Avatar.Group
          className="avatar-group"
          maxCount={4}
          size="default"
          maxStyle={{ color: "#ffffff", backgroundColor: "#5e94fd" }}
        >
          {staf.map((ele) => {
            return (
              <Tooltip title={ele.name} placement="top" key={ele.id}>
                <Avatar src={ele.pic} />
              </Tooltip>
            );
          })}
        </Avatar.Group>
        {index === 0 && (
          <div className="function-box">
            <div
              className={`set-show${status === "showTask" ? " task-hide" : ""}`}
              onClick={() => {
                InitDispatch({
                  type: "setState",
                  payload: { taskVisible: !taskVisible },
                });
                localStorage.setItem("showFnshTask", `${!taskVisible}`);
              }}
            >
              {taskVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              <span className="show-text">
                {taskVisible ? overview.task.hide : overview.task.show}
                {overview.task.text}
              </span>
            </div>
          </div>
        )}
      </div>
      <p
        className={`work-time ${
          moment(EDT).diff(moment()) <
            tmpWokList.find((ele) => ele.id === tmpWok)?.expMin * 60 * 1000 &&
          "countdown"
        }`}
      >
        {moment(SDT).format("HH:mm")} ~ {moment(EDT).format("HH:mm")}{" "}
        {moment(EDT).diff(moment()) <
          tmpWokList.find((ele) => ele.id === tmpWok)?.expMin * 60 * 1000 &&
          time}
      </p>
      <div className="select-all-box tmp">
        <Checkbox
          checked={
            data.length === data.filter((ele) => ele.stat === "Y").length
          }
          // disabled={
          //   staf.find((staff) => staff.id === account.id) ? false : true
          // }
          indeterminate={
            !(
              data.length === data.filter((ele) => ele.stat === "O").length ||
              data.length === data.filter((ele) => ele.stat === "Y").length
            )
          }
          onChange={(e) => {
            InitDispatch({
              type: "setState",
              payload: { spinning: { value: true, tip: loading.handle } },
            });
            // TODO: fetch
            console.log("📥fetch chk");
            console.table({
              data: data.map((task) => {
                return {
                  AgWkId: id,
                  GsId: task.GsId,
                  where: null,
                  CpEmpId: account.id,
                  AgTkId: task.id,
                  ChkStus: e.target.checked ? "Y" : "O",
                  DTS: e.target.checked
                    ? moment().format("YYYY-MM-DD HH:mm:ss")
                    : task.DTS,
                };
              }),
            });

            fetch(
              `${
                process.env.NODE_ENV === "development"
                  ? "https://cors-anywhere.herokuapp.com/"
                  : ""
              }${process.env.REACT_APP_URL}coptask`,
              {
                method: "POST",
                body: JSON.stringify(
                  data.map((task) => {
                    return {
                      AgWkId: id,
                      GsId: task.GsId,
                      where: null,
                      CpEmpId: account.id,
                      AgTkId: task.id,
                      ChkStus: e.target.checked ? "Y" : "O",
                      DTS: e.target.checked
                        ? moment().format("YYYY-MM-DD HH:mm:ss")
                        : task.DTS,
                    };
                  })
                ),
              }
            )
              .then((res) => res.json())
              .then((res) => {
                console.log(res);

                alert.success(
                  e.target.checked
                    ? overview.alert.success.replace(
                        "%任務數%",
                        data
                          .filter((task) => task.stat === "O")
                          .length.toString()
                      )
                    : overview.alert.cancel.replace(
                        "%任務數%",
                        data
                          .filter((task) => task.stat === "Y")
                          .length.toString()
                      )
                );

                let newTmpWokList = tmpWokList.slice();
                if (active === "Y") {
                  e.target.checked
                    ? (newTmpWokList.find((wok) => wok.id === tmpWok).num -=
                        data.filter((task) => task.stat === "O").length)
                    : (newTmpWokList.find((wok) => wok.id === tmpWok).num +=
                        data.filter((task) => task.stat === "Y").length);
                }
                dispatch({
                  type: "setState",
                  payload: {
                    reload: true,
                    tmpWokList: newTmpWokList,
                  },
                });
              });
          }}
        >
          全選
        </Checkbox>
      </div>
      <table className="task-table">
        <thead>
          <tr>
            <th className="task-title">
              <Checkbox
                checked={
                  data.length === data.filter((ele) => ele.stat === "Y").length
                }
                // disabled={
                //   staf.find((staff) => staff.id === account.id) ? false : true
                // }
                indeterminate={
                  !(
                    data.length ===
                      data.filter((ele) => ele.stat === "Y").length ||
                    data.length ===
                      data.filter((ele) => ele.stat === "O").length
                  )
                }
                onChange={(e) => {
                  InitDispatch({
                    type: "setState",
                    payload: { spinning: { value: true, tip: loading.handle } },
                  });
                  // TODO: fetch
                  console.log("📥fetch chk");
                  console.table({
                    data: data.map((task) => {
                      return {
                        AgWkId: id,
                        GsId: task.GsId,
                        CpEmpId: account.id,
                        AgTkId: task.id,
                        ChkStus: e.target.checked ? "Y" : "O",
                        DTS: e.target.checked
                          ? moment().format("YYYY-MM-DD HH:mm:ss")
                          : task.DTS,
                      };
                    }),
                  });
                  // TODO: if success,set task
                  fetch(
                    `${
                      process.env.NODE_ENV === "development"
                        ? "https://cors-anywhere.herokuapp.com/"
                        : ""
                    }${process.env.REACT_APP_URL}coptask`,
                    {
                      method: "POST",
                      body: JSON.stringify(
                        data.map((task) => {
                          return {
                            AgWkId: id,
                            GsId: task.GsId,
                            where: null,
                            CpEmpId: account.id,
                            AgTkId: task.id,
                            ChkStus: e.target.checked ? "Y" : "O",
                            DTS: e.target.checked
                              ? moment().format("YYYY-MM-DD HH:mm:ss")
                              : task.DTS,
                          };
                        })
                      ),
                    }
                  )
                    .then((res) => res.json())
                    .then((res) => {
                      console.log(res);

                      if (res) {
                        alert.success(
                          e.target.checked
                            ? overview.alert.success.replace(
                                "%任務數%",
                                data
                                  .filter((item) => item.stat === "O")
                                  .length.toString()
                              )
                            : overview.alert.cancel.replace(
                                "%任務數%",
                                data
                                  .filter((item) => item.stat === "Y")
                                  .length.toString()
                              )
                        );

                        let newTmpWokList = tmpWokList.slice();
                        if (active === "Y") {
                          e.target.checked
                            ? (newTmpWokList.find(
                                (wok) => wok.id === tmpWok
                              ).num -= data.filter(
                                (task) => task.stat === "O"
                              ).length)
                            : (newTmpWokList.find(
                                (wok) => wok.id === tmpWok
                              ).num += data.filter(
                                (task) => task.stat === "Y"
                              ).length);
                        }

                        dispatch({
                          type: "setState",
                          payload: {
                            reload: true,
                            tmpWokList: newTmpWokList,
                          },
                        });
                      }
                    });
                }}
              ></Checkbox>
              {overview.task.form.title1}
            </th>
            <th>{overview.task.form.title2}</th>
            <th>{overview.task.form.title3}</th>
          </tr>
        </thead>
        <tbody className={`${taskVisible ? "show-fnsh" : ""}`}>
          {data
            .slice()
            .sort((t1, t2) => (t2.stat === "Y" ? -1 : 1))
            .map((ele, i) => {
              return (
                <TaskTable
                  key={i}
                  {...ele}
                  staf={staf}
                  wkId={id}
                  active={active}
                />
              );
            })}
        </tbody>
      </table>
      <div className={`task-card-list ${taskVisible ? "show-fnsh" : ""}`}>
        {data
          .slice()
          .sort((t1, t2) => (t2.stat === "Y" ? -1 : 1))
          .map((ele, i) => {
            return (
              <TaskCard
                key={i}
                {...ele}
                staf={staf}
                wkId={id}
                active={active}
              />
            );
          })}
      </div>
    </>
  );
};

import { createContext, useReducer } from "react";
import { Tasks, TmpWoks, Woks } from "../utils/types";

export enum Tab {
  'null' = 1,
  'fnh',
  'cxl',
  'all',
};

export interface OverviewData {
  tmpTyp?: number;
  tmp?: number;
  tmpList?: {
    id: number;
    name: string;
  }[];
  tmpWok?: number;
  tmpWokList?: TmpWoks;
  wokList?: Woks[];
  taskLists?: Array<Tasks[]>;
  workDate?: moment.Moment | undefined;
  status?: "showControl" | "showCount" | "showWork" | "showTask";
  hideFinish?: boolean;
  area?: {
    id: number;
    name: string;
  };
  work?: {
    id: number;
    name: string;
  };
  openCount?: boolean;
  tab?: 'fnh' | 'cxl' | 'all';
  selectedTip?: string;
  reload?: boolean;
  reloadTask?: boolean;
  pubDates?: string[];
}

const data: OverviewData = {
  tmpTyp: 0,
  tmp: 0,
  tmpList: [],
  tmpWok: 0,
  tmpWokList: [],
  wokList: [],
  taskLists: [],
  workDate: undefined,
  status: "showControl",
  hideFinish: false,
  area: { id: 0, name: "" },
  work: { id: 0, name: "" },
  openCount: true,
  tab: null,
  selectedTip: "",
  reload: true,
  reloadTask: true,
  pubDates: [],
};

export interface ActionType {
  type: "setState";
  payload: OverviewData;
}

function reducer(state: OverviewData, action: ActionType) {
  switch (action.type) {
    case "setState":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export interface OverviewContextType {
  data: OverviewData;
  dispatch: React.Dispatch<ActionType>;
}

export const OverviewContext = createContext<OverviewContextType>({
  data,
  dispatch: () => {},
});

export const OverviewContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, data);
  return (
    <OverviewContext.Provider value={{ data: state, dispatch }}>
      {children}
    </OverviewContext.Provider>
  );
};

import { Timeline } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { staticText } from "../../utils/staticText";
import { Wok } from "./Wok";
import moment from "moment";
import { ReactComponent as NoData } from "../../images/assign/no-data.svg";
import { MyworkContext } from "../../contexts/mywork";

export const WokList = () => {
  const { data: MyworkData } = useContext(MyworkContext);
  const { wokLists } = MyworkData;

  const { mywork } = staticText;
  const [time, setTime] = useState(moment());
  const [wokList] = useState(wokLists.filter(wok => wok.active === "Y"));
  const [delWokList] = useState(wokLists.filter(wok => wok.active === "D"));

  useEffect(() => {
    const calcTime = () => {
      setTime(moment());
    };
    calcTime();
    const interval = setInterval(() => {
      calcTime();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <h3 className="mywork-title">{mywork.wok.title}</h3>
      {wokList.length ? (
        <Timeline style={{ paddingLeft: "13px" }}>
          <Timeline.Item dot={" "}>&nbsp;</Timeline.Item>
          {wokList.filter((ele) => moment(ele.STS) > time).length ===
            wokList.length && (
            <div className="line-now">
              <div className="red-dot"></div>
              <div className="red-line"></div>
            </div>
          )}
          {wokList.map((ele, i) => {
            return (
              <React.Fragment key={i}>
                <Wok {...ele} />
                {(i < wokList.length - 1
                  ? moment(ele.STS) < time &&
                    moment(wokList[i + 1].STS) >= time
                  : moment(ele.STS) < time) && (
                  <div className="line-now">
                    <div className="red-dot"></div>
                    <div className="red-line"></div>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </Timeline>
      ) : (
        <div className="no-data">
          <NoData />
          <h3>{mywork.noData.text1}</h3>
          <h3>{mywork.noData.text2}</h3>
        </div>
      )}
      <h3 className="mywork-title" style={{ marginTop: "30px" }}>
        {mywork.delWok.title}
      </h3>
      {delWokList.length ? (
        <Timeline style={{ paddingLeft: "13px" }}>
          <Timeline.Item dot={" "}>&nbsp;</Timeline.Item>
          {delWokList.filter((ele) => moment(ele.STS) > time).length ===
            delWokList.length && (
            <div className="line-now">
              <div className="red-dot"></div>
              <div className="red-line"></div>
            </div>
          )}
          {delWokList.map((ele, i) => {
            return (
              <React.Fragment key={i}>
                <Wok {...ele} />
                {(i < delWokList.length - 1
                  ? moment(ele.STS) < time &&
                    moment(delWokList[i + 1].STS) >= time
                  : moment(ele.STS) < time) && (
                  <div className="line-now">
                    <div className="red-dot"></div>
                    <div className="red-line"></div>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </Timeline>
      ) : (
        <div className="no-data">
          <NoData />
          <h3>{mywork.noData.text3}</h3>
        </div>
      )}
    </div>
  );
};

import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useContext } from "react";
import moment from "moment";
import { staticText } from "../../utils/staticText";
import { OverviewContext } from "../../contexts/overview";

export const Count = () => {
  const { overview } = staticText;
  const { data: OverviewData, dispatch: OverviewDispatch } =
    useContext(OverviewContext);
  const { tmpWok, tmpWokList, openCount } = OverviewData;

  return (
    <div className="count-box">
      <h3
        className={`overview-title ${openCount ? "open" : ""}`}
        onClick={() => {
          OverviewDispatch({
            type: "setState",
            payload: {
              openCount: !openCount,
            },
          });
        }}
      >
        {overview.count.title}
        <DownOutlined className="arrow" />
      </h3>
      <div className={`count-list ${openCount ? "open" : ""}`}>
        {tmpWokList.map((ele) => {
          return (
            <div
              className={`count-item ${ele.id === tmpWok ? "selected" : ""}`}
              style={{ background: `${ele.sCol}` }}
              key={ele.id}
              onClick={() => {
                if (ele.id !== tmpWok) {
                  OverviewDispatch({
                    type: "setState",
                    payload: {
                      tmpWok: ele.id,
                      selectedTip: ele.tip,
                      status: "showCount",
                      reload: true,
                    },
                  });
                }
              }}
            >
              <h5 className="count-work">{ele.name}</h5>
              <p className="count-num">{ele.num}</p>
              {moment(ele.cloDT).diff(moment(), "minutes") < ele.expMin && (
                <div className="alert">
                  <ExclamationCircleOutlined className="icon" />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

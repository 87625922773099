import { Progress } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { InitContext } from "../../contexts/init";
import { OverviewContext } from "../../contexts/overview";
import { staticText } from "../../utils/staticText";

export const AreaTable = ({
  id,
  name,
  alt,
  taskFnshNum,
  taskAllNum,
  updDS,
  sysAct,
  wok,
}: {
  id: number;
  name: string;
  alt: string;
  taskFnshNum: number;
  taskAllNum: number;
  updDS: string;
  sysAct: "Y" | "N";
  wok: { id: number; name: string };
}) => {
  const { data: OverviewData, dispatch: OverviewDispatch } =
    useContext(OverviewContext);
  const { dispatch: InitDispatch } = useContext(InitContext);
  const { overview } = staticText;
  const [time, setTime] = useState("");

  useEffect(() => {
    const countdown = () => {
      let diff = moment().diff(moment(updDS));
      let days = Math.floor(diff / (1000 * 60 * 60 * 24));
      let hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      let datetimeStr = `${days ? `${days}${overview.wok.countdown.day}` : ""}${
        hours ? `${hours}${overview.wok.countdown.hour}` : ""
      }${minutes ? `${minutes}${overview.wok.countdown.minute}` : ""}`;

      setTime(datetimeStr ? datetimeStr : overview.wok.countdown.secs);
    };
    countdown();
    let interval: any;
    if (updDS !== "DONE" && updDS !== "NULL") {
      interval = setInterval(() => {
        countdown();
      }, 60000);
    }
    return () => clearInterval(interval);
  }, []);

  return (
    <tr
      key={id}
      onClick={() => {
        OverviewDispatch({
          type: "setState",
          payload: {
            area: { id: id, name: alt },
            work: wok,
            reloadTask: true,
          },
        });
        InitDispatch({
          type: "setState",
          payload: { dataLoading: { value: true, type: "search" } },
        });
      }}
    >
      <td className={`place-box ${sysAct === "Y" ? "area-del" : ""}`}>{alt}</td>
      <td className="progress-box">
        <p className="num">
          {taskFnshNum}/{taskAllNum}
        </p>
        <Progress
          className="progress-bar"
          percent={(taskFnshNum / taskAllNum) * 100}
          showInfo={false}
          strokeColor="#5E94FD"
          trailColor="#E3E3E3"
        />
      </td>
      <td className="upd-time">
        {updDS === "NULL" && overview.status.null}
        {updDS === "DONE" && overview.status.done}
        {updDS !== "NULL" &&
          updDS !== "DONE" &&
          `${time}${overview.status.upd}`}
      </td>
    </tr>
  );
};

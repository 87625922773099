import { createContext, useReducer } from "react";
import { DptTyp } from "../utils/types";

export interface InitData {
  init?: boolean;
  account?: {
    id: number;
    name: string;
    pic: string;
  };
  permission?: {
    id: number;
    name: string;
  };
  popup?: {
    open: boolean;
    // closeClick: () => void;
    title: string;
    content: string;
    btnMain: string;
    btnSub: string;
    onClickMain: () => void;
    onClickSub: () => void;
  };
  spinning?: {
    value: boolean;
    tip?: string;
  };
  dptTyp?: DptTyp;
  dataLoading?: {
    value: boolean;
    type?: "search" | "handle"
  };
  taskVisible?: boolean;
  datepickerOpen?: boolean;
  selectOpen?: boolean;
  isMobile?: boolean;
}

const data: InitData = {
  init: true,
  account: {
    id: null,
    name: "",
    pic: "",
  },
  permission: {
    id: null,
    name: "",
  },
  popup: {
    open: false,
    title: "",
    content: "",
    btnMain: "",
    btnSub: "",
    onClickMain: () => {},
    onClickSub: () => {},
  },
  spinning: {
    value: false,
    tip: "",
  },
  dptTyp: [],
  dataLoading: {
    value: false,
    type: "search",
  },
  taskVisible: true,
  datepickerOpen: false,
  selectOpen: false,
  isMobile: false,
};

interface ActionType {
  type: "setState" | "closePopup";
  payload: InitData;
}

function reducer(state: InitData, action: ActionType) {
  switch (action.type) {
    case "setState":
      return {
        ...state,
        ...action.payload,
      };
    case "closePopup":
      return {
        ...state,
        popup: {
          ...state.popup,
          open: false,
        },
      };
    default:
      return state;
  }
}

interface InitContext {
  data: InitData;
  dispatch: React.Dispatch<ActionType>;
}

export const InitContext = createContext<InitContext>({
  data,
  dispatch: () => {},
});

export const InitContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, data);
  return (
    <InitContext.Provider value={{ data: state, dispatch }}>
      {children}
    </InitContext.Provider>
  );
};

import { Spin } from "antd";
import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { InitContext } from "../../contexts/init";
import { Popup } from "../ui/Popup";
import { Nav } from "./Nav";

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const { data: InitData, dispatch: InitDispatch } = useContext(InitContext);
  const location = useLocation();
  const { pathname } = location;
  const { popup, spinning, datepickerOpen, selectOpen } = InitData;

  useEffect(() => {
    let timer;
    const resize = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        InitDispatch({
          type: "setState",
          payload: { isMobile: window.innerWidth <= 960 },
        });
      }, 100);
    };

    resize();

    window.addEventListener("resize", resize);

    return () => window.removeEventListener("resize", resize);
  }, []);

  useEffect(() => {
    fetch(
      `${
        process.env.NODE_ENV === "development"
          ? "https://cors-anywhere.herokuapp.com/"
          : ""
      }${process.env.REACT_APP_URL}typeinfo`
    )
      .then((res) => res.json())
      .then((res) => {
        InitDispatch({
          type: "setState",
          payload: {
            popup: {
              ...popup,
              onClickSub: () => {
                InitDispatch({
                  type: "setState",
                  payload: {
                    popup: {
                      ...popup,
                      open: false,
                    },
                  },
                });
              },
            },
            dptTyp: res.map((vTyp) => {
              return {
                id: vTyp.DpTpId,
                name: vTyp.TypName,
                dpt: vTyp.dpt.map((v) => {
                  return {
                    id: v.DpIfId,
                    name: v.TpIfName,
                    dsply: v.Dsply,
                  };
                }),
              };
            }),
          },
        });
      })
      .catch((e) => console.log(e));

    fetch(
      `${
        process.env.NODE_ENV === "development"
          ? "https://cors-anywhere.herokuapp.com/"
          : ""
      }${process.env.REACT_APP_URL}profile`
    )
      .then((res) => res.json())
      .then(({ user, level }) => {
        InitDispatch({
          type: "setState",
          payload: {
            account: {
              id: process.env.NODE_ENV === "development" ? 12 : user.id,
              name:
                process.env.NODE_ENV === "development" ? "Member" : user.name,
              pic: process.env.NODE_ENV === "development" ? "" : user.pic,
            },
            permission: {
              id: process.env.NODE_ENV === "development" ? 1 : level.id,
              name:
                process.env.NODE_ENV === "development"
                  ? "Authority level"
                  : level.name,
            },
          },
        });
      })
      .catch(e => {
        if (process.env.NODE_ENV === "development") {
          InitDispatch({
            type: "setState",
            payload: {
              account: {
                id: 12,
                name: "Member",
                pic: "",
              },
              permission: {
                id: 1,
                name: "Authority level",
              },
            },
          });
        }
      });
  }, []);

  useEffect(() => {
    // TODO: fetch(work, area), get tasks data
    if (localStorage.getItem("showFnshTask")) {
      InitDispatch({
        type: "setState",
        payload: {
          taskVisible:
            localStorage.getItem("showFnshTask") === "true" ? true : false,
        },
      });
    } else {
      localStorage.setItem("showFnshTask", "true");
    }
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [pathname]);

  return (
    <>
      <Spin
        tip={spinning.tip}
        size="large"
        className="spinner"
        spinning={spinning.value}
      >
        <div className="layout-wrapper">
          <Nav />

          <div className="children-wrapper">{children} </div>
          {popup.open && <Popup />}
        </div>
      </Spin>
      <div
        className={`date-picker-wrapper${datepickerOpen ? " show" : ""}`}
      ></div>
      <div className={`ant-select-wrapper${selectOpen ? " show" : ""}`}></div>
    </>
  );
};

import { UserOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../images/layout/logo.svg";
import { staticText } from "../utils/staticText";

/** 忘記密碼 */
export const ForgotPwd = () => {
  const { login } = staticText;

  const onFinish = (values: any) => {
    // TODO: fetch API
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    document.title = "忘記密碼 | Tickup 任務指派系統";
  }, []);

  return (
    <div className="login-wrapper">
      <Form
        className="login-block"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Logo className="logo" />
        <div className="input-block">
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: login.message.replace("%項目%", login.email),
              },
              {
                required: false,
                type: "email",
                message: login.emailValid,
              },
            ]}
          >
            <Input
              size="large"
              prefix={<UserOutlined />}
              placeholder={login.email}
            />
          </Form.Item>
        </div>
        <div className="btn-wrapper">
          <button className="reset-btn" type="submit">
            {login.btn.reset}
          </button>
          <Link to="/login">
            <button className="back-btn">{login.btn.back}</button>
          </Link>
        </div>
      </Form>
    </div>
  );
};

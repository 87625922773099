import {
  BankOutlined,
  EditOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Form, Input } from "antd";
import { useContext, useEffect, useState } from "react";
import { ReactComponent as Logo } from "../images/layout/logo.svg";
import { staticText } from "../utils/staticText";
// import reloadImg from "../images/login/reload.png";
import { ReactComponent as Line } from "../images/login/line-edit.svg";
import { Link, useHistory } from "react-router-dom";
import { Popup } from "../components/ui/Popup";
import { Button } from "../components/ui/Button";
import { InitContext } from "../contexts/init";

/** 登入頁 */
export const Login = () => {
  const { data: InitData, dispatch: InitDispatch } = useContext(InitContext);
  const { popup } = InitData;

  const { login } = staticText;
  const [reload, setReload] = useState(true);
  const [fail, setFail] = useState(false);
  let history = useHistory();
  const [verifyImg, setVerifyImg] = useState({ key: "", img: "" });

  const onFinish = (values: any) => {
    // TODO: fetch API
    console.log("📥fetch login");
    console.table({
      HId: values.hotel,
      email: values.email,
      password: values.password,
      key: verifyImg.key,
      captcha: values.verify,
    });

    let data = {
      HId: values.hotel,
      email: values.email,
      password: values.password,
      key: verifyImg.key,
      captcha: values.verify,
    };
    console.log(data);
    

    fetch(`${process.env.NODE_ENV === 'development' ? 'https://cors-anywhere.herokuapp.com/' : ''}${process.env.REACT_APP_URL}login`, {
      headers: new Headers({
        "Content-Type": "application/json",
        "Accept": "application/json",
      }),
      method: "POST",
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(res => {
        console.log(res);
        
        if (res.status === 'success') {
          InitDispatch({type: 'setState', payload: {
            account: {
              id: res.id,
              name: res.name,
              pic: res.photo
            }
          }});
          history.push("/home");
          // history.push("/notice/internal");
        } else {
          setReload(true);
          setFail(true);
        }
      })
      .catch(e => setReload(true));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    document.title = "登入 | Tickup 任務指派系統";
  }, []);
  useEffect(() => {
    if (reload) {
      fetch(`${process.env.NODE_ENV === 'development' ? 'https://cors-anywhere.herokuapp.com/' : ''}${process.env.REACT_APP_URL}captkey`)
        .then((res) => {
          if (!res.ok) {
            setReload(false);
            throw new Error(res.statusText);
          } else {
            return res.json();
          }
        })
        .then((json) => {
          setVerifyImg({ key: json.url.key, img: json.url.img });
          setReload(false);
        })
        .catch(() => {});
    }
  }, [reload]);

  return (
    <div className="login-wrapper">
      <Form
        className="login-block"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Logo className="logo" />
        <h2 className="title">{login.login.title}</h2>
        <div className="input-block">
          <Form.Item
            name="hotel"
            rules={[
              {
                required: true,
                message: login.message.replace("%項目%", login.hotel),
              },
            ]}
          >
            <Input
              size="large"
              prefix={<BankOutlined />}
              placeholder={login.hotel}
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: login.message.replace("%項目%", login.email),
              },
              {
                required: false,
                type: "email",
                message: login.emailValid,
              },
            ]}
          >
            <Input
              size="large"
              prefix={<UserOutlined />}
              placeholder={login.email}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: login.message.replace("%項目%", login.password),
              },
            ]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder={login.password}
            />
          </Form.Item>
          {/* <Link to="/forgot-password">
            <p className="forgot-pwd">{login.forgotPwd}</p>
          </Link> */}
        </div>
        <div className="verify-block">
          <Form.Item
            name="verify"
            rules={[
              {
                required: true,
                message: login.message.replace("%項目%", login.verify),
              },
              {
                min: 4,
                max: 4,
                message: login.verifyValid,
              },
            ]}
          >
            <Input
              size="large"
              prefix={<EditOutlined />}
              placeholder={login.verify}
              minLength={4}
              maxLength={4}
            />
          </Form.Item>
          <div className="verify-img-block">
            {verifyImg.img && (
              <img
                src={verifyImg.img}
                className="verify-img"
                onClick={() => setReload(true)}
              />
            )}
            {/* <img
              src={reloadImg}
              className="verify-reload"
              onClick={() => setReload(true)}
            /> */}
          </div>
        </div>
        <button
          className="login-btn"
          type="submit"
        >
          {login.btn.login}
        </button>
        {fail && <p className="login-fail">{login.fail}</p>}
        {/* <Link to="/forgot-password">
          <p className="forgot-pwd">{login.forgotPwd}</p>
        </Link> */}
        <div className="others-box">
          <hr />
          <p>{login.login.other}</p>
        </div>
        <button
          className="line-login"
          type="button"
          onClick={() => {
            InitDispatch({
              type: "setState",
              payload: {
                popup: {
                  ...popup,
                  open: true,
                  title: login.line.title,
                  content: login.line.content,
                  btnMain: login.line.btnMain,
                  btnSub: login.line.btnSub,
                  onClickMain: () => {
                    // TODO: link to LINE LOGIN
                    InitDispatch({ type: "closePopup", payload: {} });
                    // pass client id from laravel blade
                    // window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${(window as any).client_id}&redirect_uri=${process.env.REACT_APP_URL}callback/login&state=12345abcde&scope=profile%20openid%20email&nonce=09876xyz`;
                    window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${process.env.REACT_APP_CLIENTID}&redirect_uri=${process.env.REACT_APP_URL}callback/login&state=12345abcde&scope=profile%20openid%20email&nonce=09876xyz`;
                  },
                  onClickSub: () => {
                    InitDispatch({type: "closePopup", payload: {}});
                  }
                },
              },
            });
          }}
        >
          <Line className="line-logo" />
          <span className="line-span">{login.line.login}</span>
        </button>
        {/* <div className="link-box">
          <p>{login.login.toSignup.text}</p>
          <Button
            text={login.login.toSignup.btn}
            type="sub"
            onClick={() => {
              history.push("/signup");
            }}
          />
        </div> */}
      </Form>
      {popup.open && <Popup />}
      <div
        style={{
          position: "fixed",
          top: "0",
          right: "0",
          background: "rgba(0,0,0,0.2)",
          zIndex: 9999999,
        }}
      >
      </div>
    </div>
  );
};

import { RightOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { InitContext } from "../../contexts/init";

export const NavItem = ({
  text,
  link,
  icon,
  sub,
  hover,
  setOpenMenu,
}: {
  text: string;
  link: string;
  icon: JSX.Element;
  sub?: { id: number; text: string; link: string }[];
  hover: boolean;
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { data: InitData, dispatch: InitDispatch } = useContext(InitContext);

  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [active, setActive] = useState<boolean>();
  useEffect(() => {
    if (!sub) {
      setActive(location.pathname === link);
    } else {
      setActive(
        sub?.findIndex((ele) => ele.link == location.pathname) !== -1 ||
          location.pathname === link
          ? true
          : false
      );
    }
    setOpen(false);
  }, [location]);

  return (
    <>
      {!sub ? (
        <>
          <Link
            to={link}
            onClick={() => {
              setOpenMenu(false);
              InitDispatch({ type: "setState", payload: { init: true } });
              document.title = `${text} | Tickup 任務指派系統`;
            }}
          >
            <div
              className={`nav-item ${active ? "active" : ""} ${
                open ? "open" : ""
              }`}
            >
              {icon}
              <div className="text-area">
                <h3>{text}</h3>
              </div>
            </div>
          </Link>
        </>
      ) : (
        <>
          <div
            className={`nav-item ${active ? "active" : ""} ${
              open ? "open" : ""
            }`}
            onClick={() => {
              setOpen(!open);
            }}
          >
            {icon}
            <div className="text-area">
              <h3>{text}</h3>
              <RightOutlined className="arrow" />
            </div>
          </div>
          <div
            className={`nav-dropdown ${open ? "open" : ""}`}
            style={{ height: `${hover ? sub.length * 40 : 0}px` }}
          >
            {sub?.map((ele) => {
              if (ele.link === location.pathname) {
                return (
                  <Link
                    key={ele.id}
                    to={{
                      pathname: ele.link,
                      state: {
                        dptId: ele.id,
                        title: ele.text,
                      }
                    }}
                    onClick={() => {
                      setOpenMenu(false);
                      InitDispatch({ type: "setState", payload: { init: true } });
                      document.title = `${ele.text} | Tickup 任務指派系統`;
                    }}
                  >
                    <div
                      className={`nav-dropdown-item ${
                        ele.link === location.pathname ? "active" : ""
                      }`}
                    >
                      <h4>{ele.text}</h4>
                    </div>
                  </Link>
                );
              } else {
                return (
                  <Link
                    key={ele.id}
                    to={{
                      pathname: ele.link,
                      state: {
                        dptId: ele.id,
                        title: ele.text,
                      }
                    }}
                    onClick={() => {
                      setOpenMenu(false);
                      InitDispatch({ type: "setState", payload: { init: true } });
                      document.title = `${ele.text} | Tickup 任務指派系統`;
                    }}
                  >
                    <div
                      className={`nav-dropdown-item ${
                        ele.link === location.pathname ? "active" : ""
                      }`}
                    >
                      <h4>{ele.text}</h4>
                    </div>
                  </Link>
                );
              }
            })}
          </div>
        </>
      )}
    </>
  );
};

import { useContext, useEffect } from "react";
import { Control } from "../components/mywork/Control";
import { WokList } from "../components/mywork/WokList";
import { TaskList } from "../components/mywork/TaskList";
import { Title } from "../components/ui/Title";
import { MyworkContext, ResGetMywork } from "../contexts/mywork";
import moment from "moment";
import { InitContext } from "../contexts/init";
import { Loading } from "../components/ui/Loading";

/** 我的工作 */
export const Mywork = () => {
  const { data: InitData, dispatch: InitDispatch } = useContext(InitContext);
  const { account, dataLoading, permission } = InitData;
  const { data: MyworkData, dispatch: MyworkDispatch } =
    useContext(MyworkContext);
  const {
    workDate,
    status,
    reload,
    area,
    work,
    wokLists,
    reloadTask,
    selectedStaff,
  } = MyworkData;
  useEffect(() => {
    document.title = "我的工作 | Tickup 任務指派系統";
  }, []);

  useEffect(() => {
    // TODO: fetch data
    MyworkDispatch({
      type: "setState",
      payload: {
        // status: "showWork",
        workDate: moment().startOf("day"),
      },
    });
  }, []);

  useEffect(() => {
    MyworkDispatch({
      type: "setState",
      payload: {
        selectedStaff: account.id,
      },
    });
    if (permission.id === 1) {
      fetch(
        `${
          process.env.NODE_ENV === "development"
            ? "https://cors-anywhere.herokuapp.com/"
            : ""
        }${process.env.REACT_APP_URL}getempwork`
      )
        .then((res) => res.json())
        .then((res) =>
          MyworkDispatch({
            type: "setState",
            payload: {
              staff: res.map((user) => {
                return {
                  id: user.Id,
                  name: user.UserName,
                };
              }),
            },
          })
        );
    }
  }, [permission.id]);

  useEffect(() => {
    MyworkDispatch({
      type: "setState",
      payload: {
        reload: true,
        wokLists: [],
        taskLists: [],
        work: { id: null, name: "" },
        area: { id: null, name: "" },
      },
    });
  }, [workDate]);

  useEffect(() => {
    if (
      workDate &&
      status === "showWork" &&
      reload &&
      (permission.id === 2 ? account.id : selectedStaff)
    ) {
      InitDispatch({
        type: "setState",
        payload: {
          dataLoading: { value: true, type: "search" },
        },
      });
      // TODO: fetch data
      console.log("📥fetch wkls");
      console.table({
        sWokDS: workDate.format("YYYY-MM-DD"),
        iStafId: account.id,
      });

      let url = new URL(
        `${
          process.env.NODE_ENV === "development"
            ? "https://cors-anywhere.herokuapp.com/"
            : ""
        }${process.env.REACT_APP_URL}getmywork`
      );
      let params = {
        AgDate: workDate.format("YYYY-MM-DD"),
        userid: permission.id === 2 ? account.id.toString() : selectedStaff.toString(),
      };
      url.search = new URLSearchParams(params).toString();

      fetch(url.toString())
        .then((res) => res.json() as Promise<ResGetMywork[]>)
        .then((res) => {
          console.log(res);

          if (res) {
            MyworkDispatch({
              type: "setState",
              payload: {
                wokLists: res.map((wrk) => {
                  return {
                    id: wrk.AgWkId,
                    name: wrk.TpWkName,
                    STS: wrk.SDT,
                    ETS: wrk.EDT,
                    avrg: moment(
                      (moment(wrk.SDT).valueOf() + moment(wrk.EDT).valueOf()) /
                        2
                    ).format("YYYY-MM-DD HH:mm"),
                    expMin: wrk.Time / (1000 * 60),
                    staf: wrk.user.map((user) => {
                      return {
                        id: user.id,
                        name: user.username,
                        pic: user.profile_photo_path,
                      };
                    }),
                    active: wrk.Active,
                    stoAre: wrk.Area.map((area) => {
                      return {
                        id: area.where,
                        name: area.Name,
                        alt: area.Name,
                        sysAct: area.SysAct,
                        done: area.compl,
                      };
                    }).sort((a, b) => (a.name as string).localeCompare(b.name)),
                    tip: wrk.Html,
                    dsply: wrk.Dsply,
                  };
                }),
                reload: false,
              },
            });
          }
        })
        .finally(() =>
          InitDispatch({
            type: "setState",
            payload: {
              dataLoading: { value: false },
            },
          })
        );
    }
  }, [workDate, account.id, status, reload, selectedStaff]);

  useEffect(() => {
    if (work.id && reloadTask) {
      let url = new URL(
        `${
          process.env.NODE_ENV === "development"
            ? "https://cors-anywhere.herokuapp.com/"
            : ""
        }${process.env.REACT_APP_URL}getmytask`
      );
      let currentWok = wokLists.find((wokList) => wokList.id === work.id);
      let params = {
        AgWkId: work.id.toString(),
        where: area.id ? area.id.toString() : null,
        WkDsply: currentWok.dsply,
        Active: currentWok.active,
        SysAct: currentWok.stoAre.find((sto) => sto.id === area.id).sysAct,
      };
      url.search = new URLSearchParams(params).toString();

      fetch(url.toString())
        .then((res) => res.json())
        .then((res) => {
          console.log(res);

          MyworkDispatch({
            type: "setState",
            payload: {
              taskLists: [
                res.map((task) => {
                  return {
                    id: task.AgTkId,
                    name: task.todo,
                    DTS: task.DTS,
                    stat: task.ChkStus,
                    staf: {
                      id: task.CpEmpId,
                      name: task.username,
                      pic: task.profile_photo_path,
                    },
                    GsId: task.GsId,
                  };
                }),
              ],
              area: { id: area.id, name: area.name },
              work: { id: work.id, name: work.name },
              selectedTip: wokLists.find((wokList) => wokList.id === work.id)
                .tip,
              status: "showTask",
              reloadTask: false,
            },
          });
        })
        .finally(() =>
          InitDispatch({
            type: "setState",
            payload: {
              dataLoading: { value: false },
              spinning: { value: false },
            },
          })
        );
    }
  }, [work.id, area.id, reloadTask]);

  return (
    <div
      className={`mywork-wrapper wrapper ${
        status === "showTask" ? "task-hide" : ""
      }`}
    >
      <div className="frozen-area">
        <Title text="我的工作" />
        {status === "showWork" && <Control />}
        {status === "showWork" && <div className="separator"></div>}
      </div>
      {status === "showWork" && dataLoading.value && <Loading />}
      {status === "showWork" && !dataLoading.value && <WokList />}
      {status === "showTask" && <TaskList />}
    </div>
  );
};

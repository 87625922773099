import React from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { AlertCustomOptionsWithType } from "react-alert";

export const Alert = ({
  style, options, message, close
}: {
  style: React.CSSProperties;
  message: React.ReactNode;
  options: AlertCustomOptionsWithType;
  close(): void;

}) => {
  return (
    <div className={`main-alert ${options.type}`}>
      {options.type === "success" ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
      {message}
    </div>
  );
};

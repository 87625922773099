import { DatePicker, Input } from "antd";
import moment from "moment";
import locale from "antd/es/date-picker/locale/zh_TW";
import React, { useContext, useState } from "react";
import {
  PickerBaseProps,
  PickerDateProps,
  PickerTimeProps,
} from "antd/lib/date-picker/generatePicker";

import { Button } from "../ui/Button";
import { staticText } from "../../utils/staticText";
import { InitContext } from "../../contexts/init";
import { NoticeContext } from "../../contexts/notice";

export const Control = () => {
  const { data: NoticeData, dispatch: NoticeDispatch } = useContext(NoticeContext);
  const { data: InitData, dispatch: InitDispatch } = useContext(InitContext);
  const { time } = NoticeData;

  const { notice } = staticText;

  const [testInput, setTestInput] = useState<React.Component<
    | PickerBaseProps<moment.Moment>
    | PickerDateProps<moment.Moment>
    | PickerTimeProps<moment.Moment>,
    any,
    any
  > | null>();

  const handleDateChange = (date: moment.Moment | null) => {
    if (date) {
      NoticeDispatch({
        type: "setState",
        payload: {},
      });

      // @ts-ignore
      if (testInput) testInput.blur(); // ? TYPE?
    }
  };

  return (
    <div className="control-box notice">
      <h5>{notice.filterByDate}</h5>
      <DatePicker.RangePicker
        inputReadOnly
        style={{ width: "100%" }}
        locale={locale}
        onOpenChange={(value) =>
          InitDispatch({
            type: "setState",
            payload: { datepickerOpen: value },
          })
        }
        getPopupContainer={() => document.querySelector(".date-picker-wrapper")}
        dropdownClassName="area-range-picker"
        onChange={(value) => {
          console.log(value);

          NoticeDispatch({
            type: "setState",
            payload: {
              time: {
                start: value[0],
                end: value[1],
              },
            },
          });
        }}
        value={[time.start ? time.start : null, time.end ? time.end : null]}
      />
      <h5>{notice.filterByWord}</h5>
      <Input></Input>
      <Button
        type="main"
        text={notice.btn.search}
        onClick={() => {
          let url = new URL(
            `${
              process.env.NODE_ENV === "development"
                ? "https://cors-anywhere.herokuapp.com/"
                : ""
            }${process.env.REACT_APP_URL}sysmemo`
          );
          let params = {
            StaDate: time.start.format("YYYY-MM-DD"),
            EndDate: time.end.format("YYYY-MM-DD"),
            search: null,
          };
          url.search = new URLSearchParams(params as any).toString();
          fetch(url.toString())
            .then((res) => res.json())
            .then((res) => {
              console.log(res);
              NoticeDispatch({type: "setState", payload: {
                notices: res.map(resNotice => {
                  return {
                    date: resNotice.date,
                    data: resNotice.data.map(resData => {
                      let tmpArr = resData.split('-');
                      return {
                        name: tmpArr[1],
                        tentNo: tmpArr[0],
                        changes: resData.changes.map(change => {
                          return {
                            col: change.col,
                            old: change.old,
                            new: change.new,
                          }
                        })
                      }
                    })
                  }
                })
              }});
            });
        }}
      ></Button>
    </div>
  );
};

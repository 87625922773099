import { useContext, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Control } from "../components/overview/Control";
import { Count } from "../components/overview/Count";
import { TaskList } from "../components/overview/TaskList";
import { WokList } from "../components/overview/WokList";
import { Title } from "../components/ui/Title";
import { locationState } from "../utils/types";
import { InitContext } from "../contexts/init";
import { OverviewContext } from "../contexts/overview";
import { ReactComponent as SelectTmp } from "../images/overview/SelectTmp.svg";
import { ReactComponent as SelectWok } from "../images/overview/SelectWok.svg";
import { staticText } from "../utils/staticText";
import moment from "moment";

import { tmp as tmps } from "../mockData/overview/tmp";
import { tmpWok as tmpWoks } from "../mockData/overview/tmpWok";
import { wok as woks } from "../mockData/overview/wok";
import { task as tasks } from "../mockData/overview/task";
import { stringify } from "querystring";
import { Loading } from "../components/ui/Loading";

/** 作業總覽 */
export const Overview = () => {
  const { data: InitData, dispatch: InitDispatch } = useContext(InitContext);
  const { dptTyp: dptTypList, dataLoading, isMobile } = InitData;
  const { data: OverviewData, dispatch: OverviewDispatch } =
    useContext(OverviewContext);
  const {
    tmp,
    tmpWok,
    workDate,
    status,
    area,
    work,
    openCount,
    tab,
    tmpList,
    reload,
    reloadTask,
    tmpWokList,
    wokList,
  } = OverviewData;

  const { overview } = staticText;
  const { state, pathname } = useLocation<locationState>();
  const tabWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // TODO: fetch data
    OverviewDispatch({
      type: "setState",
      payload: {
        status: "showControl",
        workDate: moment().startOf("day"),
        tmp: 0,
        tmpWokList: [],
        tmpWok: 0,
        tab: null,
        pubDates: []
      },
    });
  }, [pathname]);

  useEffect(() => {
    // clear search conditions
    OverviewDispatch({
      type: "setState",
      payload: {
        tmp: 0,
        tmpList: [],
        tmpWokList: [],
        status: "showControl",
        openCount: false,
      },
    });

    if (workDate && state?.dptId) {
      let url = new URL(
        `${
          process.env.NODE_ENV === "development"
            ? "https://cors-anywhere.herokuapp.com/"
            : ""
        }${process.env.REACT_APP_URL}viewtmpinfo`
      );
      let params = {
        AgDate: workDate.format("YYYY-MM-DD"),
        DpIfId: state.dptId,
      };
      url.search = new URLSearchParams(params).toString();

      fetch(url.toString())
        .then((res) => res.json())
        .then((tmps) => {
          OverviewDispatch({
            type: "setState",
            payload: {
              tmpList: tmps.map((tmp) => {
                return {
                  id: tmp.TpIfId,
                  name: tmp.TpIfName,
                };
              }),
            },
          });
        });

      let dateMarkUrl = new URL(
        `${
          process.env.NODE_ENV === "development"
            ? "https://cors-anywhere.herokuapp.com/"
            : ""
        }${process.env.REACT_APP_URL}viewdate`
      );
      let dateMarkParams = {
        DpIfId: state.dptId,
        Today: workDate.format("YYYY-MM-DD"),
      };

      dateMarkUrl.search = new URLSearchParams(dateMarkParams).toString();

      fetch(dateMarkUrl.toString())
        .then((res) => res.json())
        .then((res) => 
          OverviewDispatch({type: "setState", payload: {
            pubDates: res
          }})
        );
    }
  }, [workDate, state]);

  useEffect(() => {
    if (
      tmp &&
      tmpList.find((temp) => temp.id === tmp) &&
      // dptTypList
      //   .find((ele) =>
      //     ele.dpt.find(
      //       (ele) => ele.id === parseInt(state.dptId)
      //     )
      //   )
      //   ?.dpt.find((ele) => ele.id === parseInt(state.dptId))
      //   ?.dsply === "NoGp" &&
      status === "showControl"
    ) {
      InitDispatch({
        type: "setState",
        payload: { dataLoading: { value: true, type: "search" } },
      });
      OverviewDispatch({
        type: "setState",
        payload: { tmpWokList: [], openCount: false },
      });
      // TODO: fetch tmpWok
      console.log("📥fetch tmpWok");
      console.table({
        AgDate: workDate.format("YYYY-MM-DD"),
        TpIfId: tmp.toString(),
      });

      let url = new URL(
        `${
          process.env.NODE_ENV === "development"
            ? "https://cors-anywhere.herokuapp.com/"
            : ""
        }${process.env.REACT_APP_URL}viewuntsk`
      );

      url.search = new URLSearchParams({
        AgDate: workDate.format("YYYY-MM-DD"),
        TpIfId: tmp.toString(),
      }).toString();

      fetch(url.toString())
        .then((res) => res.json())
        .then((res) => {
          OverviewDispatch({
            type: "setState",
            payload: {
              status: "showCount",
              tmpWokList: res.map((tmpWork) => {
                return {
                  id: tmpWork.TpWkId,
                  name: tmpWork.TwkName,
                  sCol: tmpWork.Color,
                  num: tmpWork.Count,
                  expMin: tmpWork.Time / (1000 * 60),
                  cloDT: tmpWork.EDT,
                  tip: tmpWork.Html,
                  dsply: tmpWork.Dsply,
                };
              }),
              openCount: true,
              reload: true,
            },
          });
          InitDispatch({
            type: "setState",
            payload: { dataLoading: { value: false } },
          });
        });
    }
  }, [tmp, status]);

  useEffect(() => {
    if (tmpWok && workDate && reload && !dataLoading.value) {
      InitDispatch({
        type: "setState",
        payload: { dataLoading: { value: true, type: "search" } },
      });
      OverviewDispatch({ type: "setState", payload: { status: "showWork" } });
      // TODO: fetch wokList
      console.log("📥fetch wkls");
      console.table({
        sWokDS: workDate.format("YYYY-MM-DD"),
        iTmpWokId: tmpWok,
      });

      let url = new URL(
        `${
          process.env.NODE_ENV === "development"
            ? "https://cors-anywhere.herokuapp.com/"
            : ""
        }${process.env.REACT_APP_URL}viewwork`
      );
      url.search = new URLSearchParams({
        AgDate: moment(workDate).format("YYYY-MM-DD"),
        TpWkId: tmpWok.toString(),
        VwDsply: tab,
        WkDsply: tmpWokList.find((tmpwok) => tmpwok.id === tmpWok).dsply,
      }).toString();

      fetch(url.toString())
        .then((res) => res.json())
        .then((res) => {
          OverviewDispatch({
            type: "setState",
            payload: {
              wokList: res.map((work) => {
                return {
                  id: work.AgWkId,
                  name: work.TpWkName,
                  STS: work.SDT,
                  ETS: work.EDT,
                  active: work.Active,
                  staf: work.user.map((user) => {
                    return {
                      id: user.id,
                      name: user.Name,
                      pic: user.photo,
                    };
                  }),
                  stoAre: work.Area.map((area) => {
                    return {
                      id: area.where,
                      name: area.Name,
                      alt: area.Name,
                      taskFnshNum: area.compl,
                      taskAllNum: area.all,
                      updDS: area.update,
                      sysAct: area.SysAct,
                    };
                  }),
                };
              }),
              reload: false,
            },
          });
        })
        .finally(() =>
          InitDispatch({
            type: "setState",
            payload: { dataLoading: { value: false } },
          })
        );
    }
  }, [tmpWok, reload, dataLoading.value]);

  useEffect(() => {
    if (area.id && work.id && reloadTask) {
      let url = new URL(
        `${
          process.env.NODE_ENV === "development"
            ? "https://cors-anywhere.herokuapp.com/"
            : ""
        }${process.env.REACT_APP_URL}viewtask`
      );
      let params = {
        AgWkId: work.id.toString(),
        where: area.id.toString(),
        WkDsply: tmpWokList.find((tmpwok) => tmpwok.id === tmpWok).dsply,
        VwDsply: tab,
        Active: wokList.find((wok) => wok.id === work.id).active,
      };
      url.search = new URLSearchParams(params).toString();

      fetch(url.toString())
        .then((res) => res.json())
        .then((res) => {
          console.log(res);

          OverviewDispatch({
            type: "setState",
            payload: {
              taskLists: [
                res.map((task) => {
                  return {
                    id: task.AgTkId,
                    name: task.todo,
                    DTS: task.DTS,
                    stat: task.ChkStus,
                    staf: {
                      id: task.CpEmpId,
                      name: task.username,
                      pic: task.profile_photo_path,
                    },
                    GsId: task.GsId,
                  };
                }),
              ],
              reloadTask: false,
              status: "showTask",
            },
          });
          InitDispatch({
            type: "setState",
            payload: {
              dataLoading: { value: false },
              spinning: { value: false },
            },
          });
        });
    }
  }, [area, work, reloadTask]);

  useEffect(() => {
    if (tabWrapper.current && isMobile) {
      window.scrollTo({
        top: tabWrapper.current.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [tmpWok, tab, tabWrapper.current]);

  return (
    <div
      className={`overview-wrapper wrapper ${
        status === "showTask" ? "task-hide" : ""
      }`}
    >
      <Title
        text={
          dptTypList
            .find((ele) =>
              ele.dpt.find((ele) => ele.id === parseInt(state?.dptId))
            )
            ?.dpt.find((ele) => ele.id === parseInt(state?.dptId))
            ?.name as string
        }
      />
      <Control />
      <div className="separator"></div>
      {status === "showControl" && !dataLoading.value && (
        <div className="hint-box tmp">
          <SelectTmp />
          <p>{overview.select.tmp}</p>
        </div>
      )}
      {status === "showControl" && dataLoading.value && <Loading />}
      {status !== "showControl" && status !== "showTask" && <Count />}
      {status === "showCount" && openCount && (
        <div className="hint-box wok">
          <SelectWok />
          <p>{overview.select.wok}</p>
        </div>
      )}
      {status === "showWork" && <WokList ref={tabWrapper} />}
      {status === "showTask" && <TaskList />}
    </div>
  );
};

import { InfoCircleFilled } from "@ant-design/icons";
import { Avatar, Tooltip } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Woks } from "../../utils/types";
import { AreaTable } from "./AreaTable";
import { AreaCard } from "./AreaCard";
import { staticText } from "../../utils/staticText";
import { InitContext } from "../../contexts/init";
import { OverviewContext } from "../../contexts/overview";

export const Wok = ({
  id,
  name,
  STS,
  ETS,
  // expMin,
  // cloDT,
  staf,
  stoAre,
  // tip,
  active,
}: Woks) => {
  const { data: InitData, dispatch: InitDispatch } = useContext(InitContext);
  const { popup } = InitData;
  const { data: OverviewData } = useContext(OverviewContext);
  const { tmpWok, tmpWokList, selectedTip } = OverviewData;

  const { overview } = staticText;

  const [time, setTime] = useState("");

  useEffect(() => {
    const countdown = () => {
      if (
        moment(ETS).diff(moment()) <
          tmpWokList.find((ele) => ele.id === tmpWok).expMin * 60 * 1000 &&
        moment(ETS).diff(moment()) > 0
      ) {
        let diff = moment(ETS).diff(moment());
        let days = Math.floor(diff / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        setTime(
          `${overview.wok.countdown.last}${
            days ? `${days}${overview.wok.countdown.day}` : ""
          }${hours ? `${hours}${overview.wok.countdown.hour}` : ""}${
            minutes ? `${minutes}${overview.wok.countdown.minute}` : ""
          }`
        );
      } else if (moment(ETS).diff(moment()) < 0) {
        let diff = moment().diff(moment(ETS));
        let days = Math.floor(diff / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        setTime(
          `${overview.wok.countdown.over}${
            days ? `${days}${overview.wok.countdown.day}` : ""
          }${hours ? `${hours}${overview.wok.countdown.hour}` : ""}${
            minutes ? `${minutes}${overview.wok.countdown.minute}` : ""
          }`
        );
      }
    };
    countdown();
    const interval = setInterval(() => {
      countdown();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="wok-item" key={id}>
      <div className="woklist-title-box">
        <h3 className={`overview-title ${ active === "D" ? "area-del" : ""}`}>
          {name}
        </h3>
        {selectedTip && (
          <InfoCircleFilled
            className="tips-icon"
            onClick={() => {
              console.log(123);
              
              window.open(selectedTip, "_blank");
            }}
          />
        )}
        <Avatar.Group
          className="avatar-group"
          maxCount={5}
          size="default"
          maxStyle={{ color: "#ffffff", backgroundColor: "#5e94fd" }}
        >
          {staf.map((ele) => {
            return (
              <Tooltip title={ele.name} placement="top" key={ele.id}>
                <Avatar src={ele.pic} />
              </Tooltip>
            );
          })}
        </Avatar.Group>
      </div>
      <p
        className={`work-time ${
          moment(ETS).diff(moment()) <
            tmpWokList.find((ele) => ele.id === tmpWok)?.expMin * 60 * 1000 &&
          "countdown"
        }`}
      >
        {moment(STS).format("HH:mm")} ~ {moment(ETS).format("HH:mm")}{" "}
        {moment(ETS).diff(moment()) <
          tmpWokList.find((ele) => ele.id === tmpWok)?.expMin * 60 * 1000 &&
          time}
      </p>
      <table className="store-table">
        <thead>
          <tr>
            <th>{overview.wok.form.title1}</th>
            <th>{overview.wok.form.title2}</th>
            <th>{overview.wok.form.title3}</th>
          </tr>
        </thead>
        <tbody>
          {stoAre.map((ele) => {
            return (
              <AreaTable {...ele} wok={{ id: id, name: name }} key={ele.id} />
            );
          })}
        </tbody>
      </table>
      <div className="store-card-list">
        {stoAre.map((ele) => {
          return (
            <AreaCard {...ele} wok={{ id: id, name: name }} key={ele.id} />
          );
        })}
      </div>
    </div>
  );
};

import React, { useContext } from "react";
import { useLocation } from "react-router-dom";

import { Title } from "../components/ui/Title";
import { Control } from "../components/notice/Control";
import { locationState } from "../utils/types";

export const Notice = () => {

    const location = useLocation<locationState>();
    
  return (
    <div className={`notice-wrapper wrapper`}>
      <Title text={location.state?.title}></Title>
      <Control />
    </div>
  );
};

import {
  EnterOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  InfoCircleFilled,
  LeftOutlined,
} from "@ant-design/icons";
import { Checkbox } from "antd";
import { useContext, useState } from "react";
import { TaskTable } from "./TaskTable";
import { TaskCard } from "./TaskCard";
import { staticText } from "../../utils/staticText";
import { useAlert } from "react-alert";
import { InitContext } from "../../contexts/init";
import { OverviewContext } from "../../contexts/overview";
import moment from "moment";
import { Loading } from "../ui/Loading";

export const TaskList = () => {
  const { data: InitData, dispatch: InitDispatch } = useContext(InitContext);
  const { popup, account, taskVisible, dataLoading } = InitData;
  const { data, dispatch } = useContext(OverviewContext);
  const { wokList, taskLists, area, work, selectedTip, tmpWokList, tmpWok } =
    data;
  const [currentWok] = useState(wokList.find((wok) => wok.id === work.id));
  const [currentAre] = useState(
    currentWok?.stoAre.find((sto) => sto.id === area.id)
  );

  const { overview, loading } = staticText;
  const alert = useAlert();

  return (
    <>
      {taskLists.map((taskList, i) => (
        <div key={i}>
          <div className="woklist-title-box">
            <h3 className="overview-title task-title">
              <span
                className={`wok${currentWok.active === "D" ? " area-del" : ""}`}
                onClick={() => {
                  dispatch({
                    type: "setState",
                    payload: {
                      status: "showControl",
                      area: { id: 0, name: "" },
                      wokList: [],
                      taskLists: [],
                    },
                  });
                }}
              >
                {work.name}
              </span>
              {selectedTip && (
                <>
                  <InfoCircleFilled
                    className="tips-icon mobile"
                    onClick={() => {
                      window.open(selectedTip, "_blank");
                    }}
                  />
                </>
              )}
              <span
                className={`wok mobile${
                  currentWok.active === "D" ? " area-del" : ""
                }`}
              >
                {work.name}
                {selectedTip && (
                  <>
                    <InfoCircleFilled
                      className="tips-icon"
                      onClick={() => {
                        window.open(selectedTip, "_blank");
                      }}
                    />
                  </>
                )}
              </span>{" "}
              {area.name && (
                <>
                  <span className="slash">/</span>{" "}
                  <span
                    className={`area${
                      currentAre.sysAct === "Y" ? " area-del" : ""
                    }`}
                  >
                    {area.name}
                  </span>
                </>
              )}
            </h3>
            <div className="function-box">
              <div
                className="prev"
                onClick={() => {
                  dispatch({
                    type: "setState",
                    payload: {
                      status: "showControl",
                      area: { id: 0, name: "" },
                      wokList: [],
                      taskLists: [],
                    },
                  });
                }}
              >
                <EnterOutlined className="prev-content" />
                <span className="prev-content">{overview.task.prev}</span>
                <LeftOutlined className="arrow" />
              </div>
              <div
                className="set-show"
                onClick={() => {
                  InitDispatch({
                    type: "setState",
                    payload: { taskVisible: !taskVisible },
                  });
                  localStorage.setItem("showFnshTask", `${!taskVisible}`);
                }}
              >
                {taskVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                <span className="show-text">
                  {taskVisible ? overview.task.hide : overview.task.show}
                  {overview.task.text}
                </span>
              </div>
            </div>
          </div>
          {dataLoading.value ? (
            <Loading />
          ) : (
            <>
              <table className="task-table">
                <thead>
                  <tr>
                    <th className="task-title">
                      <Checkbox
                        checked={
                          taskList.length ===
                          taskList.filter((ele) => ele.stat === "Y").length
                        }
                        // disabled={
                        //   wokList
                        //     .find((ele) => ele.id == work.id)
                        //     ?.staf.findIndex((ele) => ele.id == account.id) ===
                        //   -1
                        //     ? true
                        //     : false
                        // }
                        indeterminate={
                          !(
                            taskList.length ===
                              taskList.filter((ele) => ele.stat === "O")
                                .length ||
                            taskList.length ===
                              taskList.filter((ele) => ele.stat === "Y").length
                          )
                        }
                        onChange={(e) => {
                          console.log(e);
                          // if (
                          //   wokList
                          //     .find((ele) => ele.id == work.id)
                          //     ?.staf.findIndex((ele) => ele.id == account.id) >
                          //   -1
                          // ) {
                          InitDispatch({
                            type: "setState",
                            payload: {
                              spinning: { value: true, tip: loading.handle },
                            },
                          });
                          // TODO: fetch
                          console.log("📥fetch chk");
                          console.table({
                            data: taskList.map((task) => {
                              return {
                                AgWkId: work.id,
                                GsId: task.GsId,
                                where: area.id,
                                CpEmpId: account.id,
                                AgTkId: task.id,
                                ChkStus: e.target.checked ? "Y" : "O",
                                DTS: e.target.checked
                                  ? moment().format("YYYY-MM-DD HH:mm:ss")
                                  : task.DTS,
                              };
                            }),
                          });
                          // TODO: if success,set task
                          fetch(
                            `${
                              process.env.NODE_ENV === "development"
                                ? "https://cors-anywhere.herokuapp.com/"
                                : ""
                            }${process.env.REACT_APP_URL}coptask`,
                            {
                              method: "POST",
                              body: JSON.stringify(
                                taskList.map((task) => {
                                  return {
                                    AgWkId: work.id,
                                    GsId: task.GsId,
                                    where: area.id,
                                    CpEmpId: account.id,
                                    AgTkId: task.id,
                                    ChkStus: e.target.checked ? "Y" : "O",
                                    DTS: e.target.checked
                                      ? moment().format("YYYY-MM-DD HH:mm:ss")
                                      : task.DTS,
                                  };
                                })
                              ),
                            }
                          )
                            .then((res) => res.json())
                            .then((res) => {
                              console.log(res);

                              if (res) {
                                alert.success(
                                  e.target.checked
                                    ? overview.alert.success.replace(
                                        "%任務數%",
                                        taskList
                                          .filter((item) => item.stat === "O")
                                          .length.toString()
                                      )
                                    : overview.alert.cancel.replace(
                                        "%任務數%",
                                        taskList
                                          .filter((item) => item.stat === "Y")
                                          .length.toString()
                                      )
                                );

                                let newTmpWokList = tmpWokList.slice();
                                if (currentWok.active === "Y") {
                                  e.target.checked
                                    ? (newTmpWokList.find(
                                        (wok) => wok.id === tmpWok
                                      ).num -= taskList.filter(
                                        (task) => task.stat === "O"
                                      ).length)
                                    : (newTmpWokList.find(
                                        (wok) => wok.id === tmpWok
                                      ).num += taskList.filter(
                                        (task) => task.stat === "Y"
                                      ).length);
                                }

                                dispatch({
                                  type: "setState",
                                  payload: {
                                    tmpWokList: newTmpWokList,
                                    reloadTask: true,
                                  },
                                });
                              }
                            });
                          // } else {
                          //   // TODO: setPopup
                          // }
                        }}
                      ></Checkbox>
                      {overview.task.form.title1}
                    </th>
                    <th>{overview.task.form.title2}</th>
                    <th>{overview.task.form.title3}</th>
                  </tr>
                </thead>
                <tbody className={`${taskVisible ? "show-fnsh" : ""}`}>
                  {taskList
                    .slice()
                    .sort((t1, t2) => (t2.stat === "Y" ? -1 : 1))
                    .map((ele, i) => {
                      return (
                        <TaskTable
                          key={i}
                          {...ele}
                          active={currentWok.active}
                        />
                      );
                    })}
                </tbody>
              </table>
              <div
                className={`task-card-list ${taskVisible ? "show-fnsh" : ""}`}
              >
                {taskList
                  .slice()
                  .sort((t1, t2) => (t2.stat === "Y" ? -1 : 1))
                  .map((ele, i) => {
                    return (
                      <TaskCard key={i} {...ele} active={currentWok.active} />
                    );
                  })}
              </div>
            </>
          )}
          <div className="select-all-box">
            <Checkbox
              checked={
                taskList.length ===
                taskList.filter((ele) => ele.stat === "Y").length
              }
              // disabled={
              //   wokList
              //     .find((ele) => ele.id == work.id)
              //     ?.staf.findIndex((ele) => ele.id == account.id) === -1
              //     ? true
              //     : false
              // }
              onChange={(e) => {
                // if (
                //   wokList
                //     .find((ele) => ele.id == work.id)
                //     ?.staf.findIndex((ele) => ele.id == account.id) !== -1
                // ) {
                InitDispatch({
                  type: "setState",
                  payload: { spinning: { value: true, tip: loading.handle } },
                });
                // TODO: fetch
                console.log("📥fetch chk");
                fetch(
                  `${
                    process.env.NODE_ENV === "development"
                      ? "https://cors-anywhere.herokuapp.com/"
                      : ""
                  }${process.env.REACT_APP_URL}coptask`,
                  {
                    method: "POST",
                    body: JSON.stringify(
                      taskList.map((task) => {
                        return {
                          AgWkId: work.id,
                          GsId: task.GsId,
                          where: area.id,
                          CpEmpId: account.id,
                          AgTkId: task.id,
                          ChkStus: e.target.checked ? "Y" : "O",
                          DTS: e.target.checked
                            ? moment().format("YYYY-MM-DD HH:mm:ss")
                            : task.DTS,
                        };
                      })
                    ),
                  }
                )
                  .then((res) => res.json())
                  .then((res) => {
                    console.log(res);

                    if (res) {
                      alert.success(
                        e.target.checked
                          ? overview.alert.success.replace(
                              "%任務數%",
                              taskList
                                .filter((item) => item.stat === "O")
                                .length.toString()
                            )
                          : overview.alert.cancel.replace(
                              "%任務數%",
                              taskList
                                .filter((item) => item.stat === "Y")
                                .length.toString()
                            )
                      );

                      let newTmpWokList = tmpWokList.slice();
                      if (
                        wokList.find((wok) => wok.id === work.id).active === "Y"
                      ) {
                        e.target.checked
                          ? (newTmpWokList.find(
                              (wok) => wok.id === tmpWok
                            ).num -= taskList.filter(
                              (task) => task.stat === "O"
                            ).length)
                          : (newTmpWokList.find(
                              (wok) => wok.id === tmpWok
                            ).num += taskList.filter(
                              (task) => task.stat === "Y"
                            ).length);
                      }

                      dispatch({
                        type: "setState",
                        payload: {
                          tmpWokList: newTmpWokList,
                          reloadTask: true,
                        },
                      });
                    }
                  });
                // } else {
                //   // TODO: setPopup
                // }
              }}
            ></Checkbox>
          </div>
        </div>
      ))}
    </>
  );
};

import { CloseOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { InitContext } from "../../contexts/init";
import { Button } from "./Button";

export const Popup = () => {
  const { data: InitData, dispatch: InitDispatch } = useContext(InitContext);
  const { popup } = InitData;
  const { title, content, btnMain, btnSub, onClickMain, onClickSub } = popup;

  const clickMain = () => {
    if (onClickMain) {
      onClickMain();
    }
  };
  const clickSub = () => {
    if (onClickSub) {
      onClickSub();
    }
  };

  return (
    <>
      <div className="popup-block" onClick={() => {
        InitDispatch({
          type: "setState",
          payload: {
            popup: {
              ...popup,
              open: false,
            },
          },
        });
      }}></div>
      <div className="popup-box">
        <h3 className="popup-title">{title}</h3>
        <div className="popup-sepa"></div>
        <div
          className="popup-content"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></div>
        <CloseOutlined
          className="popup-close"
          onClick={() => {
            InitDispatch({
              type: "setState",
              payload: {
                popup: {
                  ...popup,
                  open: false,
                },
              },
            });
          }}
        />
        {(btnSub || btnMain) && (
          <div className={`popup-btns ${btnSub && btnMain ? "double" : ""}`}>
            {btnSub && (
              <Button
                type="sub"
                text={btnSub}
                onClick={clickSub}
                multi={btnMain && btnSub ? true : false}
              />
            )}
            {btnMain && (
              <Button
                type="main"
                text={btnMain}
                onClick={clickMain}
                multi={btnMain && btnSub ? true : false}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

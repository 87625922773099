import {
  BankOutlined,
  EditOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Form, Input } from "antd";
import { useContext, useEffect, useState } from "react";
import { ReactComponent as Logo } from "../images/layout/logo.svg";
import { staticText } from "../utils/staticText";
// import reloadImg from "../images/login/reload.png";
// import { Link } from "react-router-dom";
import { Popup } from "../components/ui/Popup";
import { Link } from "react-router-dom";
import { InitContext } from "../contexts/init";

/** 綁定頁 */
export const Bind = () => {
  const { data: InitData, dispatch: InitDispatch } = useContext(InitContext);
  const { popup } = InitData;

  const { login } = staticText;
  const [reload, setReload] = useState(true);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [verifyImg, setVerifyImg] = useState({ key: "", img: "" });

  const onFinish = (values: any) => {
    // TODO: fetch API
    console.log("📥fetch bindline");
    console.table({
      HId: values.hotel,
      email: values.email,
      password: values.password,
      key: verifyImg.key,
      captcha: values.verify,
    });

    let data = {
      HId: values.hotel,
      email: values.email,
      password: values.password,
      key: verifyImg.key,
      captcha: values.verify,
    };

    fetch(
      `${
        process.env.NODE_ENV === "development"
          ? "https://cors-anywhere.herokuapp.com/"
          : ""
      }${process.env.REACT_APP_URL}bindline`,
      {
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        method: "POST",
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res.code !== 201) {
          setFail(true);
        } else {
          InitDispatch({
            type: "setState",
            payload: {
              popup: {
                ...popup,
                open: true,
                title: login.bind.popup.title,
                content: login.bind.popup.content,
              },
            },
          });
        }
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    document.title = "綁定 | Tickup 任務指派系統";
  }, []);
  useEffect(() => {
    if (reload) {
      fetch(
        `${
          process.env.NODE_ENV === "development"
            ? "https://cors-anywhere.herokuapp.com/"
            : ""
        }${process.env.REACT_APP_URL}captkey`
      )
        .then((res) => {
          if (!res.ok) {
            setReload(false);
            throw new Error(res.statusText);
          } else {
            return res.json();
          }
        })
        .then((json) => {
          setVerifyImg({ key: json.url.key, img: json.url.img });
          setReload(false);
        })
        .catch(() => {});
    }
  }, [reload]);

  return (
    <div className="bind-wrapper">
      <Form
        className="login-block"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Logo className="logo" />
        <h2 className="title">{login.bind.title}</h2>
        <h4 className="subtitle">{login.bind.subtitle}</h4>
        <div className="input-block">
          <Form.Item
            name="hotel"
            rules={[
              {
                required: true,
                message: login.message.replace("%項目%", login.hotel),
              },
            ]}
          >
            <Input
              size="large"
              prefix={<BankOutlined />}
              placeholder={login.hotel}
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: login.message.replace("%項目%", login.email),
              },
              {
                required: false,
                type: "email",
                message: login.emailValid,
              },
            ]}
          >
            <Input
              size="large"
              prefix={<UserOutlined />}
              placeholder={login.email}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: login.message.replace("%項目%", login.password),
              },
            ]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder={login.password}
            />
          </Form.Item>
        </div>
        <div className="verify-block">
          <Form.Item
            name="verify"
            rules={[
              {
                required: true,
                message: login.message.replace("%項目%", login.verify),
              },
              {
                min: 4,
                max: 4,
                message: login.verifyValid,
              },
            ]}
          >
            <Input
              size="large"
              prefix={<EditOutlined />}
              placeholder={login.verify}
              minLength={4}
              maxLength={4}
            />
          </Form.Item>
          <div className="verify-img-block">
            {verifyImg.img && (
              <img
                src={verifyImg.img}
                alt={login.verify}
                className="verify-img"
                onClick={() => setReload(true)}
              />
            )}
            {/* <img
              src={reloadImg}
              className="verify-reload"
              onClick={() => setReload(true)}
            /> */}
          </div>
        </div>
        {/* <div className="btn-wrapper"> */}
        <button className="bind-btn" type="submit">
          {login.btn.bind}
        </button>
        {fail && <p className="login-fail">{login.fail}</p>}
        <Link to="/login">
          {/* <button className="back-btn">{login.btn.prev}</button> */}
          <p className="to-login">{login.bind.login}</p>
        </Link>
        {/* </div> */}
      </Form>
      {popup.open && <Popup />}
    </div>
  );
};

const dpIfIdToView: (dsply: number) => string = (dsply) => {
    switch (dsply) {
        case 1:
            return '/rooms/houskeeping';
        case 2:
            return '/reception/frontdesk';
        case 3:
            return '/temporary/tasks';
        case 4:
            return '/cleaning/restroom';
        case 5:
            return '/cleaning/dressing-room';
        case 6:
            return '/cleaning/outdoor';
        case 7:
            return '/cleaning/workstations';
        default:
            break;
    }
}

export default dpIfIdToView;
import { createContext, useReducer } from "react";

export type Staf = {
  id: number;
  name: string;
  pic: string;
};

export type TmpTask = {
  id: number;
  name: string;
  DTS: string;
  stat: "Y" | "O";
  GsId?: number;
  user?: Staf;
}

export type TmpTaskList = {
  id?: number;
  WkName?: string;
  tip?: string;
  expMin?: number;
  staf?: Staf[];
  SDT?: string;
  EDT?: string;
  active: "Y" | "D",
  data: TmpTask[];
};

export type TmpWoks = {
  id: number;
  name: string;
  num: number;
  expMin: number;
  cloDT: string;
  sCol: string;
  tip: string;
  dsply: string;
}[];

export type Woks = {
  id: number;
  name: string;
  STS: string;
  ETS: string;
  staf: { id: number; name: string; pic: string }[];
  stoAre: {
    id: number;
    name: string;
    alt: string;
    taskFnshNum: number;
    taskAllNum: number;
    updDS: string;
    sysAct: "Y" | "D";
  }[];
  sysAct: "Y" | "D";
};

export interface TmpWorkData {
  tmp?: number;
  tmpWok?: number;
  tmpList?: {
    id: number;
    name: string;
  }[];
  tmpWokList?: TmpWoks;
  taskLists?: TmpTaskList[];
  workDate?: moment.Moment | undefined;
  status?: "showControl" | "showCount" | "showTask";
  hideFinish?: boolean;
  work?: {
    id: number;
    name: string;
  };
  reload?: boolean;
  openCount?: boolean;
  tab?: 'fnh' | 'cxl' | 'all';
  pubDates?: string[];
}

const data: TmpWorkData = {
  tmp: 0,
  tmpWok: 0,
  tmpList: [],
  tmpWokList: [],
  taskLists: [],
  workDate: undefined,
  status: "showControl",
  hideFinish: false,
  work: { id: 0, name: "" },
  reload: true,
  openCount: false,
  tab: null,
  pubDates: [],
};

export interface ActionType {
  type: "setState";
  payload: TmpWorkData;
}

function reducer(state: TmpWorkData, action: ActionType) {
  switch (action.type) {
    case "setState":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export interface TempWorkContextType {
  data: TmpWorkData;
  dispatch: React.Dispatch<ActionType>;
}

export const TempWorkContext = createContext<TempWorkContextType>({
  data,
  dispatch: () => {},
});

export const TempWorkContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, data);
  return (
    <TempWorkContext.Provider value={{ data: state, dispatch }}>
      {children}
    </TempWorkContext.Provider>
  );
};

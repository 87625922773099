import { DatePicker, Select } from "antd";
import moment from "moment";
import locale from "antd/es/date-picker/locale/zh_TW";
import React, { useContext, useState } from "react";
import {
  PickerBaseProps,
  PickerDateProps,
  PickerTimeProps,
} from "antd/lib/date-picker/generatePicker";
import { staticText } from "../../utils/staticText";
import { Button } from "../ui/Button";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { MyworkContext } from "../../contexts/mywork";
import { InitContext } from "../../contexts/init";

export const Control = () => {
  const { data: MyworkData, dispatch: MyworkDispatch } =
    useContext(MyworkContext);
  const { data: InitData, dispatch: InitDispatch } = useContext(InitContext);
  const { workDate, staff, selectedStaff } = MyworkData;
  const { datepickerOpen, permission, account } = InitData;
  const { mywork, assignText } = staticText;
  const [testInput, setTestInput] = useState<React.Component<
    | PickerBaseProps<moment.Moment>
    | PickerDateProps<moment.Moment>
    | PickerTimeProps<moment.Moment>,
    any
  > | null>();
  const { Option } = Select;

  const handleDateChange = (date: moment.Moment | null) => {
    if (date) {
      MyworkDispatch({
        type: "setState",
        payload: {
          workDate: moment(date).startOf("day"),
        },
      });

      // @ts-ignore
      if (testInput) testInput.blur(); // ? TYPE?
    }
  };

  return (
    <div className="control-box mywork">
      <div className="select-wrapper">
        <h5>{mywork.control.title}</h5>
        <DatePicker
          inputReadOnly
          defaultPickerValue={moment()}
          value={workDate}
          locale={locale}
          open={datepickerOpen}
          onOpenChange={(value) =>
            InitDispatch({
              type: "setState",
              payload: { datepickerOpen: value },
            })
          }
          onChange={handleDateChange}
          getPopupContainer={() =>
            document.querySelector(".date-picker-wrapper")
          }
          ref={(input) => setTestInput(input)}
          className="control-datePicker"
          placeholder={mywork.control.placeholder}
        />
        {permission.id === 1 && (
          <>
            <h5>{assignText.assignArea.staff.title}</h5>

            <Select
              onFocus={(e) => {
                e.target.setAttribute("readOnly", "true");
              }}
              onChange={(value) => {
                console.log(value);

                MyworkDispatch({
                  type: "setState",
                  payload: {
                    selectedStaff: value,
                    reload: true,
                  },
                });
              }}
              value={selectedStaff ? selectedStaff : account.id}
              allowClear
              placeholder={assignText.control.staff}
              maxTagCount="responsive"
              className="control-people"
            >
              {staff.map((employee) => (
                <Option
                  value={employee.id}
                  label={employee.name}
                  key={employee.id}
                >
                  {employee.name}
                </Option>
              ))}
            </Select>
          </>
        )}
      </div>
      <div className="date-btn-wrapper">
        <Button
          text={mywork.control.prev}
          className="prev"
          type="main"
          onClick={() => {
            MyworkDispatch({
              type: "setState",
              payload: {
                workDate: moment(workDate).subtract(1, "days"),
              },
            });
          }}
        />
        <button
          className="prev-mo"
          onClick={() => {
            MyworkDispatch({
              type: "setState",
              payload: {
                workDate: moment(workDate).subtract(1, "days"),
              },
            });
          }}
        >
          <LeftOutlined />
        </button>

        <Button
          text={mywork.control.next}
          className="next"
          type="main"
          onClick={() => {
            MyworkDispatch({
              type: "setState",
              payload: {
                workDate: moment(workDate).add(1, "days"),
              },
            });
          }}
        />
        <button
          className="next-mo"
          onClick={() => {
            MyworkDispatch({
              type: "setState",
              payload: {
                workDate: moment(workDate).add(1, "days"),
              },
            });
          }}
        >
          <RightOutlined />
        </button>
      </div>
    </div>
  );
};

import React, { useContext, useState } from "react";
import Timeline, {
  DateHeader,
  ReactCalendarGroupRendererProps,
  ReactCalendarItemRendererProps,
  SidebarHeader,
  TimelineHeaders,
  Unit,
} from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import "moment/locale/zh-tw";
import { useAlert } from "react-alert";
import { staticText } from "../../utils/staticText";
import { TimelineItem } from "../../utils/types";
import { InitContext } from "../../contexts/init";
import { AssignContext } from "../../contexts/assign";

export const TimelineArea = () => {
  const { data: InitData, dispatch: InitDispatch } = useContext(InitContext);
  const { popup, isMobile } = InitData;
  const { data: AssignData, dispatch: AssignDispatch } =
    useContext(AssignContext);
  const {
    groups,
    selectGroups,
    items,
    workDate,
    workDateEnd,
    selected,
    vTimeStart,
    vTimeEnd,
    creating,
    creatingGroups,
    creatingTime,
    status,
    assign,
    store,
    selectItem,
    tmp,
    wok,
    dptTyp,
    chose,
  } = AssignData;

  const { assignText } = staticText;

  const alert = useAlert();

  let handleItemMove = (
    itemId: string | number,
    dragTime: number,
    newGroupOrder: number
  ) => {
    const groupsa = selectGroups;
    const group = groupsa[newGroupOrder];
    AssignDispatch({
      type: "setState",
      payload: {
        items: items.map((item) =>
          creating.includes(item.id)
            ? Object.assign({}, item, {
                start_time: dragTime,
                end_time: dragTime + (item.end_time - item.start_time),
                group: item.id === itemId ? group.id : item.group,
              })
            : item
        ),
        creatingTime: {
          start: dragTime,
          end: dragTime + (creatingTime.end - creatingTime.start),
        },
        assign: {
          ...assign,
          time: {
            start: dragTime,
            end: dragTime + (creatingTime.end - creatingTime.start),
          },
        },
      },
    });
  };
  const handleItemResize = (
    itemId: string | number,
    time: number,
    edge: "left" | "right"
  ) => {
    AssignDispatch({
      type: "setState",
      payload: {
        items: items.map((item) =>
          creating.includes(item.id)
            ? Object.assign({}, item, {
                start_time: edge === "left" ? time : item.start_time,
                end_time: edge === "left" ? item.end_time : time,
              })
            : item
        ),
        creatingTime: {
          start: edge === "left" ? time : creatingTime.start,
          end: edge === "right" ? time : creatingTime.end,
        },
        assign: {
          ...assign,
          time: {
            start: edge === "left" ? time : assign.time.start,
            end: edge === "right" ? time : assign.time.end,
          },
        },
      },
    });
  };
  const handleItemClick = (itemId: number) => {
    console.log("click");

    if (creating.includes(itemId)) {
      let index = items.findIndex((ele) => ele.id === itemId);
      let tempCreating = [...creating];
      let tempItems = [...items];
      let tempCreatingGroups = [...creatingGroups];
      tempCreating.splice(creating.indexOf(itemId), 1);
      tempItems.splice(index, 1);
      tempCreatingGroups.splice(creatingGroups.indexOf(items[index].group), 1);
      console.log({
        creating: tempCreating,
        items: tempItems,
        creatingGroups: tempCreatingGroups,
      });

      AssignDispatch({
        type: "setState",
        payload: {
          creating: tempCreating,
          items: tempItems,
          creatingGroups: tempCreatingGroups,
        },
      });

      if (!tempCreating.length) {
        AssignDispatch({ type: "clearAssign", payload: {} });
      } else {
        AssignDispatch({
          type: "setState",
          payload: {
            assign: { ...assign, staff: tempCreatingGroups },
          },
        });
      }
    } else {
      
    }
  };
  const handleItemSelect = (itemId: number) => {
    console.log("select");
    AssignDispatch({ type: "setState", payload: { chose: true, canCallApi: false } });
    const todo = () => {
      // setTagColor((items as Item[]).find((ele) => ele.id === itemId)?.color);
      let selectItemF = items.find((ele) => ele.id === itemId);

      if (itemId > 100000) {
        AssignDispatch({
          type: "setState",
          payload: {
            status: "new",
            selected: [itemId],
            items: items.map((item) =>
              item.id === itemId
                ? Object.assign({}, item, {
                    className: "selected",
                    selected: true,
                    itemProps: {
                      style: {
                        background: item.color,
                        boxShadow: "6px 6px 7px rgba(0,0,0,0.16)",
                      },
                    },
                  })
                : Object.assign({}, item, {
                    className: "",
                    selected: false,
                    itemProps: {
                      style: {
                        background: item.color,
                      },
                    },
                  })
            ),
            assign: {
              ...assign,
              id: selectItemF?.wokId,
              staff: items
                .filter((ele) => ele.id > 100000)
                .map((ele) => ele.group),
              time: {
                start: selectItemF?.start_time,
                end: selectItemF?.end_time,
              },
              dptTyp: selectItemF?.dptTyp ? selectItemF?.dptTyp.id : null,
              dpt: selectItemF?.dpt ? selectItemF?.dpt.id : null,
              tmp: selectItemF?.tmp ? selectItemF?.tmp.id : null,
              work: selectItemF?.wok ? selectItemF?.wok.id : null,
              store: selectItemF?.storeArr
                ? selectItemF?.storeArr.map((ele) => ele.id)
                : [],
            },
          },
        });
      } else {
        const todo2 = () => {
          AssignDispatch({
            type: "setState",
            payload: {
              tagColor: "#" + items.find((ele) => ele.id === itemId)?.color,
              status: "edit",
              selected: items
                .filter(
                  (ele) =>
                    ele.wokId === items.find((ele) => ele.id === itemId)?.wokId
                )
                .map((ele) => ele.id),
              assign: {
                ...assign,
                id: selectItemF?.wokId,
                time: {
                  start: selectItemF?.start_time,
                  end: selectItemF?.end_time,
                },
                staff: items
                  .filter((ele) => ele.wokId === selectItemF?.wokId)
                  .map((ele) => ele.group),
                dptTyp: selectItemF?.dptTyp.id,
                dpt: selectItemF?.dpt.id,
                tmp: selectItemF?.tmp?.id,
                work: selectItemF?.wok.id,
                store: (selectItemF?.storeArr)
                  .filter((ele) => ele.sysAct === "N")
                  .map((ele) => ele.id),
                date: { start: "", end: "" },
              },
              tmp: selectItemF?.tmp ? [selectItemF.tmp] : [],
              wok: [{ ...selectItemF?.wok, sCol: selectItemF?.color }],
              store: selectItemF?.storeArr.filter((ele) => ele.sysAct === "N"),
              items: items
                .filter((item) => item.id < 100000)
                .map((item) =>
                  item.wokId === selectItemF?.wokId
                    ? Object.assign({}, item, {
                        className: "",
                        selected: true,
                        itemProps: {
                          style: {
                            background: item.color,
                            boxShadow: "6px 6px 7px rgba(0,0,0,0.16)",
                          },
                        },
                        storeArr: item.storeArr.filter(
                          (ele) => ele.sysAct === "N"
                        ),
                      })
                    : Object.assign({}, item, {
                        className: "",
                        selected: false,
                        itemProps: {
                          style: {
                            background: item.color,
                          },
                        },
                      })
                ),
              creating: [],
              creatingGroups: [],
              creatingTime: { start: 0, end: 0 },
              selectItem: selectItemF,
            },
          });
        };
        let set = new Set<string>();
        for (let store of selectItemF.storeArr) {
          if (store.sysAct === "Y") {
            set.add(store.name);
          }
        }
        let arr = Array.from(set);
        if (arr.length) {
          console.log(arr);

          AssignDispatch({
            type: "setState",
            payload: {
              delPopup: {
                id: itemId,
                status: "check",
                text: arr.map(ele =>`- ${ele} 入住`).join('<br/>'),
                func: todo2,
              },
            },
          });
          return;
        }
        todo2();
      }
    };
    if (
      (status === "new" && itemId < 100000) ||
      (status === "edit" &&
        selected.length &&
        !(
          assign.time.start === selectItem?.start_time &&
          assign.time.end === selectItem?.end_time &&
          assign.staff
            .slice()
            .sort()
            .equals(
              items
                ?.filter((ele) => ele.wokId === selectItem?.wokId)
                .map((ele) => ele.group)
                .sort()
            ) &&
          assign.dptTyp === selectItem?.dptTyp.id &&
          assign.dpt === selectItem?.dpt.id &&
          assign.tmp === selectItem?.tmp?.id &&
          assign.work === selectItem?.wok.id &&
          assign.store
            .slice()
            .sort()
            .equals(selectItem?.storeArr?.map((ele) => ele.id).sort()) &&
          assign.store.length > 0
        ))
    ) {
      InitDispatch({
        type: "setState",
        payload: {
          popup: {
            ...popup,
            open: true,
            title: assignText.popup.unedit.title,
            content: assignText.popup.unedit.content,
            btnMain: assignText.popup.unedit.btnMain,
            btnSub: assignText.popup.unedit.btnSub,
            onClickMain: () => {
              todo();
              InitDispatch({ type: "closePopup", payload: {} });
              AssignDispatch({ type: "setState", payload: { chose: false } });
            },
            onClickSub: () => InitDispatch({ type: "closePopup", payload: {} }),
          },
        },
      });
    } else {
      todo();
    }
  };
  const handleItemDeselect = () => {
    console.log("deselect");

    if (
      assign.time.start === selectItem?.start_time &&
      assign.time.end === selectItem?.end_time &&
      assign.staff
        .slice()
        .sort()
        .equals(
          items
            ?.filter((ele) => ele.wokId === selectItem?.wokId)
            .map((ele) => ele.group)
            .sort()
        ) &&
      assign.dptTyp === selectItem?.dptTyp.id &&
      assign.dpt === selectItem?.dpt.id &&
      assign.tmp === selectItem?.tmp?.id &&
      assign.work === selectItem?.wok.id &&
      assign.store
        .slice()
        .sort()
        .equals(selectItem?.storeArr?.map((ele) => ele.id).sort()) &&
      assign.store.length > 0
    ) {
      AssignDispatch({
        type: "setState",
        payload: {
          selected: [],
          selectItem: undefined,
          items: items.map((item) =>
            Object.assign({}, item, {
              className: "",
              selected: false,
              itemProps: {
                style: {
                  background: item.color,
                },
              },
            })
          ),
        },
      });
      if (creating.length === 0) {
        AssignDispatch({
          type: "setState",
          payload: {
            status: "unselect",
            store: [],
            tmp: [],
            wok: [],
          },
        });
        AssignDispatch({ type: "clearAssign", payload: {} });
      }
    }
  };

  const handleCanvasClick = (groupId: number, time: number) => {
    console.log("canvas");

    if (isMobile && chose && status !=="new") {
      AssignDispatch({ type: "setState", payload: { chose: false } });
      return;
    }

    if (selected.length) {
      if (selectItem?.id < 100000) {
        if (
          assign.time.start === selectItem?.start_time &&
          assign.time.end === selectItem?.end_time &&
          assign.staff
            .slice()
            .sort()
            .equals(
              items
                ?.filter((ele) => ele.wokId === selectItem?.wokId)
                .map((ele) => ele.group)
                .sort()
            ) &&
          assign.dptTyp === selectItem?.dptTyp.id &&
          assign.dpt === selectItem?.dpt.id &&
          assign.tmp === selectItem?.tmp?.id &&
          assign.work === selectItem?.wok.id &&
          assign.store
            .slice()
            .sort()
            .equals(selectItem?.storeArr?.map((ele) => ele.id).sort()) &&
          assign.store.length > 0
        ) {
          AssignDispatch({
            type: "setState",
            payload: {
              selected: [],
            },
          });
        } else {
          InitDispatch({
            type: "setState",
            payload: {
              popup: {
                ...popup,
                open: true,
                title: assignText.popup.unedit.title,
                content: assignText.popup.unedit.content,
                btnMain: assignText.popup.unedit.btnMain,
                btnSub: assignText.popup.unedit.btnSub,
                onClickMain: () => {
                  AssignDispatch({
                    type: "setState",
                    payload: {
                      selected: [],
                      selectItem: undefined,
                      status: "unselect",
                      store: [],
                      items: items.map((item) =>
                        Object.assign({}, item, {
                          className: "",
                          selected: false,
                          itemProps: {
                            style: {
                              background: item.color,
                            },
                          },
                        })
                      ),
                    },
                  });
                  AssignDispatch({ type: "clearAssign", payload: {} });
                  InitDispatch({ type: "closePopup", payload: {} });
                },
                onClickSub: () =>
                  InitDispatch({ type: "closePopup", payload: {} }),
              },
            },
          });
        }
      } else {
        AssignDispatch({
          type: "setState",
          payload: {
            selected: [],
            items: items.map((item) =>
              Object.assign({}, item, {
                className: "",
                selected: false,
                itemProps: {
                  style: {
                    background: item.color,
                  },
                },
              })
            ),
          },
        });
      }
    } else {
      if (groups.find((ele) => ele.id === groupId)) {
        if (creatingTime.start && creatingTime.end && status === "new") {
          if (creatingGroups.includes(groupId)) {
            alert.error(assignText.alert.creating.errorDrag);
            return;
          }
          if (time < creatingTime.start || time > creatingTime.end) {
            alert.error(assignText.alert.creating.errorSame);
            return;
          }
        }
        let nid = 100001;
        let start = 0;
        let end = 0;
        if (creating.length) {
          nid = creating[creating.length - 1] + 1;
        } else {
          start = time;
          end = time + 1000 * 60 * 30;
        }
        AssignDispatch({
          type: "setState",
          payload: {
            items: [
              ...items,
              {
                id: nid,
                group: typeof groupId == "string" ? parseInt(groupId) : groupId,
                start_time: creatingTime.start ? creatingTime.start : time,
                end_time: creatingTime.end
                  ? creatingTime.end
                  : time + 1000 * 60 * 30,
                selected: false,
                itemProps: {
                  style: {
                    background: "C2E5FA",
                  },
                },
                color: "C2E5FA",
                dptTyp: {
                  id: assign.dptTyp,
                  name: dptTyp.find((ele) => ele.id === assign.dptTyp)?.name,
                },
                dpt: {
                  id: assign.dpt,
                  name: dptTyp
                    .find((ele) => ele.id === assign.dptTyp)
                    ?.dpt.find((ele) => ele.id === assign.dpt)?.name,
                  dsply: dptTyp
                    .find((ele) => ele.id === assign.dptTyp)
                    ?.dpt.find((ele) => ele.id === assign.dpt)?.dsply,
                },
                tmp: {
                  id: assign.tmp,
                  name: tmp?.find((ele) => ele?.id === assign.tmp)?.name,
                  time: tmp?.find((ele) => ele?.id === assign.tmp)?.time,
                },
                wok: {
                  id: assign.work,
                  name: wok?.find((ele) => ele.id === assign.work)?.name,
                  dsply: wok?.find((ele) => ele.id === assign.work)?.dsply,
                },
                wokId: null,
                storeArr: store
                  .filter((ele) => assign.store.includes(ele.id))
                  .map((ele) => {
                    return { ...ele, sysAct: "N" };
                  }),
                temp_task: [""],
                canMove: true,
                canResize: "both",
                canChangeGroup: false,
              },
            ],
            creating: [...creating, nid],
            creatingGroups: [...creatingGroups, groupId],
            creatingTime: {
              start: start ? start : creatingTime.start,
              end: end ? end : creatingTime.end,
            },
            assign: {
              ...assign,
              staff: [...assign.staff, groupId],
              time: {
                start: start ? start : assign.time.start,
                end: end ? end : assign.time.end,
              },
            },
          },
        });
      }
    }
  };
  const handleTimeChange = (
    visibleTimeStart: number,
    visibleTimeEnd: number
  ) => {
    console.log(
      moment(visibleTimeStart).format("YYYY-MM-DD HH:mm"),
      moment(visibleTimeEnd).format("YYYY-MM-DD HH:mm"),
      moment(workDate).format("YYYY-MM-DD HH:mm"),
      moment(workDateEnd).format("YYYY-MM-DD HH:mm")
    );

    let minTime = workDate.valueOf();
    let maxTime = workDateEnd.valueOf();
    // when workdate > maxTime or workdate < minTime, page changing will trigger this event, so set condition to prevent
    if (visibleTimeStart < maxTime && visibleTimeEnd > minTime) {
      if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
        AssignDispatch({
          type: "setState",
          payload: {
            vTimeStart: minTime,
            vTimeEnd: maxTime,
          },
        });
      } else if (visibleTimeStart < minTime) {
        AssignDispatch({
          type: "setState",
          payload: {
            vTimeStart: minTime,
            vTimeEnd: minTime + (visibleTimeEnd - visibleTimeStart),
          },
        });
      } else if (visibleTimeEnd > maxTime) {
        AssignDispatch({
          type: "setState",
          payload: {
            vTimeStart: maxTime - (visibleTimeEnd - visibleTimeStart),
            vTimeEnd: maxTime,
          },
        });
      } else {
        AssignDispatch({
          type: "setState",
          payload: {
            vTimeStart: visibleTimeStart,
            vTimeEnd: visibleTimeEnd,
          },
        });
      }
    }
  };
  const groupRenderer: (
    props: ReactCalendarGroupRendererProps<any>
  ) => React.ReactNode = ({ group }) => {
    return (
      <div className="custom-group">
        <span className="title">{group.title}</span>
      </div>
    );
  };

  const itemRenderer:
    | ((props: ReactCalendarItemRendererProps<TimelineItem>) => React.ReactNode)
    | undefined = ({ item, itemContext, getItemProps, getResizeProps }) => {
    let style = item.itemProps ? item.itemProps.style : {};
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    return (
      <div
        {...getItemProps(item)}
        style={{ ...getItemProps(item).style, ...style }}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ""}
        <div className="rct-item-bg"></div>
        <div
          className="rct-item-content"
          style={{
            maxHeight: `${itemContext.dimensions.height}`,
            background: `#${item.color}`,
            opacity: item.selected ? 1 : 0.8,
          }}
        >
          <p className="item-content-tmp">
            {item?.tmp?.name ? item.tmp.name : "-"}
          </p>
          <p className="item-content-wok">
            {item?.wok?.name ? item.wok.name : "-"}
          </p>
          <p className="item-content-sto">
            {item.storeArr.length
              ? item.storeArr.map((ele, i) => {
                  return (
                    <React.Fragment key={ele.id}>
                      {i !== 0 ? ", " : null}
                      <span
                        className={`${ele.sysAct === "Y" ? "area-del" : ""}`}
                      >
                        {ele.alt}
                      </span>
                    </React.Fragment>
                  );
                })
              : "-"}
          </p>
        </div>
        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ""}
      </div>
    );
  };

  function formatLabel(
    [timeStart, timeEnd]: [moment.Moment, moment.Moment],
    unit: Unit,
    labelWidth: number
  ) {
    let format;
    if (labelWidth >= 50) {
      format = "HH:mm";
    } else {
      format = "mm";
    }
    return timeStart.format(format);
  }

  function moveResizeValidator(
    action: "move" | "resize",
    itemId: string | number,
    time: number,
    resizeEdge: "left" | "right"
  ) {
    if (
      action === "resize" &&
      resizeEdge === "left" &&
      time > creatingTime.end
    ) {
      return creatingTime.end;
    }
    if (
      action === "resize" &&
      resizeEdge === "right" &&
      time < creatingTime.start
    ) {
      return creatingTime.start;
    }
    return time;
  }

  return (
    <div className="timeline-area">
      {selectGroups.length > 0 && (
        <Timeline
          groups={selectGroups}
          items={items}
          lineHeight={80}
          visibleTimeStart={vTimeStart}
          visibleTimeEnd={vTimeEnd}
          canMove={true}
          canResize={"both"}
          canChangeGroup={true}
          onItemMove={handleItemMove}
          onItemResize={handleItemResize}
          onItemClick={handleItemClick}
          onItemSelect={handleItemSelect}
          onItemDeselect={handleItemDeselect}
          itemHeightRatio={1}
          dragSnap={30 * 60 * 1000}
          onCanvasClick={handleCanvasClick}
          sidebarWidth={80}
          onTimeChange={handleTimeChange}
          stackItems={true}
          groupRenderer={groupRenderer}
          itemRenderer={itemRenderer}
          moveResizeValidator={moveResizeValidator}
          selected={selected}
        >
          <TimelineHeaders className="sticky tl">
            <SidebarHeader>
              {({ getRootProps }) => {
                return <div {...getRootProps()}></div>;
              }}
            </SidebarHeader>
            <DateHeader labelFormat={formatLabel} />
          </TimelineHeaders>
        </Timeline>
      )}
    </div>
  );
};

import { createContext, useReducer } from "react";
import { MyWoks, Tasks } from "../utils/types";

export interface ResGetMywork {
  Active: "Y" | "D";
  AgWkId: number;
  Dsply: "RRG";
  SDT: string;
  EDT: string;
  Html: string;
  RvStId: number;
  StIfId: number;
  Time: number;
  TpWkName: string;
  Area: {
    where: number;
    Name: string;
    SysAct: "Y" | "N";
    compl: "O" | "Y";
  }[];
  user: {
    id: number;
    username: string;
    profile_photo_path: string;
  }[];
}

export interface MyworkData {
  wokLists?: MyWoks[];
  taskLists?: Array<Tasks[]>;
  workDate?: moment.Moment | undefined;
  status?: "showWork" | "showControl" | "showTask";
  hideFinish?: boolean;
  area?: {
    id: number;
    name: string;
  };
  work?: {
    id: number;
    name: string;
  };
  selectedTip?: string;
  reload?: boolean;
  reloadTask?: boolean;
  staff?: {
    id: number;
    name: string;
  }[];
  selectedStaff?: number;
}

const data: MyworkData = {
  wokLists: [],
  taskLists: [],
  workDate: undefined,
  status: "showWork",
  hideFinish: false,
  area: { id: 0, name: "" },
  work: { id: 0, name: "" },
  selectedTip: "",
  reload: true,
  reloadTask: true,
  staff: [],
  selectedStaff: null
};

interface ActionType {
  type: "setState";
  payload: MyworkData;
}

function reducer(state: MyworkData, action: ActionType) {
  switch (action.type) {
    case "setState":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export interface MyworkContextType {
  data: MyworkData;
  dispatch: React.Dispatch<ActionType>;
}

export const MyworkContext = createContext<MyworkContextType>({
  data,
  dispatch: () => {},
});

export const MyworkContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, data);
  return (
    <MyworkContext.Provider value={{ data: state, dispatch }}>
      {children}
    </MyworkContext.Provider>
  );
};

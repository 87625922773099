import { useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Control } from "../components/tmpwork/Control";
import { Title } from "../components/ui/Title";
import { InitContext } from "../contexts/init";
import { TempWorkContext } from "../contexts/tmpwork";
import moment from "moment";
import { locationState } from "../utils/types";
import { Count } from "../components/tmpwork/Count";
import { WokList } from "../components/tmpwork/WokList";
import { staticText } from "../utils/staticText";

import { ReactComponent as SelectTmp } from "../images/overview/SelectTmp.svg";
import { ReactComponent as SelectWok } from "../images/overview/SelectWok.svg";

import { Loading } from "../components/ui/Loading";

/** 作業總覽 */
export const TmpWork = () => {
  const { data: InitData, dispatch: InitDispatch } = useContext(InitContext);
  const { dptTyp: dptTypList, dataLoading, isMobile } = InitData;
  const { data, dispatch } = useContext(TempWorkContext);
  const {
    workDate,
    status,
    reload,
    tmp,
    openCount,
    tmpWok,
    tmpWokList,
    tab,
    work,
  } = data;

  const { overview } = staticText;

  const { pathname, state } = useLocation<locationState>();
  const tabWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // TODO: fetch data
    dispatch({
      type: "setState",
      payload: {
        status: "showControl",
        workDate: moment().startOf("day"),
        tmp: 0,
        tmpList: [],
        tab: null,
        pubDates: [],
      },
    });
  }, [pathname]);

  useEffect(() => {
    dispatch({
      type: "setState",
      payload: {
        tmp: 0,
        tmpList: [],
        tmpWokList: [],
        taskLists: [],
        status: "showControl",
        openCount: false,
        tab: null,
      },
    });
    if (workDate && state?.dptId) {
      let url = new URL(
        `${
          process.env.NODE_ENV === "development"
            ? "https://cors-anywhere.herokuapp.com/"
            : ""
        }${process.env.REACT_APP_URL}viewtmpinfo`
      );
      let params = {
        AgDate: workDate.format("YYYY-MM-DD"),
        DpIfId: state.dptId,
      };
      url.search = new URLSearchParams(params).toString();

      fetch(url.toString())
        .then((res) => res.json())
        .then((tmps) => {
          dispatch({
            type: "setState",
            payload: {
              tmpList: tmps.map((tmp) => {
                return {
                  id: tmp.TpIfId,
                  name: tmp.TpIfName,
                };
              }),
            },
          });
        });

      let dateMarkUrl = new URL(
        `${
          process.env.NODE_ENV === "development"
            ? "https://cors-anywhere.herokuapp.com/"
            : ""
        }${process.env.REACT_APP_URL}viewdate`
      );
      let dateMarkParams = {
        DpIfId: state.dptId,
        Today: workDate.format("YYYY-MM-DD"),
      };

      dateMarkUrl.search = new URLSearchParams(dateMarkParams).toString();

      fetch(dateMarkUrl.toString())
        .then((res) => res.json())
        .then((res) =>
          dispatch({
            type: "setState",
            payload: {
              pubDates: res,
            },
          })
        );
    }
  }, [workDate, state]);

  useEffect(() => {
    if (workDate && tmp) {
      InitDispatch({
        type: "setState",
        payload: { dataLoading: { value: true, type: "search" } },
      });
      // TODO: fetch viewuntsk
      console.log("📥fetch viewuntsk");
      console.table({
        AgDate: workDate.format("YYYY-MM-DD"),
        TpIfId: tmp.toString(),
      });

      let url = new URL(
        `${
          process.env.NODE_ENV === "development"
            ? "https://cors-anywhere.herokuapp.com/"
            : ""
        }${process.env.REACT_APP_URL}viewuntsk`
      );

      url.search = new URLSearchParams({
        AgDate: workDate.format("YYYY-MM-DD"),
        TpIfId: tmp.toString(),
      }).toString();

      fetch(url.toString())
        .then((res) => res.json())
        .then((res) => {
          dispatch({
            type: "setState",
            payload: {
              status: "showCount",
              tmpWokList: res.map((tmpWork) => {
                return {
                  id: tmpWork.TpWkId,
                  name: tmpWork.TwkName,
                  sCol: tmpWork.Color,
                  num: tmpWork.Count,
                  expMin: tmpWork.Time / (1000 * 60),
                  cloDT: tmpWork.EDT,
                  tip: tmpWork.Html,
                  dsply: tmpWork.Dsply,
                };
              }),
              openCount: true,
            },
          });
        })
        .finally(() =>
          InitDispatch({
            type: "setState",
            payload: { dataLoading: { value: false } },
          })
        );
    }
  }, [tmp]);

  useEffect(() => {
    if (tmpWok && reload) {
      console.log("📥fetch viewwork");
      console.table({
        AgDate: workDate.format("YYYY-MM-DD"),
        TpWkId: work.id.toString(),
        Dsply: tab,
      });

      let url = new URL(
        `${
          process.env.NODE_ENV === "development"
            ? "https://cors-anywhere.herokuapp.com/"
            : ""
        }${process.env.REACT_APP_URL}viewwork`
      );

      url.search = new URLSearchParams({
        AgDate: workDate.format("YYYY-MM-DD"),
        TpWkId: work.id.toString(),
        VwDsply: tab,
        WkDsply: tmpWokList.find((tmpwok) => tmpwok.id === tmpWok).dsply,
      }).toString();

      fetch(url.toString())
        .then((res) => res.json())
        .then((res) => {
          console.log(res);

          let currentWok = tmpWokList.find((Wok) => Wok.id === tmpWok);

          dispatch({
            type: "setState",
            payload: {
              taskLists: res.map((taskList) => {
                return {
                  id: taskList.AgWkId,
                  WkName: currentWok.name,
                  tip: currentWok.tip,
                  expMin: currentWok.expMin,
                  SDT: taskList.SDT,
                  EDT: taskList.EDT,
                  active: taskList.Active,
                  staf: taskList.user.map((user) => {
                    return {
                      id: user.id,
                      name: user.Name,
                      pic: user.photo,
                    };
                  }),
                  data: taskList.data.map((task) => {
                    return {
                      id: task.AgTkId,
                      name: task.todo,
                      DTS: task.DTS,
                      stat: task.ChkStus,
                      user: {
                        id: task.CpEmpId,
                        name: task.Name,
                        pic: task.photo,
                      },
                    };
                  }),
                };
              }),
              reload: false,
            },
          });
        })
        .finally(() =>
          InitDispatch({
            type: "setState",
            payload: {
              dataLoading: { value: false },
              spinning: { value: false },
            },
          })
        );
    }
  }, [tmpWok, reload, tab]);

  useEffect(() => {
    if (tabWrapper.current && isMobile) {
      window.scrollTo({
        top: tabWrapper.current.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [tmpWok, tab, tabWrapper.current]);

  return (
    <div className="tmpwork-wrapper wrapper">
      <Title
        text={
          dptTypList
            .find((ele) =>
              ele.dpt.find((ele) => ele.id === parseInt(state?.dptId))
            )
            ?.dpt.find((ele) => ele.id === parseInt(state?.dptId))
            ?.name as string
        }
      />
      <Control />
      <div className="separator"></div>
      {status === "showControl" && !dataLoading.value && (
        <div className="hint-box tmp">
          <SelectTmp />
          <p>{overview.select.tmp}</p>
        </div>
      )}
      {status !== "showControl" && <Count />}
      {status === "showControl" && dataLoading.value && <Loading />}
      {status === "showCount" && openCount && (
        <div className="hint-box wok">
          <SelectWok />
          <p>{overview.select.wok}</p>
        </div>
      )}
      {status === "showTask" && <WokList ref={tabWrapper} />}
    </div>
  );
};

import { DatePicker, Select, TimePicker } from "antd";
import moment, { Moment } from "moment";
import { useState, useEffect, useContext } from "react";
import locale from "antd/es/date-picker/locale/zh_TW";
import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import { Button } from "../ui/Button";
import { useAlert } from "react-alert";
import { staticText } from "../../utils/staticText";
import { InitContext } from "../../contexts/init";
import { AssignContext } from "../../contexts/assign";

const { Option } = Select;

export const AssignArea = () => {
  const { data: InitData, dispatch: InitDispatch } = useContext(InitContext);
  const { popup, account } = InitData;
  const { data: AssignData, dispatch: AssignDispatch } =
    useContext(AssignContext);
  const {
    groups,
    items,
    workDate,
    creating,
    status,
    tagColor,
    assign,
    store,
    selectItem,
    tmp,
    wok,
    dptTyp,
    unpDat,
  } = AssignData;

  const { assignText } = staticText;
  const alert = useAlert();
  const [content, setContent] = useState({
    title: assignText.status.unselect.title,
    subtitle: assignText.status.unselect.subtitle,
  });
  const [disabled, setDisabled] = useState({
    timepicker: true,
    staf: true,
    dptTyp: true,
    dpt: true,
    tmp: true,
    work: true,
    sto: true,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const Admin = ["NoGp", "NoMuGp"];

  useEffect(() => {
    switch (status) {
      case "unselect":
        setContent({
          title: assignText.status.unselect.title,
          subtitle: assignText.status.unselect.subtitle,
        });
        setDisabled({
          timepicker: true,
          staf: true,
          dptTyp: true,
          dpt: true,
          tmp: true,
          work: true,
          sto: true,
        });
        break;
      case "new":
        setContent({
          title: assignText.status.new.title,
          subtitle: assignText.status.new.subtitle,
        });
        setDisabled({
          timepicker: false,
          staf: false,
          dptTyp: false,
          dpt: false,
          tmp: false,
          work: false,
          sto: false,
        });
        break;
      case "edit":
        setContent({
          title: assignText.status.edit.title,
          subtitle: assignText.status.edit.subtitle,
        });
        setDisabled({
          timepicker: false,
          staf: false,
          dptTyp: false,
          dpt: false,
          tmp: false,
          work: false,
          sto: false,
        });
        break;
    }
  }, [status]);

  return (
    <>
      <div className="assign-area">
        <div
          className="color-tag"
          style={{
            background: `${tagColor && status === "edit" ? tagColor : ""}`,
          }}
        ></div>
        <h3 className="assign-title">{content.title}</h3>
        <h4 className="assign-subtitle">{content.subtitle}</h4>
        <div className="assign-content">
          <h6>{assignText.assignArea.workDate.title}</h6>
          <DatePicker
            bordered={false}
            disabled
            suffixIcon={<></>}
            value={workDate}
            locale={locale}
          />

          <h6>{assignText.assignArea.workTime.title}</h6>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TimePicker
              inputReadOnly
              allowClear={false}
              showNow={false}
              className="control-timePicker"
              format="HH:mm"
              minuteStep={30}
              disabled={disabled.timepicker}
              placeholder={assignText.assignArea.workTime.placeholder_s}
              onChange={(value) => {
                console.log(value);

                let start: number, end: number;
                if (value.valueOf() < assign.time.end) {
                  start = value
                    ? moment(workDate)
                        .add(value?.hour(), "hour")
                        .add(value?.minute(), "minute")
                        .valueOf()
                    : assign.time.start;
                  end = assign.time.end;
                } else {
                  start = assign.time.end;
                  end = value
                    ? moment(workDate)
                        .add(value?.hour(), "hour")
                        .add(value?.minute(), "minute")
                        .valueOf()
                    : assign.time.start;
                }
                AssignDispatch({
                  type: "setState",
                  payload: {
                    assign: {
                      ...assign,
                      time: {
                        start: start,
                        end: end,
                      },
                    },
                  },
                });
                if (status === "new") {
                  AssignDispatch({
                    type: "setState",
                    payload: {
                      creatingTime: {
                        start: start,
                        end: end,
                      },
                      items: items.map((item) =>
                        creating.includes(item.id)
                          ? Object.assign({}, item, {
                              start_time: start,
                              end_time: end,
                            })
                          : item
                      ),
                    },
                  });
                }
              }}
              value={
                (status === "unselect"
                  ? ""
                  : moment(assign.time.start)) as Moment
              }
              locale={locale}
            />
            <span>-</span>
            <TimePicker
              inputReadOnly
              allowClear={false}
              showNow={false}
              className="control-timePicker"
              format="HH:mm"
              minuteStep={30}
              disabled={disabled.timepicker}
              placeholder={assignText.assignArea.workTime.placeholder_e}
              onChange={(value) => {
                let start: number, end: number;
                if (value.valueOf() > assign.time.start) {
                  start = assign.time.start;
                  end = value
                    ? moment(workDate)
                        .add(value?.hour(), "hour")
                        .add(value?.minute(), "minute")
                        .valueOf()
                    : assign.time.end;
                } else {
                  start = value
                    ? moment(workDate)
                        .add(value?.hour(), "hour")
                        .add(value?.minute(), "minute")
                        .valueOf()
                    : assign.time.end;
                  end = assign.time.start;
                }
                AssignDispatch({
                  type: "setState",
                  payload: {
                    assign: {
                      ...assign,
                      time: {
                        start: start,
                        end: end,
                      },
                    },
                  },
                });
                if (status === "new") {
                  AssignDispatch({
                    type: "setState",
                    payload: {
                      creatingTime: {
                        start: start,
                        end: end,
                      },
                      items: items.map((item) =>
                        creating.includes(item.id)
                          ? Object.assign({}, item, {
                              start_time: start,
                              end_time: end,
                            })
                          : item
                      ),
                    },
                  });
                }
              }}
              value={
                (status === "unselect" ? "" : moment(assign.time.end)) as Moment
              }
              locale={locale}
            />
          </div>

          <h6>{assignText.assignArea.staff.title}</h6>
          <Select
            onFocus={(e) => {
              e.target.setAttribute("readOnly", "true");
            }}
            onDropdownVisibleChange={(value) =>
              InitDispatch({
                type: "setState",
                payload: { selectOpen: value },
              })
            }
            getPopupContainer={() =>
              document.querySelector(".ant-select-wrapper")
            }
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder={assignText.assignArea.staff.placeholder}
            value={assign.staff}
            onChange={(value) => {
              AssignDispatch({
                type: "setState",
                payload: {
                  assign: {
                    ...assign,
                    staff: value,
                  },
                },
              });
              if (status === "new") {
                let tempCreating = new Array();
                let tempItems = new Array();
                let nid = 100001;
                for (let i of value) {
                  tempCreating.push(nid);
                  tempItems.push({
                    id: nid,
                    group: i,
                    start_time: assign.time.start,
                    end_time: assign.time.end,
                    selected: false,
                    itemProps: {
                      style: {
                        background: `C2E5FA`,
                      },
                    },
                    color: `C2E5FA`,
                    dptTyp: {
                      id: assign.dptTyp,
                      name: dptTyp.find((ele) => ele.id === assign.dptTyp)
                        ?.name,
                    },
                    dpt: {
                      id: assign.dpt,
                      name: dptTyp
                        .find((ele) => ele.id === assign.dptTyp)
                        ?.dpt.find((ele) => ele.id === assign.dpt)?.name,
                    },
                    tmp: {
                      id: assign.tmp,
                      name: tmp.find((ele) => ele.id === assign.tmp)?.name,
                    },
                    wok: {
                      id: assign.work,
                      name: wok.find((ele) => ele.id === assign.work)?.name,
                    },
                    wokId: null,
                    storeArr: store.filter((where) =>
                      assign.store.includes(where.id)
                    ),
                    canMove: true,
                    canResize: "both",
                    canChangeGroup: false,
                  });
                  nid++;
                }
                let tempArr = items.filter((ele) => ele.id < 100000);
                AssignDispatch({
                  type: "setState",
                  payload: {
                    creatingGroups: value,
                    creating: tempCreating,
                    items: tempArr.concat(tempItems),
                    selected: [],
                  },
                });
              }
            }}
            className="control-people"
            suffixIcon={status === "unselect" ? <></> : <DownOutlined />}
            showArrow={status === "unselect" ? false : true}
            disabled={disabled.staf}
          >
            {groups.map((ele) => {
              return (
                <Option value={ele.id} label={ele.title} key={ele.id}>
                  {ele.title}
                </Option>
              );
            })}
          </Select>

          <h6>{assignText.assignArea.dptTyp.title}</h6>
          <Select
            onFocus={(e) => {
              e.target.setAttribute("readOnly", "true");
            }}
            onDropdownVisibleChange={(value) =>
              InitDispatch({
                type: "setState",
                payload: { selectOpen: value },
              })
            }
            getPopupContainer={() =>
              document.querySelector(".ant-select-wrapper")
            }
            style={{ width: "100%" }}
            placeholder={assignText.assignArea.dptTyp.placeholder}
            onChange={(value) => {
              AssignDispatch({
                type: "setState",
                payload: {
                  assign: {
                    ...assign,
                    date: {
                      start: "",
                      end: "",
                    },
                    dptTyp: value,
                    dpt: null,
                    tmp: null,
                    work: null,
                    store: [],
                  },
                },
              });
              if (status === "new") {
                AssignDispatch({
                  type: "setState",
                  payload: {
                    items: items.map((item) =>
                      creating.includes(item.id)
                        ? Object.assign({}, item, {
                            dptTyp: {
                              id: value,
                              name: dptTyp.find((ele) => ele.id === value)
                                ?.name,
                            },
                            dpt: null,
                            tmp: null,
                            wok: null,
                            storeArr: [],
                          })
                        : item
                    ),
                  },
                });
              }
            }}
            disabled={disabled.dptTyp}
            value={assign.dptTyp}
            suffixIcon={status === "unselect" ? <></> : <DownOutlined />}
            showArrow={status === "unselect" ? false : true}
          >
            {dptTyp.map((ele) => {
              return (
                <Option value={ele.id} label={ele.name} key={ele.id}>
                  {ele.name}
                </Option>
              );
            })}
          </Select>

          <h6>{assignText.assignArea.dpt.title}</h6>
          <Select
            onFocus={(e) => {
              e.target.setAttribute("readOnly", "true");
            }}
            onDropdownVisibleChange={(value) =>
              InitDispatch({
                type: "setState",
                payload: { selectOpen: value },
              })
            }
            getPopupContainer={() =>
              document.querySelector(".ant-select-wrapper")
            }
            style={{ width: "100%" }}
            placeholder={assignText.assignArea.dpt.placeholder}
            onChange={(value) => {
              AssignDispatch({
                type: "setState",
                payload: {
                  assign: {
                    ...assign,
                    dpt: value,
                    tmp: null,
                    work: null,
                    store: [],
                  },
                },
              });
              if (status === "new") {
                AssignDispatch({
                  type: "setState",
                  payload: {
                    items: items.map((item) =>
                      creating.includes(item.id)
                        ? Object.assign({}, item, {
                            dpt: {
                              id: value,
                              name: dptTyp
                                .find((ele) => ele.id === assign.dptTyp)
                                ?.dpt.find((ele) => ele.id === value)?.name,
                            },
                            tmp: null,
                            wok: null,
                            storeArr: [],
                          })
                        : item
                    ),
                    canCallApi:
                      dptTyp
                        .find((ele) => ele.id === assign.dptTyp)
                        ?.dpt.find((ele) => ele.id === value)?.dsply ===
                      "NoMuGp"
                        ? true
                        : false,
                  },
                });
              }
              if (status === "edit") {
                AssignDispatch({
                  type: "setState",
                  payload: {
                    canCallApi:
                      dptTyp
                        .find((ele) => ele.id === assign.dptTyp)
                        ?.dpt.find((ele) => ele.id === value)?.dsply ===
                      "NoMuGp"
                        ? true
                        : false,
                  },
                });
              }
            }}
            disabled={disabled.dpt}
            value={assign.dpt}
            suffixIcon={status === "unselect" ? <></> : <DownOutlined />}
            showArrow={status === "unselect" ? false : true}
          >
            {dptTyp
              .find((ele) => ele.id === assign.dptTyp)
              ?.dpt.map((ele) => {
                return (
                  <Option value={ele.id} label={ele.name} key={ele.id}>
                    {ele.name}
                  </Option>
                );
              })}
          </Select>

          {dptTyp
            .find((ele) => ele.id === assign.dptTyp)
            ?.dpt.find((ele) => ele.id === assign.dpt)?.dsply === "NoGp" && (
            <>
              <h6>{assignText.assignArea.date.title}</h6>
              <DatePicker.RangePicker
                inputReadOnly
                style={{ width: "100%" }}
                locale={locale}
                onOpenChange={(value) =>
                  InitDispatch({
                    type: "setState",
                    payload: { datepickerOpen: value },
                  })
                }
                getPopupContainer={() =>
                  document.querySelector(".date-picker-wrapper")
                }
                dropdownClassName="area-range-picker"
                onChange={(value) => {
                  AssignDispatch({
                    type: "setState",
                    payload: {
                      assign: {
                        ...assign,
                        date: {
                          start: value
                            ? value[0]?.format("YYYY-MM-DD")
                            : assign.date.start,
                          end: value
                            ? value[1]?.format("YYYY-MM-DD")
                            : assign.date.end,
                        },
                        tmp: null,
                        work: null,
                        store: [],
                      },
                      store: [],
                      wok: [],
                      tmp: [],
                    },
                  });
                }}
                disabled={disabled.sto}
                value={[
                  assign.date.start ? moment(assign.date.start) : null,
                  assign.date.end ? moment(assign.date.end) : null,
                ]}
              />
            </>
          )}

          {assign.dpt && (
            <>
              <h6>{assignText.assignArea.tmp.title}</h6>
              <Select
                onFocus={(e) => {
                  e.target.setAttribute("readOnly", "true");
                }}
                onDropdownVisibleChange={(value) =>
                  InitDispatch({
                    type: "setState",
                    payload: { selectOpen: value },
                  })
                }
                getPopupContainer={() =>
                  document.querySelector(".ant-select-wrapper")
                }
                style={{ width: "100%" }}
                onChange={(value) => {
                  AssignDispatch({
                    type: "setState",
                    payload: {
                      assign: {
                        ...assign,
                        tmp: value,
                        work: null,
                        store: [],
                      },
                    },
                  });
                  if (status === "new") {
                    AssignDispatch({
                      type: "setState",
                      payload: {
                        items: items.map((item) =>
                          creating.includes(item.id)
                            ? Object.assign({}, item, {
                                tmp: {
                                  id: value,
                                  name: tmp.find((ele) => ele.id === value)
                                    ?.name,
                                },
                                wok: null,
                                storeArr: [],
                              })
                            : item
                        ),
                      },
                    });
                  }
                }}
                disabled={disabled.tmp}
                value={assign.tmp}
                placeholder={assignText.assignArea.tmp.placeholder}
                suffixIcon={status === "unselect" ? <></> : <DownOutlined />}
                showArrow={status === "unselect" ? false : true}
              >
                {tmp.length > 0
                  ? tmp.map((ele) => {
                      return (
                        <Option value={ele.id} label={ele.name} key={ele.id}>
                          {ele.name}
                        </Option>
                      );
                    })
                  : selectItem?.tmp && (
                      <Option
                        value={selectItem.tmp.id}
                        label={selectItem.tmp.name}
                        key={selectItem.tmp.id}
                      >
                        {selectItem.tmp.name}
                      </Option>
                    )}
              </Select>
            </>
          )}

          {assign.dpt && (
            <>
              <h6>{assignText.assignArea.work.title}</h6>
              <Select
                onFocus={(e) => {
                  e.target.setAttribute("readOnly", "true");
                }}
                onDropdownVisibleChange={(value) =>
                  InitDispatch({
                    type: "setState",
                    payload: { selectOpen: value },
                  })
                }
                getPopupContainer={() =>
                  document.querySelector(".ant-select-wrapper")
                }
                style={{ width: "100%" }}
                onChange={(value) => {
                  AssignDispatch({
                    type: "setState",
                    payload: {
                      assign: {
                        ...assign,
                        work: value,
                        store: [],
                      },
                    },
                  });
                  if (status === "new") {
                    AssignDispatch({
                      type: "setState",
                      payload: {
                        items: items.map((item) =>
                          creating.includes(item.id)
                            ? Object.assign({}, item, {
                                wok: {
                                  id: value,
                                  name: wok.find((ele) => ele.id === value)
                                    ?.name,
                                },
                                storeArr: [],
                              })
                            : item
                        ),
                      },
                    });
                  }
                }}
                disabled={disabled.work}
                value={assign.work}
                placeholder={assignText.assignArea.work.placeholder}
                suffixIcon={status === "unselect" ? <></> : <DownOutlined />}
                showArrow={status === "unselect" ? false : true}
              >
                {wok.length > 0
                  ? wok.map((ele) => {
                      return (
                        <Option value={ele.id} label={ele.name} key={ele.id}>
                          {ele.name}
                        </Option>
                      );
                    })
                  : selectItem?.wok && (
                      <Option
                        value={selectItem.wok.id}
                        label={selectItem.wok.name}
                        key={selectItem.wok.id}
                      >
                        {selectItem.wok.name}
                      </Option>
                    )}
              </Select>
            </>
          )}

          {assign.dpt && (
            <>
              <h6>{assignText.assignArea.store.title}</h6>
              <Select
                onFocus={(e) => {
                  e.target.setAttribute("readOnly", "true");
                }}
                onDropdownVisibleChange={(value) =>
                  InitDispatch({
                    type: "setState",
                    payload: { selectOpen: value },
                  })
                }
                getPopupContainer={() =>
                  document.querySelector(".ant-select-wrapper")
                }
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder={assignText.assignArea.store.placeholder}
                value={assign.store}
                onChange={(value) => {
                  console.log(value.sort());

                  let ifAll =
                    (value as (string | number)[]).findIndex(
                      (ele: string | number) => ele === "all"
                    ) !== -1;

                  if (ifAll) {
                    AssignDispatch({
                      type: "setState",
                      payload: {
                        assign: {
                          ...assign,
                          store: store.map((ele) => ele.id),
                        },
                        items: items.map((item) =>
                          creating.includes(item.id)
                            ? Object.assign(item, {
                                storeArr: store.map((ele) => {
                                  return { ...ele, sysAct: "N" };
                                }),
                              })
                            : item
                        ),
                      },
                    });
                    return;
                  }
                  AssignDispatch({
                    type: "setState",
                    payload: {
                      assign: {
                        ...assign,
                        store: value.sort(),
                      },
                    },
                  });
                  if (status === "new") {
                    AssignDispatch({
                      type: "setState",
                      payload: {
                        items: items.map((item) =>
                          creating.includes(item.id)
                            ? Object.assign({}, item, {
                                storeArr: store
                                  .filter((ele) => value.includes(ele.id))
                                  .map((ele) => {
                                    return { ...ele, sysAct: "N" };
                                  }),
                              })
                            : item
                        ),
                      },
                    });
                  }
                }}
                className="control-people"
                disabled={disabled.sto}
                suffixIcon={status === "unselect" ? <></> : <DownOutlined />}
                showArrow={status === "unselect" ? false : true}
              >
                {store.length > 0 && (
                  <Option
                    value="all"
                    label="all"
                    key={9999999999}
                    style={{ color: "#5e94fd", textAlign: "center" }}
                  >
                    {assignText.assignArea.selectAll}
                  </Option>
                )}
                {store.length > 0
                  ? store
                      .filter((ele) => ele.sysAct === "N")
                      .map((ele) => {
                        return (
                          <Option value={ele.id} label={ele.name} key={ele.id}>
                            {ele.name}
                          </Option>
                        );
                      })
                  : selectItem?.storeArr &&
                    selectItem.storeArr
                      .filter((ele) => ele.sysAct === "N")
                      .map((ele) => {
                        return (
                          <Option value={ele.id} label={ele.name} key={ele.id}>
                            {ele.name}
                          </Option>
                        );
                      })}
              </Select>
            </>
          )}
        </div>
        {status !== "edit" ? (
          <>
            <Button
              text={assignText.status.new.btn1}
              type="sub"
              disabled={!creating.length}
              multi={true}
              onClick={() => {
                InitDispatch({
                  type: "setState",
                  payload: {
                    popup: {
                      ...popup,
                      open: true,
                      title: assignText.popup.clear.title,
                      content: assignText.popup.clear.content,
                      btnMain: assignText.popup.clear.btnMain,
                      btnSub: assignText.popup.clear.btnSub,
                      onClickMain: () => {
                        AssignDispatch({
                          type: "setState",
                          payload: {
                            items: items.filter((ele) => ele.id < 100000),
                            showAssign: false,
                          },
                        });
                        AssignDispatch({ type: "clearTimeline", payload: {} });
                        InitDispatch({ type: "closePopup", payload: {} });
                      },
                      onClickSub: () =>
                        InitDispatch({ type: "closePopup", payload: {} }),
                    },
                  },
                });
              }}
            />
            <Button
              text={
                loading
                  ? assignText.status.new.btn3
                  : assignText.status.new.btn2
              }
              multi={true}
              type="main"
              disabled={
                !(
                  assign.store.length &&
                  assign.time.start &&
                  assign.time.end &&
                  assign.staff.length
                ) || loading
              }
              loading={loading}
              onClick={() => {
                setLoading(true);
                let data = {
                  AgDate: workDate.format("YYYY-MM-DD"),
                  SDT: moment(assign.time.start).format("YYYY-MM-DD HH:mm"),
                  EDT: moment(assign.time.end).format("YYYY-MM-DD HH:mm"),
                  AgMagId: account.id,
                  AgEmpId: assign.staff,
                  DpTpId: assign.dptTyp,
                  DpIfId: assign.dpt,
                  TpIfId: assign.tmp,
                  TpWkId: assign.work,
                  RvStId: assign.store,
                };
                fetch(
                  `${
                    process.env.NODE_ENV === "development"
                      ? "https://cors-anywhere.herokuapp.com/"
                      : ""
                  }${process.env.REACT_APP_URL}api/asgwork`,
                  {
                    headers: new Headers({
                      "Content-Type": "application/json",
                    }),
                    method: "POST",
                    body: JSON.stringify(data),
                  }
                )
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.status === "success") {
                      alert.success(assignText.alert.save.success);

                      AssignDispatch({
                        type: "setState",
                        payload: {
                          isReload: true,
                          showAssign: false,
                          unpDat: [...unpDat, workDate.format("YYYY-MM-DD")],
                        },
                      });
                      AssignDispatch({ type: "clearTimeline", payload: {} });
                    } else {
                      alert.error(
                        assignText.alert.save.fail.replace("%錯誤代碼%", "xxx")
                      );
                    }
                  })
                  .finally(() => setLoading(false));
              }}
            />
          </>
        ) : (
          <>
            <Button
              text={assignText.status.edit.btn1}
              type="sub"
              multi={true}
              onClick={() => {
                InitDispatch({
                  type: "setState",
                  payload: {
                    popup: {
                      ...popup,
                      open: true,
                      title: assignText.popup.delete.title,
                      content: (assign.staff.length > 1
                        ? assignText.popup.delete.content.many
                        : assignText.popup.delete.content.one
                      )
                        .replace(
                          "%人員%",
                          groups
                            .filter((ele) => assign.staff.includes(ele.id))
                            .map((ele) => ele.title)
                            .join(", ")
                        )
                        .replace("%作業%", selectItem.wok.name),
                      btnMain: assignText.popup.delete.btnMain,
                      btnSub: assignText.popup.delete.btnSub,
                      onClickMain: () => {
                        let data = {
                          AgWkId: assign.id,
                          iMagId: account.id,
                        };
                        fetch(
                          `${
                            process.env.NODE_ENV === "development"
                              ? "https://cors-anywhere.herokuapp.com/"
                              : ""
                          }${process.env.REACT_APP_URL}delagwk`,
                          {
                            headers: new Headers({
                              "Content-Type": "application/json",
                            }),
                            method: "POST",
                            body: JSON.stringify(data),
                          }
                        )
                          .then((res) => res.json())
                          .then((res) => {
                            if (res.status === "success") {
                              alert.success(
                                assignText.alert.delete.success.replace(
                                  "%作業%",
                                  selectItem.wok.name
                                )
                              );

                              let temp_item = items.filter(
                                (ele) => ele.wokId !== assign.id
                              );
                              AssignDispatch({
                                type: "setState",
                                payload: {
                                  items: temp_item,
                                  showAssign: false,
                                  unpDat:
                                    temp_item.length > 0
                                      ? unpDat
                                      : unpDat
                                          .slice()
                                          .filter(
                                            (date) =>
                                              date !==
                                              workDate.format("YYYY-MM-DD")
                                          ),
                                  selectItem: undefined,
                                },
                              });

                              AssignDispatch({
                                type: "clearAssign",
                                payload: {},
                              });

                              AssignDispatch({
                                type: "clearTimeline",
                                payload: {},
                              });
                            } else {
                              alert.error(
                                assignText.alert.delete.fail
                                  .replace("%作業%", selectItem.wok.name)
                                  .replace("%錯誤代碼%", "xxx")
                              );
                            }
                          });

                        InitDispatch({ type: "closePopup", payload: {} });
                      },
                      onClickSub: () =>
                        InitDispatch({ type: "closePopup", payload: {} }),
                    },
                  },
                });
              }}
            />
            <Button
              text={
                loading
                  ? assignText.status.edit.btn3
                  : assignText.status.edit.btn2
              }
              type="main"
              multi={true}
              loading={loading}
              disabled={
                (assign.time.start === selectItem?.start_time &&
                  assign.time.end === selectItem?.end_time &&
                  assign.staff
                    .slice()
                    .sort()
                    .equals(
                      items
                        ?.filter((ele) => ele.wokId === selectItem?.wokId)
                        .map((ele) => ele.group)
                        .sort()
                    ) &&
                  assign.dptTyp === selectItem?.dptTyp.id &&
                  assign.dpt === selectItem?.dpt.id &&
                  assign.tmp === selectItem?.tmp?.id &&
                  assign.work === selectItem?.wok.id &&
                  assign.store
                    .slice()
                    .sort()
                    .equals(selectItem?.storeArr.map((ele) => ele.id).sort()) &&
                  assign.store.length > 0) ||
                !(
                  assign.store.length &&
                  assign.time.start &&
                  assign.time.end &&
                  assign.staff.length
                )
                  ? true
                  : false
              }
              onClick={() => {
                InitDispatch({
                  type: "setState",
                  payload: {
                    popup: {
                      ...popup,
                      open: true,
                      title: assignText.popup.edit.title,
                      content: `<ul>
                        ${
                          assign.time.start !== selectItem?.start_time ||
                          assign.time.end !== selectItem?.end_time
                            ? `<li>
                                  - ${assignText.popup.edit.content.time
                                    .replace(
                                      "%開始時間%",
                                      moment(assign.time.start).format("HH:mm")
                                    )
                                    .replace(
                                      "%結束時間%",
                                      moment(assign.time.end).format("HH:mm")
                                    )}
                                </li>`
                            : ""
                        }
                        ${
                          assign.staff.filter(
                            (ele) =>
                              items
                                .filter(
                                  (ele) => ele.wokId === selectItem?.wokId
                                )
                                .map((ele) => ele.group)
                                .indexOf(ele) === -1
                          ).length
                            ? `<li>
                                - ${assignText.popup.edit.content.staffAdd.replace(
                                  "%新增人員%",
                                  groups
                                    .filter((ele) =>
                                      assign.staff
                                        .filter(
                                          (ele) =>
                                            items
                                              .filter(
                                                (ele) =>
                                                  ele.wokId ===
                                                  selectItem?.wokId
                                              )
                                              .map((ele) => ele.group)
                                              .indexOf(ele) === -1
                                        )
                                        .includes(ele.id)
                                    )
                                    .map((ele) => ele.title)
                                    .join(", ")
                                )}
                              </li>`
                            : ""
                        }
                        ${
                          items
                            .filter((ele) => ele.wokId === selectItem?.wokId)
                            .map((ele) => ele.group)
                            .filter((ele) => assign.staff.indexOf(ele) === -1)
                            .length
                            ? `<li>
                                - ${assignText.popup.edit.content.staffDel.replace(
                                  "%刪除人員%",
                                  groups
                                    .filter((ele) =>
                                      items
                                        .filter(
                                          (ele) =>
                                            ele.wokId === selectItem?.wokId
                                        )
                                        .map((ele) => ele.group)
                                        .filter(
                                          (ele) =>
                                            assign.staff.indexOf(ele) === -1
                                        )
                                        .includes(ele.id)
                                    )
                                    .map((ele) => ele.title)
                                    .join(", ")
                                )}
                              </li>`
                            : ""
                        }
                        ${
                          assign.dptTyp !== selectItem?.dptTyp.id
                            ? `<li>
                                - ${assignText.popup.edit.content.dptTyp.replace(
                                  "%部門%",
                                  dptTyp.find((ele) => ele.id === assign.dptTyp)
                                    ?.name
                                )}
                              </li>`
                            : ""
                        }
                        ${
                          assign.dpt !== selectItem?.dpt.id
                            ? `<li>
                                - ${assignText.popup.edit.content.dpt.replace(
                                  "%範本類型%",
                                  dptTyp
                                    .find((ele) => ele.id === assign.dptTyp)
                                    ?.dpt.find((ele) => ele.id === assign.dpt)
                                    ?.name
                                )}
                              </li>`
                            : ""
                        }
                            ${
                              tmp.length && assign?.tmp !== selectItem?.tmp?.id
                                ? `<li>
                                - ${assignText.popup.edit.content.tmp.replace(
                                  "%範本%",
                                  tmp.find((ele) => ele.id === assign.tmp)?.name
                                )}
                              </li>`
                                : ""
                            }
                            ${
                              assign.work !== selectItem?.wok.id
                                ? `<li>
                                - ${assignText.popup.edit.content.wok.replace(
                                  "%作業%",
                                  selectItem.wok.name
                                )}
                              </li>`
                                : ""
                            }
                        ${
                          assign.store.filter(
                            (ele) =>
                              selectItem?.storeArr
                                .map((ele) => ele.id)
                                .indexOf(ele) === -1
                          ).length
                            ? `<li>
                                - ${assignText.popup.edit.content.storeAdd.replace(
                                  "%新增作業項目%",
                                  store
                                    .filter((ele) =>
                                      assign.store
                                        .filter(
                                          (ele) =>
                                            selectItem?.storeArr
                                              .map((ele) => ele.id)
                                              .indexOf(ele) === -1
                                        )
                                        .includes(ele.id)
                                    )
                                    .map((ele) => ele.alt)
                                    .join(", ")
                                )}
                              </li>`
                            : ""
                        }
                        ${
                          selectItem?.storeArr
                            .map((ele) => ele.id)
                            .filter((ele) => assign.store.indexOf(ele) === -1)
                            .length
                            ? `<li>
                                - ${assignText.popup.edit.content.storeDel.replace(
                                  "%刪除作業項目%",
                                  selectItem?.storeArr
                                    .filter(
                                      (ele) =>
                                        assign.store.indexOf(ele.id) === -1
                                    )
                                    .map((ele) => ele.alt)
                                    .join(", ")
                                )}
                              </li>`
                            : ""
                        }
                          </ul>
                        `,
                      btnSub: assignText.popup.edit.btnSub,
                      btnMain: assignText.popup.edit.btnMain,
                      onClickMain: () => {
                        setLoading(true);
                        let data = {
                          AgWkId: assign.id,
                          AgDate: workDate.format("YYYY-MM-DD"),
                          SDT: moment(assign.time.start).format(
                            "YYYY-MM-DD HH:mm"
                          ),
                          EDT: moment(assign.time.end).format(
                            "YYYY-MM-DD HH:mm"
                          ),
                          AgMagId: account.id,
                          AgEmpId: assign.staff,
                          DpTpId: assign.dptTyp,
                          DpIfId: assign.dpt,
                          TpIfId: assign.tmp,
                          TpWkId: assign.work,
                          RvStId: assign.store,
                        };
                        fetch(
                          `${
                            process.env.NODE_ENV === "development"
                              ? "https://cors-anywhere.herokuapp.com/"
                              : ""
                          }${process.env.REACT_APP_URL}upagwk`,
                          {
                            headers: new Headers({
                              "Content-Type": "application/json",
                            }),
                            method: "POST",
                            body: JSON.stringify(data),
                          }
                        )
                          .then((res) => res.json())
                          .then((json) => {
                            console.log(json);

                            if (json.status === "success") {
                              AssignDispatch({
                                type: "setState",
                                payload: {
                                  isReload: true,
                                  showAssign: false,
                                },
                              });
                              AssignDispatch({
                                type: "clearTimeline",
                                payload: {},
                              });
                              alert.success(
                                assignText.alert.edit.success.replace(
                                  "%作業%",
                                  selectItem.wok.name
                                )
                              );
                            } else {
                              alert.error(
                                assignText.alert.edit.fail
                                  .replace("%作業%", selectItem.wok.name)
                                  .replace("%錯誤代碼%", "xxx")
                              );
                            }
                          })
                          .finally(() => setLoading(false));
                        InitDispatch({ type: "closePopup", payload: {} });
                      },
                      onClickSub: () =>
                        InitDispatch({ type: "closePopup", payload: {} }),
                    },
                  },
                });
              }}
            />
          </>
        )}
        <CloseOutlined
          className="assign-close"
          onClick={() => {
            const todo = () => {
              AssignDispatch({
                type: "setState",
                payload: {
                  items: items.map((item) =>
                    Object.assign({}, item, {
                      className: "",
                      selected: false,
                      itemProps: {
                        style: {
                          background: item.color,
                        },
                      },
                    })
                  ),
                  assign: {
                    id: 0,
                    time: { start: 0, end: 0 },
                    staff: [],
                    dptTyp: null,
                    dpt: null,
                    date: { start: "", end: "" },
                    tmp: null,
                    work: null,
                    store: [],
                    temp_work: null,
                    temp_task: [""],
                    updateAt: null,
                  },
                  tagColor: "",
                  status: "unselect",
                  selected: [],
                  selectItem: undefined,
                  showAssign: false,
                },
              });
            };
            if (status === "edit" && selectItem?.id < 100000) {
              if (
                assign.time.start === selectItem?.start_time &&
                assign.time.end === selectItem?.end_time &&
                assign.staff
                  .slice()
                  .sort()
                  .equals(
                    items
                      ?.filter((ele) => ele.wokId === selectItem?.wokId)
                      .map((ele) => ele.group)
                      .sort()
                  ) &&
                assign.dptTyp === selectItem?.dptTyp.id &&
                assign.dpt === selectItem?.dpt.id &&
                assign.tmp === selectItem?.tmp?.id &&
                assign.work === selectItem?.wok.id &&
                assign.store
                  .slice()
                  .sort()
                  .equals(selectItem?.storeArr.map((ele) => ele.id).sort()) &&
                assign.store.length > 0
              ) {
                todo();
              } else {
                InitDispatch({
                  type: "setState",
                  payload: {
                    popup: {
                      ...popup,
                      open: true,
                      title: assignText.popup.unedit.title,
                      content: assignText.popup.unedit.content,
                      btnMain: assignText.popup.unedit.btnMain,
                      btnSub: assignText.popup.unedit.btnSub,
                      onClickMain: () => {
                        todo();
                        InitDispatch({ type: "closePopup", payload: {} });
                      },
                      onClickSub: () =>
                        InitDispatch({ type: "closePopup", payload: {} }),
                    },
                  },
                });
              }
            } else if (status === "new") {
              AssignDispatch({
                type: "setState",
                payload: {
                  selected: [],
                  items: items.map((item) =>
                    Object.assign({}, item, {
                      className: "",
                      selected: false,
                      itemProps: {
                        style: {
                          background: item.color,
                        },
                      },
                    })
                  ),
                  showAssign: false,
                },
              });
            }
          }}
        />
      </div>
    </>
  );
};

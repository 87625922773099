import { Checkbox, Avatar, Tooltip } from "antd";
import { useContext } from "react";
import { TmpTask, Staf } from "../../contexts/tmpwork";
import { staticText } from "../../utils/staticText";
import { useAlert } from "react-alert";
import { InitContext } from "../../contexts/init";
import moment from "moment";
import { TempWorkContext } from "../../contexts/tmpwork";

export const TaskCard = ({
  id,
  GsId,
  name,
  DTS,
  stat,
  user,
  wkId,
  staf,
  active,
}: TmpTask & { wkId: number; staf: Staf[]; active: "Y" | "D" }) => {
  const { data: InitData, dispatch: InitDispatch } = useContext(InitContext);
  const { account } = InitData;
  const { data, dispatch } = useContext(TempWorkContext);
  const { tmpWok, tmpWokList } = data;

  const { overview, loading } = staticText;
  const alert = useAlert();
  console.log(staf, account);

  return (
    <div
      className={`task-card ${stat === "Y" ? "finish" : ""} ${
        staf.find((staff) => staff.id === account.id) ? "" : "disabled"
      }`}
    >
      <Checkbox
        checked={stat === "Y" ? true : false}
        // disabled={staf.find((staff) => staff.id === account.id) ? false : true}
        onChange={(e) => {
          InitDispatch({
            type: "setState",
            payload: { spinning: { value: true, tip: loading.handle } },
          });
          // TODO: fetch
          console.log("📥fetch chk");
          console.table({
            data: [
              {
                AgWkId: wkId,
                GsId: GsId,
                where: null,
                CpEmpId: account.id,
                AgTkId: id,
                ChkStus: e.target.checked ? "Y" : "O",
                DTS: e.target.checked
                  ? moment().format("YYYY-MM-DD HH:mm:ss")
                  : DTS,
              },
            ],
          });

          fetch(
            `${
              process.env.NODE_ENV === "development"
                ? "https://cors-anywhere.herokuapp.com/"
                : ""
            }${process.env.URL}coptask`,
            {
              method: "POST",
              body: JSON.stringify([
                {
                  AgWkId: wkId,
                  GsId: GsId,
                  where: null,
                  CpEmpId: account.id,
                  AgTkId: id,
                  ChkStus: e.target.checked ? "Y" : "O",
                  DTS: e.target.checked
                    ? moment().format("YYYY-MM-DD HH:mm:ss")
                    : DTS,
                },
              ]),
            }
          )
            .then((res) => res.json())
            .then((res) => {
              let newTmpWokList = tmpWokList.slice();
              if (active === "Y") {
                e.target.checked
                  ? (newTmpWokList.find((wok) => wok.id === tmpWok).num -= 1)
                  : (newTmpWokList.find((wok) => wok.id === tmpWok).num += 1);
              }

              if (res) {
                alert.success(
                  e.target.checked
                    ? overview.alert.success.replace("%任務數%", "1")
                    : overview.alert.cancel.replace("%任務數%", "1")
                );
                dispatch({
                  type: "setState",
                  payload: {
                    reload: true,
                    tmpWokList: newTmpWokList,
                  },
                });
              }
            });
        }}
      >
        {name}
      </Checkbox>
      {stat === "Y" && (
        <Tooltip title={user.name} placement="top" key={user.id}>
          <Avatar src={user.pic} />
        </Tooltip>
      )}
      <p className="fnsh-time">
        {stat === "Y" && `${DTS} ${overview.task.finish}`}
      </p>
    </div>
  );
};

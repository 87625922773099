import React, { useContext } from "react";
import { Spin } from "antd";
import { InitContext } from "../../contexts/init";
import { staticText } from "../../utils/staticText";

const { loading } = staticText;

export const Loading = () => {
  const { data } = useContext(InitContext);
  const { dataLoading } = data;

  return (
    <div className="loading-box">
      <Spin size="large" style={{ marginBottom: "5px" }}></Spin>
      <p>{loading[dataLoading.type]}</p>
    </div>
  );
};

import { InfoCircleFilled } from "@ant-design/icons";
import { Avatar, Timeline, Tooltip } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { staticText } from "../../utils/staticText";
import { MyWoks } from "../../utils/types";
import { ReactComponent as Circle } from "../../images/mywork/circle.svg";
import { ReactComponent as Check } from "../../images/mywork/check.svg";
import { InitContext } from "../../contexts/init";
import { MyworkContext } from "../../contexts/mywork";

export const Wok = ({
  id,
  name,
  STS,
  ETS,
  expMin,
  staf,
  stoAre,
  tip,
  active,
}: MyWoks) => {
  const { data: InitData, dispatch: InitDispatch } = useContext(InitContext);
  const { popup } = InitData;
  const { data: MyworkData, dispatch: MyworkDispatch } =
    useContext(MyworkContext);

  const { overview } = staticText;
  const [time, setTime] = useState("");

  useEffect(() => {
    const countdown = () => {
      if (
        moment(ETS).diff(moment()) < expMin * 60 * 1000 &&
        moment(ETS).diff(moment()) > 0
      ) {
        let diff = moment(ETS).diff(moment());
        let days = Math.floor(diff / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        if (!days && !hours && !minutes) minutes = 1; //? 不到1分鐘顯示1分鐘
        setTime(
          `${overview.wok.countdown.last}${
            days ? `${days}${overview.wok.countdown.day}` : ""
          }${hours ? `${hours}${overview.wok.countdown.hour}` : ""}${
            minutes ? `${minutes}${overview.wok.countdown.minute}` : ""
          }`
        );
      } else if (moment(ETS).diff(moment()) < 0) {
        let diff = moment().diff(moment(ETS));
        let days = Math.floor(diff / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        if (!days && !hours && !minutes) minutes = 1; //? 不到1分鐘顯示1分鐘
        setTime(
          `${overview.wok.countdown.over}${
            days ? `${days}${overview.wok.countdown.day}` : ""
          }${hours ? `${hours}${overview.wok.countdown.hour}` : ""}${
            minutes ? `${minutes}${overview.wok.countdown.minute}` : ""
          }`
        );
      }
    };
    countdown();
    const interval = setInterval(() => {
      countdown();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Timeline.Item
        dot={
          stoAre.filter((ele) => ele.done === "O").length ? (
            <Circle />
          ) : (
            <Check />
          )
        }
        key={id}
      >
        <div className="timeline-item">
          <div className="wok-title-box">
            <p className={`wok-title ${active === "D" ? "area-del" : ""}`}>
              {name}
              {tip && (
                <InfoCircleFilled
                  className="tips-icon"
                  onClick={() => {
                    window.open(tip, "_blank");
                  }}
                />
              )}
            </p>
            <Avatar.Group
              className="avatar-group"
              maxCount={4}
              size="default"
              maxStyle={{ color: "#ffffff", backgroundColor: "#5e94fd" }}
            >
              {staf.map((ele) => {
                return (
                  <Tooltip title={ele.name} placement="top" key={ele.id}>
                    <Avatar src={ele.pic} />
                  </Tooltip>
                );
              })}
            </Avatar.Group>
            <p
              className={`work-time timeline ${
                moment(ETS).diff(moment()) < expMin * 60 * 1000 &&
                // active === "Y" &&
                // stoAre.filter((ele) => ele.sysAct === "Y").length ===
                //   stoAre.length &&
                "countdown"
              }`}
            >
              {moment(STS).format("HH:mm")} ~ {moment(ETS).format("HH:mm")}{" "}
              {moment(ETS).diff(moment()) < expMin * 60 * 1000 &&
                // active === "Y" &&
                // stoAre.filter((ele) => ele.sysAct === "Y").length ===
                //   stoAre.length &&
                time}
            </p>
          </div>
          <div className="area-list">
            {stoAre.map((ele) => (
              <p
                className={`area ${ele.done === "Y" ? "task-fnsh" : ""} ${
                  ele.sysAct === "Y" ? "area-del" : ""
                }`}
                onClick={() => {
                  MyworkDispatch({
                    type: "setState",
                    payload: {
                      area: { id: ele.id, name: ele.alt },
                      work: { id: id, name: name },
                      reloadTask: true,
                    },
                  });
                  InitDispatch({type: "setState", payload: {dataLoading: {value: true, type: "search"}}});
                }}
                key={ele.id}
              >
                {ele.name ? ele.name : overview.wok.button.detail}
              </p>
            ))}
          </div>
        </div>
        <div className="separator"></div>
      </Timeline.Item>
    </>
  );
};

import { DatePicker, Select, TimePicker } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import React, { useContext, useState, useEffect } from "react";
import locale from "antd/es/date-picker/locale/zh_TW";
import moment from "moment";
import {
  PickerBaseProps,
  PickerDateProps,
  PickerTimeProps,
} from "antd/lib/date-picker/generatePicker";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { Button } from "../ui/Button";
import { useAlert } from "react-alert";
import { staticText } from "../../utils/staticText";
import { InitContext } from "../../contexts/init";
import { AssignContext } from "../../contexts/assign";

const { Option } = Select;

export const Control = () => {
  const { data: InitData, dispatch: InitDispatch } = useContext(InitContext);
  const { popup, account, permission, isMobile } = InitData;
  const { data: AssignData, dispatch: AssignDispatch } =
    useContext(AssignContext);
  const {
    groups,
    selectGroups,
    items,
    workDate,
    selected,
    status,
    assign,
    selectItem,
    unpDat,
    showDeleted,
    vTimeStart,
    vTimeEnd,
  } = AssignData;

  const { assignText, errorText } = staticText;
  const alert = useAlert();
  let location = useLocation();

  let { pub } = useParams<{ pub: string }>();
  const [testInput, setTestInput] = useState<React.Component<
    | PickerBaseProps<moment.Moment>
    | PickerDateProps<moment.Moment>
    | PickerTimeProps<moment.Moment>,
    any,
    any
  > | null>();
  const [show, setShow] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  let history = useHistory();

  const children = new Array();
  for (let i = 0; i < groups.length; i++) {
    children.push(
      <Option value={groups[i].id} label={groups[i].title} key={groups[i].id}>
        {groups[i].title}
      </Option>
    );
  }

  const handleDateChange = (date: moment.Moment | null) => {
    if (date) {
      const todo = () => {
        AssignDispatch({ type: "clearTimeline", payload: {} });
        let end: number | moment.Moment | Date;
        if (window.innerWidth < 500) {
          end = moment(date).startOf("day").add(11, "hour");
        } else if (window.innerWidth > 500 && window.innerWidth < 1000) {
          end = moment(date).startOf("day").add(14, "hour");
        } else {
          end = moment(date).startOf("day").add(18, "hour");
        }
        AssignDispatch({
          type: "setState",
          payload: {
            workDate: moment(date).startOf("day"),
            workDateEnd: moment(date).startOf("day").add(24, "hour"),
            vTimeStart: moment(date).startOf("day").add(9, "hour"),
            vTimeEnd: end,
          },
        });
        // @ts-ignore
        if (testInput) testInput.blur(); // ? TYPE?
      };
      if (
        status === "new" ||
        (status === "edit" &&
          selected.length > 0 &&
          !(
            assign.time.start === selectItem?.start_time &&
            assign.time.end === selectItem?.end_time &&
            assign.staff
              .slice()
              .sort()
              .equals(
                items
                  ?.filter((ele) => ele.wokId === selectItem?.wokId)
                  .map((ele) => ele.group)
                  .sort()
              ) &&
            assign.dptTyp === selectItem?.dptTyp.id &&
            assign.dpt === selectItem?.dpt.id &&
            assign.tmp === selectItem?.tmp?.id &&
            assign.work === selectItem?.wok.id &&
            ((pub === "unpub" &&
              (assign.store
                .slice()
                .sort()
                .equals(selectItem?.storeArr?.map((ele) => ele.id).sort()) ||
                assign.store.length > 0)) ||
              pub === "pub")
          ))
      ) {
        InitDispatch({
          type: "setState",
          payload: {
            popup: {
              ...popup,
              open: true,
              title: assignText.popup.unedit.title,
              content: assignText.popup.unedit.content,
              btnMain: assignText.popup.unedit.btnMain,
              btnSub: assignText.popup.unedit.btnSub,
              onClickMain: () => {
                todo();
                InitDispatch({ type: "closePopup", payload: {} });
              },
              onClickSub: () =>
                InitDispatch({ type: "closePopup", payload: {} }),
            },
          },
        });
      } else {
        todo();
      }
    }
  };

  const handleSelectChange = (value: any[]) => {
    const todo = () => {
      AssignDispatch({
        type: "setState",
        payload: {
          items: items
            .filter((ele) => ele.id < 100000)
            .map((item) =>
              Object.assign({}, item, {
                className: "",
                selected: false,
                itemProps: {
                  style: {
                    background: item.color,
                  },
                },
              })
            ),
        },
      });
      AssignDispatch({ type: "clearTimeline", payload: {} });
      if (
        (value as (string | number)[]).find(
          (ele: string | number) => ele === "all"
        )
      ) {
        AssignDispatch({ type: "setState", payload: { selectGroups: groups } });
        return;
      }
      let tempGroups = [];
      for (let i = 0; i < value.length; i++) {
        tempGroups.push(groups.find((element) => element.id === value[i]));
      }
      tempGroups.sort((a, b) => a.id - b.id);
      AssignDispatch({
        type: "setState",
        payload: { selectGroups: tempGroups },
      });
    };
    if (
      status === "new" ||
      (status === "edit" &&
        selected.length > 0 &&
        !(
          assign.time.start === selectItem?.start_time &&
          assign.time.end === selectItem?.end_time &&
          assign.staff
            .slice()
            .sort()
            .equals(
              items
                ?.filter((ele) => ele.wokId === selectItem?.wokId)
                .map((ele) => ele.group)
                .sort()
            ) &&
          assign.dptTyp === selectItem?.dptTyp.id &&
          assign.dpt === selectItem?.dpt.id &&
          assign.tmp === selectItem?.tmp?.id &&
          assign.work === selectItem?.wok.id &&
          ((pub === "unpub" &&
            (assign.store
              .slice()
              .sort()
              .equals(selectItem?.storeArr?.map((ele) => ele.id).sort()) ||
              assign.store.length > 0)) ||
            pub === "pub")
        ))
    ) {
      InitDispatch({
        type: "setState",
        payload: {
          popup: {
            ...popup,
            open: true,
            title: assignText.popup.unedit.title,
            content: assignText.popup.unedit.content,
            btnMain: assignText.popup.unedit.btnMain,
            btnSub: assignText.popup.unedit.btnSub,
            onClickMain: () => {
              todo();
              InitDispatch({ type: "closePopup", payload: {} });
            },
            onClickSub: () => InitDispatch({ type: "closePopup", payload: {} }),
          },
        },
      });
    } else {
      todo();
    }
  };

  useEffect(() => {
    setShow(false);
  }, [location]);

  return (
    <div className="control-box">
      <div className="select-wrapper">
        <div className="select-group">
          <h5>{assignText.assignArea.workDate.title}</h5>
          <DatePicker
            inputReadOnly
            defaultPickerValue={moment()}
            value={workDate}
            locale={locale}
            onOpenChange={(value) =>
              InitDispatch({
                type: "setState",
                payload: { datepickerOpen: value },
              })
            }
            onChange={handleDateChange}
            getPopupContainer={() =>
              document.querySelector(".date-picker-wrapper")
            }
            ref={(input) => setTestInput(input)}
            className="control-datePicker"
            // disabledDate={(current) =>
            //   pub === "unpub" ? current < moment().subtract(1, "d") : false
            // }
            dateRender={(current) => {
              return (
                <div
                  className={`ant-picker-cell-inner ${
                    pub === "unpub" &&
                    unpDat.includes(current.format("YYYY-MM-DD"))
                      ? "unpub"
                      : ""
                  }`}
                >
                  {current.date()}
                </div>
              );
            }}
          />
        </div>
        {permission.id === 1 && pub === "pub" && (
          <div className="select-group only-desktop">
            <h5>{assignText.assignArea.workTime.title}</h5>
            <TimePicker
              inputReadOnly
              allowClear={false}
              showNow={false}
              className="control-timePicker"
              format="HH:mm"
              minuteStep={30}
              placeholder={assignText.assignArea.workTime.placeholder_s}
              onChange={(value) => {
                if (value.valueOf() < vTimeEnd) {
                  AssignDispatch({
                    type: "setState",
                    payload: {
                      vTimeStart: moment(workDate)
                        .add(value?.hour(), "hour")
                        .add(value?.minute(), "minute")
                        .valueOf(),
                      vTimeEnd: vTimeEnd,
                    },
                  });
                } else {
                  AssignDispatch({
                    type: "setState",
                    payload: {
                      vTimeStart: vTimeEnd,
                      vTimeEnd: moment(workDate)
                        .add(value?.hour(), "hour")
                        .add(value?.minute(), "minute")
                        .valueOf(),
                    },
                  });
                }
              }}
              value={moment(vTimeStart)}
              locale={locale}
            />
            <span>-</span>
            <TimePicker
              inputReadOnly
              allowClear={false}
              showNow={false}
              className="control-timePicker"
              format="HH:mm"
              minuteStep={30}
              placeholder={assignText.assignArea.workTime.placeholder_e}
              onChange={(value) => {
                if (value.valueOf() < vTimeStart) {
                  AssignDispatch({
                    type: "setState",
                    payload: {
                      vTimeStart: moment(workDate)
                        .add(value?.hour(), "hour")
                        .add(value?.minute(), "minute")
                        .valueOf(),
                      vTimeEnd: vTimeStart,
                    },
                  });
                } else {
                  AssignDispatch({
                    type: "setState",
                    payload: {
                      vTimeStart: vTimeStart,
                      vTimeEnd: moment(workDate)
                        .add(value?.hour(), "hour")
                        .add(value?.minute(), "minute")
                        .valueOf(),
                    },
                  });
                }
              }}
              value={moment(vTimeEnd)}
              locale={locale}
            />
          </div>
        )}
        <div className="select-group">
          <h5>{assignText.assignArea.staff.title}</h5>
          <Select
            onFocus={(e) => {
              e.target.setAttribute("readOnly", "true");
            }}
            mode="multiple"
            allowClear
            placeholder={assignText.control.staff}
            value={selectGroups
              .filter((ele) => ele.id < 10000)
              .map((ele) => ele.id)}
            onChange={handleSelectChange}
            maxTagCount="responsive"
            className="control-people"
          >
            <Option
              value="all"
              label="all"
              key={9999999999}
              style={{ color: "#5e94fd", textAlign: "center" }}
            >
              {assignText.assignArea.selectAll}
            </Option>
            {children}
          </Select>
        </div>
      </div>
      <div className="button-wrapper">
        {pub === "pub" && (
          <button
            className="show-item-btn"
            onClick={() => {
              AssignDispatch({
                type: "setState",
                payload: {
                  showDeleted: !showDeleted,
                },
              });
              localStorage.setItem("showDeleted", (!showDeleted).toString());
            }}
          >
            {showDeleted ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            {assignText.pub.btn2[showDeleted ? "hide" : "show"]}
          </button>
        )}
        <Button
          text={
            pub === "pub"
              ? assignText.pub.btn1
              : btnDisabled
              ? assignText.unpub.btn4
              : assignText.unpub.btn1
          }
          loading={pub === "unpub" && btnDisabled}
          type="main"
          disabled={pub === "unpub" && (btnDisabled || items.length === 0)}
          onClick={() => {
            if (pub === "pub") {
              if (
                status === "new" ||
                (status === "edit" &&
                  selected.length > 0 &&
                  !(
                    assign.time.start === selectItem?.start_time &&
                    assign.time.end === selectItem?.end_time &&
                    assign.staff
                      .slice()
                      .sort()
                      .equals(
                        items
                          ?.filter((ele) => ele.wokId === selectItem?.wokId)
                          .map((ele) => ele.group)
                          .sort()
                      ) &&
                    assign.dptTyp === selectItem?.dptTyp.id &&
                    assign.dpt === selectItem?.dpt.id &&
                    assign.tmp === selectItem?.tmp?.id &&
                    assign.work === selectItem?.wok.id &&
                    assign.temp_task
                      .slice()
                      .sort()
                      .equals(selectItem?.temp_task.slice().sort())
                  ))
              ) {
                InitDispatch({
                  type: "setState",
                  payload: {
                    popup: {
                      ...popup,
                      open: true,
                      title: assignText.popup.unedit.title,
                      content: assignText.popup.unedit.content,
                      btnMain: assignText.popup.unedit.btnMain,
                      btnSub: assignText.popup.unedit.btnSub,
                      onClickMain: () => {
                        AssignDispatch({ type: "clearTimeline", payload: {} });
                        AssignDispatch({
                          type: "setState",
                          payload: {
                            store: [],
                            items: items
                              .filter((ele) => ele.id < 100000)
                              .map((item) =>
                                Object.assign({}, item, {
                                  className: "",
                                  selected: false,
                                  itemProps: {
                                    style: {
                                      background: item.color,
                                    },
                                  },
                                })
                              ),
                          },
                        });
                        InitDispatch({
                          type: "setState",
                          payload: {
                            popup: {
                              ...popup,
                              open: true,
                              title: assignText.popup.create.title,
                              content: assignText.popup.create.content,
                              btnSub: assignText.popup.create.btnSub,
                              btnMain: assignText.popup.create.btnMain,
                              onClickMain: () => {
                                InitDispatch({
                                  type: "closePopup",
                                  payload: {},
                                });
                                InitDispatch({
                                  type: "setState",
                                  payload: {
                                    init: false,
                                  },
                                });
                                history.push("/assign/unpub");
                              },
                              onClickSub: () =>
                                InitDispatch({
                                  type: "closePopup",
                                  payload: {},
                                }),
                            },
                          },
                        });
                      },
                      onClickSub: () =>
                        InitDispatch({ type: "closePopup", payload: {} }),
                    },
                  },
                });
              } else {
                InitDispatch({
                  type: "setState",
                  payload: {
                    popup: {
                      ...popup,
                      open: true,
                      title: assignText.popup.create.title,
                      content: assignText.popup.create.content,
                      btnSub: assignText.popup.create.btnSub,
                      btnMain: assignText.popup.create.btnMain,
                      onClickMain: () => {
                        InitDispatch({ type: "closePopup", payload: {} });
                        InitDispatch({
                          type: "setState",
                          payload: { init: false },
                        });
                        history.push("/assign/unpub");
                      },
                      onClickSub: () =>
                        InitDispatch({ type: "closePopup", payload: {} }),
                    },
                  },
                });
              }
            } else {
              setShow(!show);
            }
          }}
        />
        <div
          className={`unpub-dropdown ${show && pub === "unpub" ? "show" : ""}`}
        >
          <p
            onClick={() => {
              let set = new Set<string>();
              for (let item of items) {
                for (let store of item.storeArr) {
                  if (store.sysAct === "Y") {
                    set.add(store.name);
                  }
                }
              }
              let arr = Array.from(set);
              setShow(false);
              // 將異動的任務刪除
              if (arr.length) {
                InitDispatch({
                  type: "setState",
                  payload: {
                    popup: {
                      ...popup,
                      open: true,
                      title: assignText.popup.delAll.title,
                      content: `${
                        assignText.popup.delAll.content
                      }<br/><br/>${arr
                        .map((ele) => `- ${ele} 入住`)
                        .join("<br/>")}`,
                      btnMain: assignText.popup.delAll.btnMain,
                      onClickMain: () => {
                        // TODO: fetch API & clear del/refresh all items
                        let data = items.map((item) => {
                          return {
                            AgWkId: item.wokId,
                            RvStId: item.storeArr
                              .filter((store) => store.sysAct === "N")
                              .map((store) => store.id),
                          };
                        });

                        console.table(data);

                        fetch(
                          `${
                            process.env.NODE_ENV === "development"
                              ? "https://cors-anywhere.herokuapp.com/"
                              : ""
                          }${process.env.REACT_APP_URL}delpak`,
                          {
                            headers: new Headers({
                              "Content-Type": "application/json",
                            }),
                            method: "POST",
                            body: JSON.stringify(data),
                          }
                        )
                          .then((res) => res.json())
                          .then((res) => {
                            console.log(res);

                            if (res.status === "success") {
                              alert.success(
                                assignText.popup.delAll.alert.replace(
                                  "%項目%",
                                  arr.join(", ")
                                )
                              );

                              AssignDispatch({
                                type: "setState",
                                payload: {
                                  items: items.map((item) =>
                                    Object.assign({}, item, {
                                      storeArr: item.storeArr.filter(
                                        (ele) => ele.sysAct === "N"
                                      ),
                                    })
                                  ),
                                },
                              });
                              InitDispatch({ type: "closePopup", payload: {} });
                            } else {
                            }
                          });
                      },
                      onClickSub: () =>
                        InitDispatch({ type: "closePopup", payload: {} }),
                    },
                  },
                });
                return;
              }
              // 指派有異動需跳出popup
              if (
                status === "new" ||
                (status === "edit" &&
                  selected.length > 0 &&
                  !(
                    assign.time.start === selectItem?.start_time &&
                    assign.time.end === selectItem?.end_time &&
                    assign.staff
                      .slice()
                      .sort()
                      .equals(
                        items
                          ?.filter((ele) => ele.wokId === selectItem?.wokId)
                          .map((ele) => ele.group)
                          .sort()
                      ) &&
                    assign.dptTyp === selectItem?.dptTyp.id &&
                    assign.dpt === selectItem?.dpt.id &&
                    assign.tmp === selectItem?.tmp?.id &&
                    assign.work === selectItem?.wok.id &&
                    ((pub === "unpub" &&
                      (assign.store
                        .slice()
                        .sort()
                        .equals(
                          selectItem?.storeArr?.map((ele) => ele.id).sort()
                        ) ||
                        assign.store.length > 0)) ||
                      pub === "pub")
                  ))
              ) {
                InitDispatch({
                  type: "setState",
                  payload: {
                    popup: {
                      ...popup,
                      open: true,
                      title: assignText.popup.unedit.title,
                      content: assignText.popup.unedit.content,
                      btnMain: assignText.popup.unedit.btnMain,
                      btnSub: assignText.popup.unedit.btnSub,
                      onClickMain: () => {
                        setBtnDisabled(true);
                        InitDispatch({
                          type: "setState",
                          payload: {
                            popup: {
                              ...popup,
                              open: false,
                            },
                          },
                        });
                        let data = {
                          AgWkId: Array.from(
                            new Set(items.map((item) => item.wokId))
                          ),
                          iMagId: account.id,
                          AgDate: workDate.format("YYYY-MM-DD"),
                        };
                        let timer = new Promise((resolve) => {
                          setTimeout(resolve, 30000, "timer");
                        });
                        let release = fetch(
                          `${
                            process.env.NODE_ENV === "development"
                              ? "https://cors-anywhere.herokuapp.com/"
                              : ""
                          }${process.env.REACT_APP_URL}reltask`,
                          {
                            headers: new Headers({
                              "Content-Type": "application/json",
                            }),
                            method: "POST",
                            body: JSON.stringify(data),
                          }
                        )
                          .then((res) => res.json())
                          .then((json) => {
                            console.log(json, workDate);

                            if (json.status === "success") {
                              alert.success(
                                assignText.alert.pub.success.replace(
                                  "%作業日%",
                                  workDate.format("YYYY/MM/DD")
                                )
                              );

                              AssignDispatch({
                                type: "setState",
                                payload: {
                                  items: [],
                                  unpDat: unpDat.filter(
                                    (date) =>
                                      date !== workDate.format("YYYY-MM-DD")
                                  ),
                                },
                              });
                              AssignDispatch({
                                type: "clearTimeline",
                                payload: {},
                              });
                              setTimeout(() => {
                                history.push("/assign/pub");
                              }, 3000);
                            } else {
                              alert.error(
                                assignText.alert.pub.fail
                                  .replace(
                                    "%作業日%",
                                    workDate.format("YYYY/MM/DD")
                                  )
                                  .replace("%錯誤代碼%", "xxx")
                              );
                            }
                          })
                          .catch((e) => console.log(e))
                          .finally(() => setBtnDisabled(false));

                        Promise.race([timer, release]).then((name) => {
                          if (name === "timer") {
                            alert.error(errorText["publish_expire"]);
                          }
                          InitDispatch({ type: "closePopup", payload: {} });
                        });
                      },
                      onClickSub: () =>
                        InitDispatch({ type: "closePopup", payload: {} }),
                    },
                  },
                });
              } else {
                setBtnDisabled(true);
                let data = {
                  AgWkId: Array.from(new Set(items.map((item) => item.wokId))),
                  iMagId: account.id,
                  AgDate: workDate.format("YYYY-MM-DD"),
                };
                let timer = new Promise((resolve) =>
                  setTimeout(resolve, 30000, "timer")
                );
                let release = fetch(
                  `${
                    process.env.NODE_ENV === "development"
                      ? "https://cors-anywhere.herokuapp.com/"
                      : ""
                  }${process.env.REACT_APP_URL}reltask`,
                  {
                    headers: new Headers({
                      "Content-Type": "application/json",
                    }),
                    method: "POST",
                    body: JSON.stringify(data),
                  }
                )
                  .then((res) => res.json())
                  .then((json) => {
                    console.log(json, workDate);

                    if (json.status === "success") {
                      alert.success(
                        assignText.alert.pub.success.replace(
                          "%作業日%",
                          workDate.format("YYYY/MM/DD")
                        )
                      );
                      AssignDispatch({
                        type: "setState",
                        payload: {
                          items: [],
                          unpDat: unpDat.filter(
                            (date) => date !== workDate.format("YYYY-MM-DD")
                          ),
                        },
                      });
                      AssignDispatch({ type: "clearTimeline", payload: {} });
                      setTimeout(() => {
                        history.push("/assign/pub");
                      }, 3000);
                    } else {
                      alert.error(
                        assignText.alert.pub.fail
                          .replace("%作業日%", workDate.format("YYYY/MM/DD"))
                          .replace("%錯誤代碼%", "xxx")
                      );
                    }
                  })
                  .catch((e) => console.log(e))
                  .finally(() => setBtnDisabled(false));

                Promise.race([timer, release]).then((name) => {
                  if (name === "timer") {
                    alert.error(errorText["publish_expire"]);
                  }
                  InitDispatch({ type: "closePopup", payload: {} });
                });
              }
            }}
          >
            {assignText.unpub.btn2}
          </p>
          <p
            onClick={() => {
              let set = new Set<string>();
              for (let item of items) {
                for (let store of item.storeArr) {
                  if (store.sysAct === "Y") {
                    set.add(store.alt);
                  }
                }
              }
              let arr = Array.from(set);
              setShow(false);
              if (arr.length) {
                InitDispatch({
                  type: "setState",
                  payload: {
                    popup: {
                      ...popup,
                      open: true,
                      title: assignText.popup.delAll.title,
                      content: `${
                        assignText.popup.delAll.content
                      }<br/><br/>${arr
                        .map((ele) => `- ${ele} 入住`)
                        .join("<br/>")}`,
                      btnMain: assignText.popup.delAll.btnMain,
                      onClickMain: () => {
                        // TODO: fetch API & clear del/refresh all items
                        let data = items.map((item) => {
                          return {
                            AgWkId: item.wokId,
                            RvStId: item.storeArr.map((store) => store.id),
                          };
                        });

                        console.table(data);

                        fetch(
                          `${
                            process.env.NODE_ENV === "development"
                              ? "https://cors-anywhere.herokuapp.com/"
                              : ""
                          }${process.env.REACT_APP_URL}delpak`,
                          {
                            headers: new Headers({
                              "Content-Type": "application/json",
                            }),
                            method: "POST",
                            body: JSON.stringify(data),
                          }
                        )
                          .then((res) => res.json())
                          .then((res) => {
                            console.log(res);

                            if (res.status === "success") {
                              alert.success(
                                assignText.popup.delAll.alert.replace(
                                  "%項目%",
                                  arr.join(", ")
                                )
                              );

                              AssignDispatch({
                                type: "setState",
                                payload: {
                                  items: items.map((item) =>
                                    Object.assign({}, item, {
                                      storeArr: item.storeArr.filter(
                                        (ele) => ele.sysAct === "N"
                                      ),
                                    })
                                  ),
                                },
                              });
                              InitDispatch({ type: "closePopup", payload: {} });
                            } else {
                            }
                          });
                      },
                      onClickSub: () =>
                        InitDispatch({ type: "closePopup", payload: {} }),
                    },
                  },
                });
                return;
              }
              if (
                status === "new" ||
                (status === "edit" &&
                  selected.length > 0 &&
                  !(
                    assign.time.start === selectItem?.start_time &&
                    assign.time.end === selectItem?.end_time &&
                    assign.staff
                      .slice()
                      .sort()
                      .equals(
                        items
                          ?.filter((ele) => ele.wokId === selectItem?.wokId)
                          .map((ele) => ele.group)
                          .sort()
                      ) &&
                    assign.dptTyp === selectItem?.dptTyp.id &&
                    assign.dpt === selectItem?.dpt.id &&
                    assign.tmp === selectItem?.tmp?.id &&
                    assign.work === selectItem?.wok.id &&
                    ((pub === "unpub" &&
                      (assign.store
                        .slice()
                        .sort()
                        .equals(
                          selectItem?.storeArr?.map((ele) => ele.id).sort()
                        ) ||
                        assign.store.length > 0)) ||
                      pub === "pub")
                  ))
              ) {
                InitDispatch({
                  type: "setState",
                  payload: {
                    popup: {
                      ...popup,
                      open: true,
                      title: assignText.popup.unedit.title,
                      content: assignText.popup.unedit.content,
                      btnMain: assignText.popup.unedit.btnMain,
                      btnSub: assignText.popup.unedit.btnSub,
                      onClickMain: () => {
                        setBtnDisabled(true);
                        let data = {
                          AgWkId: Array.from(
                            new Set(items.map((item) => item.wokId))
                          ),
                          iMagId: account.id,
                          AgDate: workDate.format("YYYY-MM-DD"),
                        };
                        let timer = new Promise((resolve) => {
                          setTimeout(resolve, 30000, "timer");
                        });
                        let release = fetch(
                          `${
                            process.env.NODE_ENV === "development"
                              ? "https://cors-anywhere.herokuapp.com/"
                              : ""
                          }${process.env.REACT_APP_URL}reltask`,
                          {
                            headers: new Headers({
                              "Content-Type": "application/json",
                            }),
                            method: "POST",
                            body: JSON.stringify(data),
                          }
                        )
                          .then((res) => res.json())
                          .then((json) => {
                            console.log(json, workDate);

                            if (json.status === "success") {
                              alert.success(
                                assignText.alert.pub.success.replace(
                                  "%作業日%",
                                  workDate.format("YYYY/MM/DD")
                                )
                              );
                              AssignDispatch({
                                type: "setState",
                                payload: {
                                  items: [],
                                  unpDat: unpDat.filter(
                                    (date) =>
                                      date !== workDate.format("YYYY-MM-DD")
                                  ),
                                },
                              });
                              AssignDispatch({
                                type: "clearTimeline",
                                payload: {},
                              });
                              setShow(false);
                            } else {
                              alert.error(
                                assignText.alert.pub.fail
                                  .replace(
                                    "%作業日%",
                                    workDate.format("YYYY/MM/DD")
                                  )
                                  .replace("%錯誤代碼%", "xxx")
                              );
                            }
                          })
                          .catch((e) => console.log(e))
                          .finally(() => setBtnDisabled(false));

                        Promise.race([timer, release]).then((name) => {
                          if (name === "timer") {
                            alert.error(errorText["publish_expire"]);
                          }
                          InitDispatch({ type: "closePopup", payload: {} });
                        });
                      },
                      onClickSub: () =>
                        InitDispatch({ type: "closePopup", payload: {} }),
                    },
                  },
                });
              } else {
                setBtnDisabled(true);
                let data = {
                  AgWkId: Array.from(new Set(items.map((item) => item.wokId))),
                  iMagId: account.id,
                  AgDate: workDate.format("YYYY-MM-DD"),
                };
                let timer = new Promise((resolve) => {
                  setTimeout(resolve, 30000, "timer");
                });
                let release = fetch(
                  `${
                    process.env.NODE_ENV === "development"
                      ? "https://cors-anywhere.herokuapp.com/"
                      : ""
                  }${process.env.REACT_APP_URL}reltask`,
                  {
                    headers: new Headers({
                      "Content-Type": "application/json",
                    }),
                    method: "POST",
                    body: JSON.stringify(data),
                  }
                )
                  .then((res) => res.json())
                  .then((json) => {
                    console.log(json, workDate);

                    if (json.status === "success") {
                      alert.success(
                        assignText.alert.pub.success.replace(
                          "%作業日%",
                          workDate.format("YYYY/MM/DD")
                        )
                      );
                      AssignDispatch({
                        type: "setState",
                        payload: {
                          items: [],
                          unpDat: unpDat.filter(
                            (date) => date !== workDate.format("YYYY-MM-DD")
                          ),
                        },
                      });
                      AssignDispatch({ type: "clearTimeline", payload: {} });
                      setShow(false);
                    } else {
                      alert.error(
                        assignText.alert.pub.fail
                          .replace("%作業日%", workDate.format("YYYY/MM/DD"))
                          .replace("%錯誤代碼%", "xxx")
                      );
                    }
                  })
                  .catch((e) => console.log(e))
                  .finally(() => setBtnDisabled(false));

                Promise.race([timer, release]).then((name) => {
                  if (name === "timer") {
                    alert.error(errorText["publish_expire"]);
                  }
                  InitDispatch({ type: "closePopup", payload: {} });
                });
              }
            }}
          >
            {assignText.unpub.btn3}
          </p>
        </div>
      </div>
    </div>
  );
};

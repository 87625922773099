import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { AssignArea } from "../components/assign/AssignArea";
import { Control } from "../components/assign/Control";
import { TimelineArea } from "../components/assign/TimelineArea";
import { Title } from "../components/ui/Title";
import "moment/locale/zh-tw";
import { useLocation, Prompt, useHistory, useParams } from "react-router-dom";
import { Location } from "history";
import { PlusOutlined } from "@ant-design/icons";
import { staticText } from "../utils/staticText";
import { PubAssignArea } from "../components/assign_pub/PubAssignArea";
import { PubTimelineArea } from "../components/assign_pub/PubTimelineArea";
import { InitContext } from "../contexts/init";
import { AssignContext } from "../contexts/assign";
import moment from "moment";
import { ReactComponent as EditIcon } from "../images/assign/edit.svg";
import { useAlert } from "react-alert";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

/** 作業指派 */
export const Assign = () => {
  const { data: InitData, dispatch: InitDispatch } = useContext(InitContext);
  const { init, popup, account } = InitData;
  const { data: AssignData, dispatch: AssignDispatch } =
    useContext(AssignContext);
  const {
    content,
    groups,
    selectGroups,
    items,
    workDate,
    selected,
    vTimeStart,
    creating,
    status,
    assign,
    selectItem,
    showAssign,
    wok,
    delPopup,
    dptTyp,
    isReload,
    canCallApi,
  } = AssignData;

  let { pub } = useParams<{ pub: string }>();
  const { assignText } = staticText;
  let location = useLocation();
  const [confirm, setConfirm] = useState(false);
  const [locat, setLocat] = useState<Location | null>(null);
  let history = useHistory();
  const [width, height] = useWindowSize();
  let alert = useAlert();

  useEffect(() => {
    //未發布作業指派init 全部人員搜尋+未發布指派日期
    fetch(
      `${
        process.env.NODE_ENV === "development"
          ? "https://cors-anywhere.herokuapp.com/"
          : ""
      }${process.env.REACT_APP_URL}api/NAinit`
    )
      .then((res) => res.json())
      .then((json) => {
        let group = json.User.map((v) => {
          return { id: v.Id, title: v.UserName };
        });
        AssignDispatch({
          type: "setState",
          payload: {
            unpDat: json.Date,
            groups: group,
            selectGroups: group,
          },
        });
      });

    // set timeline visible time range
    let end: number | moment.Moment | Date;
    if (window.innerWidth < 500) {
      end = moment().startOf("day").add(11, "hour");
    } else if (window.innerWidth > 500 && window.innerWidth < 1000) {
      end = moment().startOf("day").add(13, "hour");
    }
    AssignDispatch({
      type: "setState",
      payload: {
        vTimeEnd: end,
        showDeleted: localStorage.getItem('showDeleted') === "true" ? true : false,
      },
    });
  }, []);

  useEffect(() => {
    let end: number | moment.Moment | Date;
    if (width < 500) {
      end = moment(vTimeStart).add(2, "hour");
    } else if (width > 500 && width < 1200) {
      end = moment(vTimeStart).add(4, "hour");
    } else {
      end = moment(vTimeStart).add(9, "hour");
    }
    AssignDispatch({
      type: "setState",
      payload: { vTimeEnd: end },
    });
  }, [width, height]);

  useEffect(() => {
    if (pub === "unpub") {
      //部門類型+範本類型
      fetch(
        `${
          process.env.NODE_ENV === "development"
            ? "https://cors-anywhere.herokuapp.com/"
            : ""
        }${process.env.REACT_APP_URL}api/dpttyp`
      )
        .then((res) => res.json())
        .then((json) => {
          AssignDispatch({
            type: "setState",
            payload: {
              dptTyp: json.map((v) => {
                return {
                  id: v.DpTpId,
                  name: v.TypName,
                  dpt: v.dpt.map((v) => {
                    return { id: v.DpIfId, name: v.DpIfName, dsply: v.Dsply };
                  }),
                };
              }),
            },
          });
        });
    } else {
      // 本機開發fetch有CORS問題，所以做例外處理
      // fetch(`${process.env.NODE_ENV === 'development' ? 'https://cors-anywhere.herokuapp.com/' : ''}${process.env.REACT_APP_URL}typeinfo`)
      //   .then((res) => {
      //     if (!res.ok) {
      //       throw new Error(res.statusText);
      //     } else {
      //       return res.json();
      //     }
      //   })
      //   .then((json) => {
      //     AssignDispatch({
      //       type: "setState",
      //       payload: {
      //         dptTyp: json.map((v) => {
      //           return {
      //             id: v.DpTpId,
      //             name: v.TypName,
      //             dpt: v.dpt.map((v) => {
      //               return { id: v.DpIfId, name: v.TpIfName, dsply: v.Dsply };
      //             }),
      //           };
      //         }),
      //       },
      //     });
      //   })
      //   .catch(() => {});
      // TODO: 臨時作業的範本和作業
      // AssignDispatch({
      //   type: "setState",
      //   payload: {
      //     tempWok: temp_wok,
      //     tmp: tmp_temp,
      //   },
      // });
    }

    setConfirm(false);
    setLocat(null);
    AssignDispatch({
      type: "setState",
      payload: {
        status: "unselect",
        items: [],
        selected: [],
        selectItem: undefined,
      },
    });
  }, [location]);

  useEffect(() => {
    if (confirm) {
      history.push(
        locat?.pathname ? { pathname: locat.pathname, state: locat.state } : ""
      );
    }
  }, [confirm]);

  useEffect(() => {
    if (init) {
      AssignDispatch({
        type: "setState",
        payload: {
          selectGroups: groups,
        },
      });
    }
  }, [groups]);

  useEffect(() => {
    let dsply = dptTyp
      .find((ele) => ele.id === assign.dptTyp)
      ?.dpt.find((ele) => ele.id === assign.dpt)?.dsply;

    if (pub === "unpub") {
      console.log(status, dsply);
      switch (status) {
        case "edit":
          if (
            (assign.date.start && assign.date.end && dsply === "NoGp")
          ) {
            AssignDispatch({
              type: "setState",
              payload: {
                canCallApi: true,
              },
            });
          }
          break;
        case "new":
          if (dsply === "NoGp") {
            AssignDispatch({
              type: "setState",
              payload: {
                canCallApi: true,
              },
            });
          }
          break;
        default:
          AssignDispatch({
            type: "setState",
            payload: {
              canCallApi: false,
            },
          });
          break;
      }
    }
  }, [status, assign.date]);

  useEffect(() => {
    // timeline 至少顯示8個指派人員
    if (selectGroups.length < 8) {
      AssignDispatch({
        type: "setState",
        payload: {
          selectGroups: [
            ...selectGroups,
            { id: selectGroups.length + 10000, title: "" },
          ],
        },
      });
    }
  }, [selectGroups]);

  useEffect(() => {
    AssignDispatch({
      type: "setState",
      payload: {
        content: { title: pub === "pub" ? "已發佈作業" : "未發佈作業" },
      },
    });
  }, [pub]);

  useEffect(() => {
    if (!creating.length) {
      AssignDispatch({
        type: "setState",
        payload: {
          creatingTime: { start: 0, end: 0 },
        },
      });
      if (assign.id) {
        AssignDispatch({
          type: "setState",
          payload: {
            status: "edit",
          },
        });
      } else {
        AssignDispatch({
          type: "setState",
          payload: {
            status: "unselect",
          },
        });
        AssignDispatch({ type: "clearAssign", payload: {} });
      }
    } else {
      AssignDispatch({
        type: "setState",
        payload: {
          status: "new",
        },
      });
    }
  }, [creating]);

  useEffect(() => {
    if (init) {
      AssignDispatch({
        type: "setState",
        payload: {
          selectGroups: groups,
          workDate: moment().startOf("day"),
          workDateEnd: moment().startOf("day").add(24, "hour"),
        },
      });
    }
    // 設定Timeline visible 區間
    let end: number | moment.Moment | Date;
    if (window.innerWidth < 500) {
      end = moment(workDate).startOf("day").add(11, "hour");
    } else if (window.innerWidth > 500 && window.innerWidth < 1200) {
      end = moment(workDate).startOf("day").add(13, "hour");
    } else {
      end = moment(workDate).startOf("day").add(18, "hour");
    }
    AssignDispatch({
      type: "setState",
      payload: {
        vTimeStart: moment(workDate).startOf("day").add(9, "hour"),
        vTimeEnd: end,
        selected: [],
        creating: [],
        creatingGroups: [],
        creatingTime: { start: 0, end: 0 },
        status: "unselect",
        tagColor: "",
        chose: false,
      },
    });
    AssignDispatch({ type: "clearAssign", payload: {} });

    // 切換頁面的時候要更新資料
    if (workDate) {
      AssignDispatch({
        type: "setState",
        payload: {
          isReload: true,
        },
      });
    }
  }, [location]);

  useEffect(() => {
    if (workDate) {
      AssignDispatch({
        type: "setState",
        payload: {
          isReload: true,
        },
      });
      AssignDispatch({ type: "clearAssign", payload: {} });
    }
  }, [workDate]);

  useEffect(() => {
    // 有更新就刷新timeline資料
    if (isReload) {
      fetch(
        `${process.env.REACT_APP_URL}api/${
          pub === "unpub" ? "asgwork" : "asgtask"
        }?AgDate=${workDate.format("YYYY-MM-DD")}`
      )
        .then((res) => res.json())
        .then((json) => {
          AssignDispatch({
            type: "setState",
            payload: {
              status: "unselect",
              items: json.map((v, i) => {
                return {
                  id: i + 1,
                  group: v.AgEmpId,
                  start_time: parseInt(moment(v.SDT).format("X")) * 1000,
                  end_time: parseInt(moment(v.EDT).format("X")) * 1000,
                  selected: false,
                  itemProps: {
                    style: {
                      background: v.Color ? v.Color.replace("#", "") : "000000", // ! 預設顏色是黑的?
                    },
                  },
                  color: v.Color ? v.Color.replace("#", "") : "000000",
                  dptTyp: { id: v.DpTpId, name: v.DpTpName },
                  dpt: {
                    id: v.DpIfId,
                    name: v.DpIfName,
                    dsply: v.DpIfDsply,
                  },
                  tmp: v.TpIfId ? { id: v.TpIfId, name: v.TpIfName } : null,
                  wok: { id: v.TpWkId, name: v.TpWkName, dsply: v.TpWkDsply },
                  wokId: v.AgWkId,
                  storeArr: v.RvStId.map((v) => {
                    return {
                      id: v.Id,
                      name: v.Name,
                      alt: v.Alt,
                      sysAct: v.SysAct,
                    };
                  }),
                  temp_task: v.todo ? v.todo : [""], // TODO: 有臨時作業的時候加入
                  canMove: false,
                  canResize: false,
                  canChangeGroup: false,
                  deleted: v?.Active,
                  updateAt: moment(v.updated_at),
                };
              }),
              isReload: false,
            },
          });
          AssignDispatch({ type: "clearAssign", payload: {} });
        });
    }
  }, [isReload]);

  useEffect(() => {
    let dsply = dptTyp
      .find((ele) => ele.id === assign.dptTyp)
      ?.dpt.find((ele) => ele.id === assign.dpt)?.dsply;

    if (assign.dpt && canCallApi) {
      AssignDispatch({
        type: "setState",
        payload: {
          tmp: [],
        },
      });
      
      let url = new URL(
        `${
          process.env.NODE_ENV === "development"
            ? "https://cors-anywhere.herokuapp.com/"
            : ""
        }${process.env.REACT_APP_URL}api/tmpInfo`
      );
      let params = {
        DpIfId: assign.dpt,
        StaDate: assign.date.start,
        EndDate: assign.date.end,
        Dsply: dsply,
      };
      url.search = new URLSearchParams(params as any).toString();
      fetch(url.toString())
        .then((res) => res.json())
        .then((json) => {
          AssignDispatch({
            type: "setState",
            payload: {
              tmp: json.map((v) => {
                return {
                  id: v.Id,
                  name: v.Name,
                  time: v.Time,
                };
              }),
            },
          });
        });
    } else if (
      // 未來 餐廳視角
      dsply === "GpTi" &&
      assign.dptTyp &&
      assign.date.start &&
      assign.date.end
    ) {
      let url = new URL(
        `${
          process.env.NODE_ENV === "development"
            ? "https://cors-anywhere.herokuapp.com/"
            : ""
        }${process.env.REACT_APP_URL}api/tmpwork`
      );
      let params = {
        DpIfId: assign.dpt,
        StaDate: assign.date.start,
        EndDate: assign.date.end,
        TpIfId: assign.tmp,
        DpDsply: dsply,
      };
      url.search = new URLSearchParams(params as any).toString();
      fetch(url.toString())
        .then((res) => res.json())
        .then((json) => {
          AssignDispatch({
            type: "setState",
            payload: {
              wok: json.map((v) => {
                return {
                  id: v.Id,
                  name: v.Name,
                  sCol: v.Color.replace("#", ""),
                  dsply: v.Dsply,
                  html: v.Html,
                };
              }),
            },
          });
        });
    }
  }, [assign.dpt, assign.date, canCallApi]);

  useEffect(() => {
    AssignDispatch({
      type: "setState",
      payload: {
        wok: [],
      },
    });

    let dsply = dptTyp
      .find((ele) => ele.id === assign.dptTyp)
      ?.dpt.find((ele) => ele.id === assign.dpt)?.dsply;

    if (assign.tmp && canCallApi) {
      let url = new URL(
        `${
          process.env.NODE_ENV === "development"
            ? "https://cors-anywhere.herokuapp.com/"
            : ""
        }${process.env.REACT_APP_URL}api/tmpwork`
      );
      let params = {
        DpIfId: assign.dpt,
        StaDate: assign.date.start,
        EndDate: assign.date.end,
        TpIfId: assign.tmp,
        DpDsply: dsply,
      };
      url.search = new URLSearchParams(params as any).toString();
      fetch(url.toString())
        .then((res) => res.json())
        .then((json) => {
          AssignDispatch({
            type: "setState",
            payload: {
              wok: json.map((v) => {
                return {
                  id: v.Id,
                  name: v.Name,
                  sCol: v.Color.replace("#", ""),
                  dsply: v.Dsply,
                  html: v.Html,
                };
              }),
            },
          });
        });
    }
  }, [assign.tmp]);

  useEffect(() => {
    AssignDispatch({
      type: "setState",
      payload: {
        store: [],
      },
    });

    let dsply = dptTyp
      .find((ele) => ele.id === assign.dptTyp)
      ?.dpt.find((ele) => ele.id === assign.dpt)?.dsply;

    if (assign.work && canCallApi) {
      let url = new URL(
        `${
          process.env.NODE_ENV === "development"
            ? "https://cors-anywhere.herokuapp.com/"
            : ""
        }${process.env.REACT_APP_URL}api/workit`
      );
      let params = {
        Dsply: wok.find((ele) => ele.id === assign.work)?.dsply,
        StaDate: assign.date.start,
        EndDate: assign.date.end,
        TpWkId: assign.work,
      };
      url.search = new URLSearchParams(params as any).toString();
      fetch(url.toString())
        .then((res) => res.json())
        .then((json) => {
          AssignDispatch({
            type: "setState",
            payload: {
              store: json.map((v) => {
                return {
                  id: v.Id,
                  name: v.Name,
                  alt: v.Alt,
                  sysAct: "N",
                };
              }),
            },
          });
        })
        .catch((e) => alert.error(e.message));
    }
  }, [assign.work]);

  useEffect(() => {
    // 團單異動
    if (delPopup.status === "check") {
      InitDispatch({
        type: "setState",
        payload: {
          popup: {
            ...popup,
            open: true,
            title: assignText.popup.del.title,
            content: `${assignText.popup.delAll.content}<br/><br/>${delPopup.text}`,
            btnMain: assignText.popup.del.btnMain,
            onClickMain: () => {
              // fetch API & clear del

              let data = {
                AgDate: workDate.format("YYYY-MM-DD"),
              };

              console.table(data);

              fetch(
                `${
                  process.env.NODE_ENV === "development"
                    ? "https://cors-anywhere.herokuapp.com/"
                    : ""
                }${process.env.REACT_APP_URL}delpak`,
                {
                  headers: new Headers({
                    "Content-Type": "application/json",
                  }),
                  method: "POST",
                  body: JSON.stringify(data),
                }
              )
                .then((res) => res.json())
                .then((res) => {
                  console.log(res);

                  if (res.status === "success") {
                    alert.success(
                      assignText.popup.delAll.alert.replace(
                        "%項目%",
                        delPopup.text
                      )
                    );

                    AssignDispatch({
                      type: "setState",
                      payload: {
                        items: items.map((item) =>
                          Object.assign({}, item, {
                            storeArr: item.storeArr.filter(
                              (ele) => ele.sysAct === "N"
                            ),
                          })
                        ),
                        isReload: true,
                      },
                    });
                    InitDispatch({ type: "closePopup", payload: {} });
                  } else {
                    alert.error(
                      assignText.popup.del.fail
                        .replace("%項目%", delPopup.text)
                        .replace("%錯誤代碼%", res.code)
                    );
                  }
                });
            },
            onClickSub: () => InitDispatch({ type: "closePopup", payload: {} }),
          },
        },
      });

      AssignDispatch({
        type: "setState",
        payload: {
          delPopup: { id: 0, status: "", text: "", func: () => {} },
        },
      });
    }
  }, [delPopup.status]);

  useEffect(() => {
    // AssignDispatch({
    //   type: "setState",
    //   payload: {
    //     selectItem: items.find((ele) => ele.wokId === assign.id),
    //   },
    // });
  }, [assign]);

  const handleNavigate = (locat: Location<unknown>) => {
    setLocat(locat);

    if (!confirm) {
      InitDispatch({
        type: "setState",
        payload: {
          popup: {
            ...popup,
            open: true,
            title: assignText.popup.leave.title,
            content: assignText.popup.leave.content,
            btnMain: assignText.popup.leave.btnMain,
            btnSub: assignText.popup.leave.btnSub,
            onClickMain: () => {
              setConfirm(true);
              InitDispatch({ type: "closePopup", payload: {} });
            },
            onClickSub: () => {
              InitDispatch({ type: "closePopup", payload: {} });
            },
          },
        },
      });
      return false;
    }
    return true;
  };

  return (
    <div className={`assign-wrapper ${showAssign ? "show" : ""}`}>
      <Title text={content.title} />
      <Control />
      <div className="separator"></div>
      <div style={{ display: "flex" }}>
        {/* 已發佈/未發佈 顯示不同元件 */}
        {pub === "unpub" ? <AssignArea /> : <PubAssignArea />}
        {pub === "unpub" ? <TimelineArea /> : <PubTimelineArea />}

        {/* 離開頁面時的檢查 */}
        <Prompt
          when={
            status === "new" ||
            (status === "edit" &&
              selected.length > 0 &&
              !(
                assign.time.start === selectItem?.start_time &&
                assign.time.end === selectItem?.end_time &&
                assign.staff
                  .slice()
                  .sort()
                  .equals(
                    items
                      ?.filter((ele) => ele.wokId === selectItem?.wokId)
                      .map((ele) => ele.group)
                      .sort()
                  ) &&
                assign.dptTyp === selectItem?.dptTyp.id &&
                assign.dpt === selectItem?.dpt.id &&
                assign.tmp === selectItem?.tmp?.id &&
                assign.work === selectItem?.wok.id &&
                assign.temp_task
                  .slice()
                  .filter((task) => task !== "")
                  .sort()
                  .equals(selectItem?.temp_task?.slice().filter((task) => task !== "").sort()) &&
                ((pub === "unpub" &&
                  (assign.store
                    .slice()
                    .sort()
                    .equals(
                      selectItem?.storeArr?.map((ele) => ele.id).sort()
                    ) ||
                    assign.store.length > 0)) ||
                  pub === "pub")
              ))
          }
          message={handleNavigate}
        />
      </div>
      <div
        className={`add-btn ${status === "new" ? "show" : ""}`}
        onClick={() => {
          AssignDispatch({
            type: "setState",
            payload: {
              selectItem: items.find((item) => selected.includes(item.id)),
              showAssign: true,
            },
          });
        }}
      >
        <PlusOutlined className="icon-plus" />
      </div>
      <div
        className={`edit-btn ${status === "edit" ? "show" : ""}`}
        onClick={() => {
          AssignDispatch({
            type: "setState",
            payload: {
              selectItem: items.find((item) => selected.includes(item.id)),
              showAssign: true,
            },
          });
        }}
      >
        <div className="icon-plus">
          <EditIcon />
        </div>
      </div>
      <div
        className="block"
        onClick={() => {
          AssignDispatch({
            type: "setState",
            payload: {
              status: "unselect",
              selectItem: undefined,
              items: items.map((item) => {
                return item.wokId === assign.id
                  ? {
                      ...item,
                      selected: false,
                      className: "",
                      itemProps: {
                        style: {
                          ...item.itemProps.style,
                          boxShadow: "",
                        },
                      },
                    }
                  : item;
              }),
              chose: false,
            },
          });
          AssignDispatch({ type: "clearAssign", payload: {} });
        }}
      ></div>
    </div>
  );
};

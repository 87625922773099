import moment from "moment";
import { createContext, useReducer } from "react";
import { Assign, DptTyp, Group, Item, Store, Tmp, Wok } from "../utils/types";

export interface AssignData {
  content?: {
    title: string;
  };
  groups?: Group;
  selectGroups?: {
    id: number;
    title: string;
  }[];
  items?: Item[];
  workDate?: moment.Moment | undefined;
  workDateEnd?: moment.Moment | undefined;
  selected?: number[];
  vTimeStart?: number | moment.Moment | Date | undefined;
  vTimeEnd?: number | moment.Moment | Date | undefined;
  creating?: number[];
  creatingGroups?: number[];
  creatingTime?: {
    start: number;
    end: number;
  };
  status?: "unselect" | "new" | "edit";
  tagColor?: string;
  assign?: Assign;
  store?: Store | undefined;
  selectItem?: Item | undefined;
  showAssign?: boolean;
  tmp?: Tmp;
  wok?: Wok;
  delPopup?: {
    id: number;
    status: string;
    text: string;
    func: () => void;
  };
  unpDat?: string[];
  tempWok?: Wok;
  dptTyp?: DptTyp;
  isReload?: boolean;
  chose?: boolean;
  canCallApi?: boolean;
  showDeleted?: boolean;
}

const data: AssignData = {
  content: {
    title: "",
  },
  groups: [],
  selectGroups: [],
  items: [],
  workDate: undefined,
  workDateEnd: undefined,
  selected: [],
  vTimeStart: moment().startOf("day").add(9, "hour"),
  vTimeEnd: moment().startOf("day").add(18, "hour"),
  creating: [],
  creatingGroups: [],
  creatingTime: { start: 0, end: 0 },
  status: "new",
  tagColor: "",
  assign: {
    id: 0,
    time: { start: 0, end: 0 },
    staff: [],
    dptTyp: null,
    dpt: null,
    date: { start: "", end: "" },
    tmp: null,
    work: null,
    store: [],
    temp_work: null,
    temp_task: [""],
    updateAt: null,
  },
  store: [],
  selectItem: undefined,
  showAssign: false,
  tmp: [],
  wok: [],
  delPopup: {
    id: 0,
    status: "",
    text: "",
    func: () => {},
  },
  unpDat: [],
  tempWok: [],
  dptTyp: [],
  isReload: false,
  chose: false,
  canCallApi: false,
  showDeleted: true,
};

interface ActionType {
  type: "setState" | "clearAssign" | "clearTimeline";
  payload: AssignData;
}

function reducer(state: AssignData, action: ActionType) {
  switch (action.type) {
    case "setState":
      return {
        ...state,
        ...action.payload,
      };
    case "clearAssign":
      return {
        ...state,
        assign: {
          id: 0,
          time: { start: 0, end: 0 },
          staff: [],
          dptTyp: null,
          dpt: null,
          date: { start: "", end: "" },
          tmp: null,
          work: null,
          store: [],
          temp_work: null,
          temp_task: [""],
        },
        selected: [],
        showAssign: false,
        tagColor: ""
      };

    /**
     * Reset states: status, assign, creating, creatingTime, creatingGroups, selected, store, tmp, wok
     */
    case "clearTimeline":
      return {
        ...state,
        // status: "unselect" as "unselect" | "new" | "edit",
        // clearAssign
        creating: [],
        creatingTime: { start: 0, end: 0 },
        creatingGroups: [],
        selected: [],
        selectItem: undefined,
        store: [],
        tmp: [],
        wok: [],
      };
    default:
      return state;
  }
}

interface AssignContext {
  data: AssignData;
  dispatch: React.Dispatch<ActionType>;
}

export const AssignContext = createContext<AssignContext>({
  data,
  dispatch: () => {},
});

export const AssignContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, data);
  return (
    <AssignContext.Provider value={{ data: state, dispatch }}>
      {children}
    </AssignContext.Provider>
  );
};

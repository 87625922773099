import { LoginOutlined, MenuOutlined, UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { Avatar, Button } from "antd";
import { useContext, useState } from "react";
import { ReactComponent as Logo } from "../../images/layout/logo.svg";
import { ReactComponent as Pen } from "../../images/layout/Pen.svg";
import { ReactComponent as OverviewIcon } from "../../images/home/icon-01.svg";
import { ReactComponent as MyworkIcon } from "../../images/home/icon-02.svg";
import { NavItem } from "./NavItem";
import { staticText } from "../../utils/staticText";
import { InitContext } from "../../contexts/init";
import { useHistory } from "react-router-dom";
import dpIfIdToView from "../../utils/dpIfIdToView";

export const Nav = () => {
  const { data: InitData } = useContext(InitContext);
  const { dptTyp: dptTypList, account, permission } = InitData;
  const { site } = staticText;
  const [hover, setHover] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const history = useHistory();

  return (
    <>
      <div className="header">
        <MenuOutlined className="icon-menu" onClick={() => setOpenMenu(true)} />
      </div>
      <div
        className={`${openMenu ? "block" : ""}`}
        onClick={() => setOpenMenu(false)}
      ></div>
      <div
        className={`nav-wrapper ${openMenu ? "open" : ""}`}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <div
          className="logo-box"
          onClick={() => {
            setOpenMenu(false);
            history.push("/home");
          }}
        >
          <Logo />
        </div>
        <div className="avatar-box">
          {account.pic ? (
            <Avatar className="nav-avatar" src={account.pic} size={50} />
          ) : (
            <Avatar className="nav-avatar" icon={<UserOutlined />} size={50} />
          )}
          {/* https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png */}
          <div className="text-area">
            <h6>{permission.name}</h6>
            <h4>{account.name}</h4>
          </div>
        </div>
        <nav>
          {/* <NavItem
            setOpenMenu={setOpenMenu}
            hover={hover}
            text={site.notice}
            link="/system"
            icon={<Notice />}
            sub={[
              { id: 1, text: site.system, link: "/notice/system" },
              { id: 2, text: site.internal, link: "/notice/internal" },
            ]}
            key="/assign"
          /> */}
          <NavItem
            setOpenMenu={setOpenMenu}
            hover={hover}
            text={site.assign}
            link="/assign"
            icon={<Pen />}
            sub={
              permission.id === 1
                ? [
                    { id: 1, text: site.unpub, link: "/assign/unpub" },
                    { id: 2, text: site.pub, link: "/assign/pub" },
                  ]
                : [{ id: 2, text: site.pub, link: "/assign/pub" }]
            }
            key="/assign"
          />
          {/* <NavItem
            setOpenMenu={setOpenMenu}
            hover={hover}
            text={site.overview}
            link="/overview"
            icon={<Message />}
            sub={tmpTypList}
          /> */}
          {dptTypList.map((ele, i) => (
            <NavItem
              setOpenMenu={setOpenMenu}
              hover={hover}
              text={site.overview.replace("%部門%", ele.name)}
              link="/overview"
              icon={<OverviewIcon />}
              sub={ele.dpt.map((ele) => ({
                id: ele.id,
                text: ele.name,
                link: dpIfIdToView(ele.id),
              }))}
              key={`/nav-${i}`}
            />
          ))}
          <NavItem
            setOpenMenu={setOpenMenu}
            hover={hover}
            text={site.myjob}
            link="/mywork"
            icon={<MyworkIcon />}
            key="/mywork"
          />
          {/* <NavItem
            setOpenMenu={setOpenMenu}
            hover={hover}
            text={site.login}
            link="/login"
            icon={<LoginOutlined />}
            key="/login"
          />
          <NavItem
            setOpenMenu={setOpenMenu}
            hover={hover}
            text={site.bind}
            link="/bind"
            icon={<LoginOutlined />}
            key="/bind"
          /> */}
        </nav>
        <Button
          className="logout-btn"
          onClick={() => {
            fetch(
              `${
                process.env.NODE_ENV === "development"
                  ? "https://cors-anywhere.herokuapp.com/"
                  : ""
              }${process.env.REACT_APP_URL}logout`,
              {
                method: "POST",
              }
            ).then(() => history.push("/login"));
          }}
        >
          <LogoutOutlined />{site.logout}
        </Button>
      </div>
    </>
  );
};
